import React from 'react';
// import { Button } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import Lottie from 'lottie-react';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './index.css';
import { Helmet } from 'react-helmet';

export const getSequenceName = name => {
  if (!name) return '';
  if (name?.length > 30) {
    return `${name?.substring(0, 30)}...`;
  }
  return name;
};

const Connections = () => {
  const GetStartedView = React.forwardRef(() => {
    return (
      <Flex
        className={'gap-2'}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: 'auto',
          height: 'auto'
        }}
      >
        <Lottie
          animationData=""
          style={{
            width: 300,
            height: 300
          }}
        />
        <h5>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="30"
            width="30"
            xmlns="http://www.w3.org/2000/svg"
            style={{ color: 'rgb(255, 201, 0)', marginTop: '-4px' }}
          >
            <g>
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M9.973 18h4.054c.132-1.202.745-2.194 1.74-3.277.113-.122.832-.867.917-.973a6 6 0 1 0-9.37-.002c.086.107.807.853.918.974.996 1.084 1.609 2.076 1.741 3.278zM14 20h-4v1h4v-1zm-8.246-5a8 8 0 1 1 12.49.002C17.624 15.774 16 17 16 18.5V21a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-2.5C8 17 6.375 15.774 5.754 15zM13 10.004h2.5l-4.5 6v-4H8.5L13 6v4.005z"></path>
            </g>
          </svg>{' '}
          Your subscribers will appear here
        </h5>
        {/* <Button
          style={{
            marginTop: '2rem'
          }}
          variant={'primary'}
        >
          <Flex alignItems={'center'} className={'gap-1'}>
            <FontAwesomeIcon icon={faPlus} />
            <span>Start connecting</span>
          </Flex>
        </Button> */}
      </Flex>
    );
  });

  return (
    <>
      <Helmet>
        <title>Connections - Reachable</title>
        <meta charSet="utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@reachableonline " />
        <meta name="twitter:creator" content="@reachableonline " />
        <meta property="og:description" content="Reachable CRM" />
        <meta
          content="https://twitter.com/reachableonline"
          property="og:see_also"
        />
        <meta name="twitter:title" content="Dashboard - Reachable" />
        <meta name="twitter:description" content="" />
        {/* <meta name="twitter:image" content="https://promoteapp.s3.amazonaws.com/promote-twitter-card.jpeg" /> */}
        <meta name="twitter:image:width" content="1001 " />
        <meta name="twitter:image:height" content="459 " />
        <link href="https://reachableonline.co/" rel="canonical " />
        <link href="https://reachableonline.co/" rel="home " />
      </Helmet>
      <GetStartedView />
    </>
  );
};
export default Connections;
