/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from 'components/common/FalconCloseButton';
import useSearchStore from 'store/search_store';
import APIService from 'http/api_service';
import EndPoints from 'http/endpoints';
import { useLocation } from 'react-router-dom';
import paths from 'routes/paths';

const SearchBox = () => {

  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const searchFilters = useSearchStore(state => state.searchFilters);
  const searchInputValue = useSearchStore(state => state.searchText);
  const setSearchInputValue = useSearchStore(state => state.setSearchText);
  const setSearchResults = useSearchStore(state => state.setSearchResults);
  const searchMaps = {
    [paths.mediaRequests]: EndPoints.MEDIA_REQUEST_SEARCH
  };

  useEffect(() => {
    if (searchInputValue || Object.entries(searchFilters).length > 0) {
      APIService.searchContent(searchMaps[location.pathname], { search: searchInputValue, ...searchFilters }, (response, error) => {
        if (error) {
          return;
        }
        let { data } = response;
        setSearchResults({ [location.pathname]: data });
      });
    } else {
      setSearchResults({ [location.pathname]: [] });
    }
    // eslint-disable-next-line
  }, [searchInputValue, searchFilters]);

  return (
    <Dropdown
      show={dropdownOpen}
      className="search-box"
      onToggle={() => setDropdownOpen(!dropdownOpen)}
    >
      <Dropdown.Toggle as="div" className="dropdown-caret-none">
        <Form className="position-relative">
          <Form.Control
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input"
            value={searchInputValue}
            onChange={({ target }) => setSearchInputValue(target.value)}
          />
          <FontAwesomeIcon
            icon="magnifying-glass"
            className="position-absolute text-400 search-box-icon"
          />
          {(dropdownOpen || searchInputValue) && (
            <div className="search-box-close-btn-container">
              <FalconCloseButton
                size="sm"
                noOutline
                className="fs-11"
                onClick={() => {
                  setSearchInputValue('');
                  setDropdownOpen(false);
                }}
              />
            </div>
          )}
        </Form>
      </Dropdown.Toggle>
    </Dropdown>
  );
};

export default SearchBox;
