/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIService from 'http/api_service';
import React, { useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import usePostsStore from 'store/posts_store';
import usePublishablePostStore from 'store/publishable_post_store';
import useUserStore from 'store/userStore';
import CreatePost from './CreatePost';

const FeedDropdown = ({ authorId, onDeleteDoneCallback, post }) => {
  const { t } = useTranslation();
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const setPost = usePublishablePostStore(state => state.setPost);
  const posts = usePostsStore(state => state.posts);
  const setPosts = usePostsStore(state => state.setPosts);
  const [openDeleteConsent, setOpenDeleteConsent] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const deletePost = () => {
    let postsCopy = [...posts];
    let postIndex = posts.findIndex(item => item._id === post._id);
    if (postIndex !== -1) {
      let deletedPost = postsCopy[postIndex];
      postsCopy.splice(postIndex, 1);
      setPosts([...postsCopy]);
      APIService.deletePost(post._id, (response, err) => {
        if (err) {
          postsCopy.splice(postIndex, 0, deletedPost);
          setPosts([...postsCopy]);
          toast.error(err, { theme: 'colored' });
          return;
        }
        let { message } = response;
        toast.success(message, { theme: 'colored' });
        if (onDeleteDoneCallback) {
          onDeleteDoneCallback();
        }
      });
    } else {
      toast.error(t('oops', { theme: 'colored' }));
    }
  };

  return (
    <>
      <Dropdown
        id={`${post._id}-options`}
        className="font-sans-serif btn-reveal-trigger"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}>
        <Dropdown.Toggle
          variant="link"
          size="sm"
          className="px-1 text-600 btn-reveal"
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {authorId === authenticatedUser._id && (
            <Dropdown.Item
              onClick={() => {
                setPost({ ...post });
                setOpenEditModal(true);
              }}
            >
              {t('edit')}
            </Dropdown.Item>
          )}
          <Dropdown.Divider />
          {authorId !== authenticatedUser._id && (
            <Dropdown.Item className="text-warning">
              {t('report')}
            </Dropdown.Item>
          )}
          {authorId === authenticatedUser._id && (
            <Dropdown.Item
              onClick={() => setOpenDeleteConsent(true)}
              className="text-danger"
            >
              {t('delete')}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <div
        onKeyDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}>
        <Modal show={openDeleteConsent}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {t('delete_post_question')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t('delete_post_consent')}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setOpenDeleteConsent(false)} variant="default">
              {t('no')}
            </Button>
            <Button
              onClick={() => {
                setOpenDeleteConsent(false);
                deletePost();
              }}
              variant="danger"
            >
              {t('yes')}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          centered
          show={openEditModal}
          onHide={() => {
            setPost({});
            setOpenEditModal(false);
          }}
          onExit={() => {
            setPost({});
            setOpenEditModal(false);
          }}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <CreatePost
              onDoneCallback={() => {
                setOpenEditModal(false);
              }}
              footerOpen
              title={t('edit_post')}
              className="mb-3"
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default FeedDropdown;
