export default class StringUtils {
  static substringBeforeLast(parent, str) {
    let idx = parent.lastIndexOf(str);
    if (idx !== -1) {
      return parent.substr(0, idx);
    }
    return parent;
  }

  static substringAfterLast(parent, str) {
    if (!parent || !str) return '';
    const lastIndex = parent.lastIndexOf(str);
    if (lastIndex === -1) return '';
    return parent.substring(lastIndex + str.length);
  }

  static substringAfter(parent, str) {
    let idx = parent.indexOf(str);
    if (idx !== -1) {
      return parent.substr(idx + str.length);
    }
    return parent;
  }

  static generateRandomString(length) {
    let result = '';
    let characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static capitalizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static isStringAFirebaseLink(str) {
    if (typeof str !== 'string') {
      return false;
    }
    return str.includes('firebasestorage.googleapis.com');
  }
}
