import Flex from 'components/common/Flex';
import { Col, Form, FormCheck } from 'react-bootstrap';
// import SoftBadge from 'components/common/SoftBadge';
import React from 'react';

const MonthlyYearlySwitch = () => {
  return (
    <Col
      sm="auto"
      as={Flex}
      justifyContent="center"
      alignItems="center"
      className="mt-1 mt-sm-0"
    >
      <FormCheck.Label htmlFor="custom-switch" className="me-2">
        Monthly
      </FormCheck.Label>
      <Form.Check type="switch" id="yearly" className="mb-2">
        <Form.Check.Input type="checkbox" defaultChecked />
      </Form.Check>
      <Form.Check.Label>
        Yearly {/* <SoftBadge bg="success" pill> */}
        {/* Save 20% */}
        {/* </SoftBadge> */}
      </Form.Check.Label>
    </Col>
  );
};
export default MonthlyYearlySwitch;
