/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { faBackward, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import {
  Button,
  Card,
  Col,
  Modal,
  Offcanvas,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { useBreakpoints } from 'hooks/useBreakpoints';
import Editor from "./editor/Editor"
import useUserStore from 'store/userStore';
import { useTranslation } from 'react-i18next';
import usePitchStore from 'store/pitch_store';

export default function MediaRequestDetails({
  open,
  mediaRequest,
  closeDrawer
}) {

  const { t } = useTranslation();
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const [showDrawer, setShowDrawer] = useState(open);
  const [openPitchReporterDialog, setOpenPitchReporterDialog] = useState(false);
  const { breakpoints } = useBreakpoints();
  const requesteCreator = mediaRequest.created_by;
  const pitch = usePitchStore(state => state.pitch);
  const setPitch = usePitchStore(state => state.setPitch);
  const [pitching, setPitching] = useState(false);

  const handleCloseMediaRequestDetails = () => {
    setShowDrawer(false);
    closeDrawer();
  };

  useEffect(() => {
    setShowDrawer(open);
  }, [open]);

  const prePitchTooltip = () => {
    if (!pitch.text && !pitch.media) {
      return t('add_some_text_or_media_to_pitch');
    }
    if ((pitch.attributions ?? []).length < 1) {
      return t('pick_an_attribution_before_pitching');
    }
    return null;
  };

  const isEmptyTag = (content) => {
    const tagRegex = /<([a-z][a-z0-9]*)\s*[^>]*>.*?<\/\1>/gi;
    const emptyTagRegex = /^<([a-z][a-z0-9]*)\s*[^>]*>\s*<\/\1>$/i;

    const tags = content.match(tagRegex) || [];
    return tags.every(tag => emptyTagRegex.test(tag));
  }

  const pitchBodyIsEmpty = () => {
    if ((pitch.body ?? "").length === 0) {
      return true;
    }
    if (isEmptyTag(pitch.body)) {
      return true;
    }
    return false;
  }

  return (
    <>
      <Offcanvas
        style={{
          width: breakpoints.down('xl') ? '100%' : '60%'
        }}
        fullscreen={true}
        scroll={true}
        backdrop={false}
        placement="end"
        show={showDrawer}
        onHide={handleCloseMediaRequestDetails}
      >
        <Offcanvas.Header className="border-bottom border-200">
          <Row className="flex-between-center g-0">
            <Col xs="auto">
              <Button
                onClick={() => {
                  handleCloseMediaRequestDetails();
                }}
                icon={faBackward}
                variant={'link'}
                style={{
                  textDecoration: 'none'
                }}
              >
                <Flex alignItems={'center'} className={'gap-2'}>
                  <FontAwesomeIcon className='text-700' icon={faTimes} />
                  <span className='text-700'>Close</span>
                </Flex>
              </Button>
            </Col>
          </Row>
          <Offcanvas.Title>
            <div className="d-md-flex justify-content-between py-3 py-md-2">
              <Row className="g-md-0">
                <Col xs="auto" className="d-md-flex">
                  <Col xs="auto">
                    {
                      requesteCreator?._id !== authenticatedUser?._id &&
                      <Button
                        size="sm"
                        variant="primary"
                        className={'ms-sm-0  ms-md-3 d-flex align-items-center'}
                        style={{ boxShadow: 'none', border: '1px solid #d8e2ef' }}
                        onClick={() => {
                          setOpenPitchReporterDialog(true);
                        }}
                      >
                        <span>Pitch the reporter</span>
                      </Button>
                    }
                  </Col>
                </Col>
              </Row>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}
        >
          {mediaRequest ? (
            <>
              <h5>{mediaRequest?.title}</h5>
              <p>{mediaRequest?.question}</p>
            </>
          ) : (
            <p>No media request selected</p>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={openPitchReporterDialog}
        onHide={() => setOpenPitchReporterDialog(false)}
        onExit={() => setOpenPitchReporterDialog(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><h5>Write, preview, and send your pitch</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 elevation-none shadow-none">
          <Card className="p-0 m-0 elevation-none shadow-none">
            <Card.Body className="p-0 m-0 elevation-none shadow-none">
              <div className="border border-0 border-200">
                <Editor
                  value={pitch?.body ?? ''}
                  onChange={(val) => setPitch({ ...pitch, body: val })}
                />
              </div>
              {/* <Attributions
                mediaRequest={mediaRequest}
                users={members}
                addMember
                showMember={1}
                avatarSize="3xl"
              /> */}
              <Row className="g-0 mt-3 px-x1 pb-3 justify-content-end">
                <Col xs="auto">
                  <OverlayTrigger
                    overlay={
                      prePitchTooltip() != null ? (
                        <Tooltip
                          style={{ position: 'fixed' }}
                          id="pre-pitch-trigger"
                        >
                          {prePitchTooltip()}
                        </Tooltip>
                      ) : (
                        <div></div>
                      )
                    }
                  >
                    <span>
                      <Button
                        variant="primary"
                        size="sm"
                        className="px-4 px-sm-5"
                        type="submit"
                        disabled={
                          pitching ||
                          (pitchBodyIsEmpty() && !pitch.media)
                        }>
                        {pitch._id ? t('update') : t('submit')}
                      </Button>
                    </span>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
}
