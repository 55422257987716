/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  faBold,
  faCode,
  faFile,
  faHeadphones,
  faHighlighter,
  faImage,
  faItalic,
  faLink,
  faQuoteLeft,
  faRotateLeft,
  faRotateRight,
  faStrikethrough,
  faUnderline,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Code from '@tiptap/extension-code';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Hightlight from '@tiptap/extension-highlight';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Text from '@tiptap/extension-text';
import OrderedList from '@tiptap/extension-ordered-list';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorProvider, useCurrentEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useEffect, useRef, useState, useTransition } from 'react';
import Flex from 'components/common/Flex';
import { Button, Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './index.css';
import OverlayedComponent from './OverlayedComponent';
import Heading from './EditorHeading'
import List from './List';
import Blockquote from '@tiptap/extension-blockquote';
import StringUtils from 'utils/string';
import UploadableMediaPreview from './UploadableMediaPreview';

const MenuBar = () => {

  const { t } = useTranslation();
  const { editor } = useCurrentEditor();
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const [media, setMedia] = useState([]);
  const [openMediaPreview, setOpenMediaPreview] = useState(false);
  const [activeMediaTypeTitle, setActiveMediaTypeTitle] = useState("");
  const [activeMediaTypeSelection, setActiveMediaTypeSelection] = useState();
  const [_, startTransition] = useTransition();

  useEffect(() => {
    editor.commands.focus('end');
  }, []);

  const addMoreMedia = () => {
    switch (activeMediaTypeSelection) {
      case "image":
        imageInputRef.current.click();
        break;
      default:
        break;
    }
  }

  const handleFileInputChange = (event, { title }) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      let existingMedia = media ?? [];
      existingMedia = [
        ...existingMedia,
        ...Array.from(selectedFiles).map(file => {
          return {
            _id: StringUtils.generateRandomString(360),
            file,
            type: StringUtils.substringBeforeLast(file.type, '/')
          };
        })
      ];
      setMedia(existingMedia);
      setActiveMediaTypeTitle(title);
      startTransition(() => {
        setOpenMediaPreview(true);
      });
    }
  }

  const hideMediaModal = () => {
    setOpenMediaPreview(false);
    setMedia([]);
  }

  const handleUploadedFiles = (uploadedFiles, fileType) => {
    switch (fileType) {
      case "image":
        uploadedFiles.forEach((url) => {
          editor.chain().focus().setImage({ src: url }).run();
        });
        break;
      default:
        break;
    }
    setOpenMediaPreview(false);
    setMedia([]);
  }

  return (
    <Flex className={'gap-1'} alignItems={'left'} justifyContent={'left'}>
      {/* <OverlayedComponent tooltip={t('undo_action')}>
        <FontAwesomeIcon
          onClick={() => editor.commands.undo()}
          icon={faRotateLeft}
          className='mt-1'
        />
      </OverlayedComponent> */}
      {/* <OverlayedComponent tooltip={t('redo_action')}>
        <FontAwesomeIcon
          className='ms-2 mt-1'
          onClick={() => editor.commands.redo()}
          icon={faRotateRight}
        />
      </OverlayedComponent> */}
      {/* <div className='ms-2 bg-200' style={{ height: 25, width: 1 }}></div> */}
      <OverlayedComponent tooltip={t('bold')}>
        <Button
          size={'sm'}
          variant={'link'}
          onClick={() => {
            editor.chain().focus().toggleBold().run();
          }}
          className={`m-0 py-0 px-2 text-600 rounded-2 ${!editor.isActive('bold') ? 'bg-50' : 'bg-300'}`}>
          <FontAwesomeIcon icon={faBold} className='p-0 m-0' />
        </Button>
      </OverlayedComponent>
      <OverlayedComponent tooltip={t('italicize')}>
        <Button
          size={'sm'}
          variant={'link'}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`m-0 py-0 px-2 text-600 rounded-2 ${!editor.isActive('italic') ? 'bg-50' : 'bg-300'}`}>
          <FontAwesomeIcon icon={faItalic} className='p-0 m-0' />
        </Button>
      </OverlayedComponent>
      <OverlayedComponent tooltip={t('strike')}>
        <Button
          size={'sm'}
          variant={'link'}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={`m-0 py-0 px-2 text-600 rounded-2 ${!editor.isActive('strike') ? 'bg-50' : 'bg-300'}`}>
          <FontAwesomeIcon icon={faStrikethrough} className='p-0 m-0' />
        </Button>
      </OverlayedComponent>
      {/* <OverlayedComponent tooltip={t('insert_code')}>
        <Button
          size={'sm'}
          variant={'link'}
          onClick={() => editor.chain().focus().toggleCode().run()}
          className={`m-0 py-0 px-2 text-600 rounded-2 ${!editor.isActive('code') ? 'bg-50' : 'bg-300'}`}>
          <FontAwesomeIcon icon={faCode} className='p-0 m-0' />
        </Button>
      </OverlayedComponent> */}
      {/* <div className='bg-200' style={{ height: 25, width: 1 }}></div> */}
      {/* <OverlayedComponent tooltip={t('insert_image')}>
        <React.Fragment>
          <Button
            size={'sm'}
            variant={'link'}
            className={`m-0 py-0 px-2 text-600 rounded-2`}
            onClick={() => {
              setActiveMediaTypeSelection("image");
              imageInputRef.current.click();
            }}>
            <FontAwesomeIcon icon={faImage} className='p-0 m-0' />
          </Button>
          <input
            ref={imageInputRef}
            type="file"
            multiple
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => handleFileInputChange(e, { title: `${t('preview')} ${t('your')} ${t('images', { count: media.length })} ${t('before_attachment')}` })}
          />
        </React.Fragment>
      </OverlayedComponent> */}
      {/* <OverlayedComponent tooltip={t('insert_video')}>
        <React.Fragment>
          <Button
            size={'sm'}
            variant={'link'}
            className={`m-0 py-0 px-2 text-600 rounded-2`}
            onClick={() => {
              setActiveMediaTypeSelection("video");
              videoInputRef.current.click();
            }}>
            <FontAwesomeIcon icon={faVideo} className='p-0 m-0' />
          </Button>
          <input
            ref={videoInputRef}
            type="file"
            multiple
            accept="video/*"
            style={{ display: 'none' }}
            onChange={(e) => handleFileInputChange(e, { title: `${t('preview')} ${t('your')} ${t('videos', { count: media.length })} ${t('before_attachment')}` })}
          />
        </React.Fragment>
      </OverlayedComponent> */}
      {/* <OverlayedComponent tooltip={t('insert_audio')}>
        <Button
          size={'sm'}
          variant={'link'}
          className={`m-0 py-0 px-2 text-600 rounded-2`}>
          <FontAwesomeIcon icon={faHeadphones} className='p-0 m-0' />
        </Button>
      </OverlayedComponent> */}
      <OverlayedComponent tooltip={t('insert_file')}>
        <Button
          size={'sm'}
          variant={'link'}
          className={`m-0 py-0 px-2 text-600 rounded-2`}>
          <FontAwesomeIcon icon={faFile} className='p-0 m-0' />
        </Button>
      </OverlayedComponent>
      <div className='bg-200' style={{ height: 25, width: 1 }}></div>
      <OverlayedComponent tooltip={t('insert_link')}>
        <Button
          size={'sm'}
          variant={'link'}
          onClick={() => {
            let url = prompt('Enter url link: ');
            if (url) {
              editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run();
            }
          }}
          className={`m-0 py-0 px-2 text-600 rounded-2 ${!editor.isActive('link') ? 'bg-50' : 'bg-300'}`}>
          <FontAwesomeIcon icon={faLink} className='p-0 m-0' />
        </Button>
      </OverlayedComponent>
      <OverlayedComponent tooltip={t('insert_block_quotes')}>
        <Button
          size={'sm'}
          variant={'link'}
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={`m-0 py-0 px-2 text-600 rounded-2 ${!editor.isActive('blockquote') ? 'bg-50' : 'bg-300'}`}>
          <FontAwesomeIcon icon={faQuoteLeft} className='p-0 m-0' />
        </Button>
      </OverlayedComponent>
      <OverlayedComponent tooltip={t('insert_underline')}>
        <Button
          size={'sm'}
          variant={'link'}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={`m-0 py-0 px-2 text-600 rounded-2 ${!editor.isActive('underline') ? 'bg-50' : 'bg-300'}`}>
          <FontAwesomeIcon icon={faUnderline} className='p-0 m-0' />
        </Button>
      </OverlayedComponent>
      <OverlayedComponent tooltip={t('highlight')}>
        <Button
          size={'sm'}
          variant={'link'}
          onClick={() => editor.chain().focus().toggleHighlight().run()}
          className={`m-0 py-0 px-2 text-600 rounded-2 ${!editor.isActive('highlight') ? 'bg-50' : 'bg-300'}`}>
          <FontAwesomeIcon icon={faHighlighter} className='p-0 m-0' />
        </Button>
      </OverlayedComponent>
      <div className='mx-2 bg-200' style={{ height: 25, width: 1 }}></div>
      <Heading editor={editor} />
      <div className='mx-2 bg-200' style={{ height: 25, width: 1 }}></div>
      <List editor={editor} />
      <Modal
        fullscreen
        show={openMediaPreview}
        centered
        onHide={hideMediaModal}
        onExit={hideMediaModal}>
        <Modal.Header closeButton>
          <h5>{activeMediaTypeTitle}</h5>
        </Modal.Header>
        <Modal.Body>
          <UploadableMediaPreview
            media={media}
            setMedia={setMedia}
            addMore={addMoreMedia}
            hideModal={hideMediaModal}
            mediaType={activeMediaTypeSelection}
            onDone={(uploadedFiles, fileType) => handleUploadedFiles(uploadedFiles, fileType)}
          />
        </Modal.Body>
      </Modal>
    </Flex>
  );
};

const Editor = ({ onChange, value }) => {

  const { t } = useTranslation();
  const bodyRef = useRef();
  const [openLinkPrompt, setOpenLinkPrompt] = useState(false);
  const [urlLink, setUrlLink] = useState();

  const extensions = [
    StarterKit,
    Document,
    Paragraph,
    Text,
    TextAlign,
    Strike,
    Hightlight,
    Underline,
    Typography,
    Dropcursor,
    OrderedList,
    Image.configure({
      inline: true
    }),
    // ImageResize, //Be like this extension is buggy, will just leave it here for now and be checking for updates to it to see if the current issues with it are fixed.
    Blockquote,
    Placeholder.configure({
      placeholder: t('type_here'),
    }),
    Code.configure({
      HTMLAttributes: {
        class: 'text-600'
      }
    }),
    Link.configure({
      HTMLAttributes: {
        class: 'text-blue-500 hover:underline',
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
      alignments: ['left', 'right', 'center', 'justify']
    })
  ];

  const content = `${value}`;
  const [preview, setPreview] = useState('');
  const [_, startTransition] = useTransition();

  return (
    <div className="editor-container">
      <Tab.Container defaultActiveKey="write">
        <Row className="d-inline-block">
          <Col>
            <Nav variant="pills" className="nav-pills-falcon m-0">
              <Nav.Item>
                <Nav.Link as={Button} size="sm" eventKey="write">
                  {t('write')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Button} size="sm" eventKey="preview">
                  {t('preview')}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Tab.Content className='mt-4'>
          <Tab.Pane eventKey="write">
            <EditorProvider
              ref={bodyRef}
              slotBefore={
                <MenuBar
                  body={value}
                  openLinkPrompt={openLinkPrompt}
                  setOpenLinkPrompt={setOpenLinkPrompt}
                  url={urlLink}
                  setUrl={setUrlLink}
                />
              }
              extensions={extensions}
              content={content}
              onUpdate={({ editor }) => {
                if (onChange) {
                  onChange(editor.getHTML());
                }
                startTransition(() => {
                  setPreview(editor.getHTML());
                });
              }}
            />
          </Tab.Pane>
          <Tab.Pane
            eventKey="preview"
            className="overflow-auto scrollbar"
          >
            <div dangerouslySetInnerHTML={{ __html: preview }} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Editor;
