/* eslint-disable prettier/prettier */
import React from 'react';
import { Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import paths from "routes/paths";
import usePostsStore from "store/posts_store";

const ContentTypeToggle = () => {

    const postTypes = [
        {
            key: "for_you",
            value: "For you"
        },
        {
            key: "following",
            value: "Following"
        }
    ];

    const postType = usePostsStore(state => state.postType);
    const setPostType = usePostsStore(state => state.setPostType);
    const activePostType = postTypes.find(p => p.key === postType);
    const location = useLocation();

    return (
        location.pathname.includes(paths.feed) ?
            <Dropdown>
                <Dropdown.Toggle size='sm' className='p-0 px-2 rounded-pill' variant={'falcon-default'}>
                    {activePostType ? activePostType.value : "Select"}
                </Dropdown.Toggle>
                <Dropdown.Menu className='py-2'>
                    {
                        postTypes.map(pType => {
                            return (
                                <Dropdown.Item key={pType.key} onClick={() => {
                                    setPostType(pType.key);
                                }}>{pType.value}</Dropdown.Item>
                            )
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>
            : <div></div>
    );
}
export default ContentTypeToggle;
