/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import ChatThread from './ChatThread';
import SimpleBarReact from 'simplebar-react';
import ChatContactsSearch from './ChatContactSearch';
import classNames from 'classnames';
// import { useChatContext } from 'providers/ChatProvider';
import usePubSub from 'pubsub';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import useConversationsStore from 'store/conversationStore';

const ChatSidebar = ({ hideSidebar, handleSelect }) => {
  // const { threads } = useChatContext();
  const {
    // sendMessage,
    socket
  } = usePubSub();
  // console.log('🚀 ~ ChatSidebar ~ socket:', socket);
  const { conversations, updateConversations } = useConversationsStore(
    state => ({
      conversations: state.conversations,
      updateConversations: state.updateConversations
    })
  );

  // console.log('🚀 ~ ChatSidebar ~ conversations:', conversations);

  useEffect(() => {
    if (socket?.connected) {
      // sendMessage({
      //   body: 'How are you today?',
      //   conversationType: 'one_to_one',
      //   senderId: '665090d7128850da6a4d54b9'
      // });
    }
  }, [socket]);

  const fetchConversationsOfUser = () => {
    // console.log('Fetching conversations....');
    // APIService.fetchConversationsOfParticipant(
    //   'xxxxxxxxxxxxxxxxxxxxxxxxxxxx',
    //   (response, error) => {
    //     if (error) {
    //       toast.error(error, { theme: 'colored' });
    //       return;
    //     }
    //     let { data } = response;
    //     prepareConversationList(data);
    //   }
    // );
  };

  useEffect(() => {
    fetchConversationsOfUser();
  }, []);

  return (
    <div className={classNames('chat-sidebar', { 'start-0': hideSidebar })}>
      <div className="contacts-list">
        <SimpleBarReact style={{ height: '100%', minWidth: '65px' }}>
          <Nav className="border-0">
            {conversations.map((conversation, index) => (
              <ChatThread
                thread={conversation}
                index={index}
                key={conversation?.id}
                handleSelect={handleSelect}
              />
            ))}
          </Nav>
        </SimpleBarReact>
      </div>
      <ChatContactsSearch />
    </div>
  );
};

ChatSidebar.propTypes = {
  hideSidebar: PropTypes.bool,
  handleSelect: PropTypes.func
};

export default ChatSidebar;
