import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import AppProvider from 'providers/AppProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { router } from 'routes';
import 'helpers/initFA';
import './i18n';

const googleApiClientId =
  '694148205274-f3oah7ab3ak5mi02nqaghr401dkvof00.apps.googleusercontent.com';
const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleApiClientId}>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
