/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import ChatContentHeader from './ChatContentHeader';
// import threads from 'data/chat/threads';
import ChatContentBody from './ChatContentBody';
import MessageTextArea from './MessageTextArea';
import useConversationsStore from 'store/conversationStore';

const ChatContent = ({ setHideSidebar }) => {
  const { activeConversation, messages } = useConversationsStore(state => ({
    conversations: state.conversations,
    activeConversation: state.activeConversation,
    messages: state.messages
    // updateConversations: state.updateConversations
  }));

  const conversationMessages = messages[activeConversation?._id];

  console.log('🚀 ~ ChatContent ~ conversationMessages:', conversationMessages);

  if (!conversationMessages) return null;

  return (
    <Tab.Content className="card-chat-content">
      {/* {conversations.map((conversation, index) => (
        <Tab.Pane key={index} eventKey={index} className="card-chat-pane"> */}
      <ChatContentHeader
        thread={activeConversation}
        setHideSidebar={setHideSidebar}
      />
      <ChatContentBody thread={conversationMessages} />
      {/* </Tab.Pane>
      ))} */}
      {/* <MessageTextArea /> */}
    </Tab.Content>
  );
};

ChatContent.propTypes = {
  setHideSidebar: PropTypes.func.isRequired
};

export default ChatContent;
