/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import Flex from 'components/common/Flex';
import { Button, Card, Col, Offcanvas, Row } from 'react-bootstrap';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { RoutePaths } from '../../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
import IconButton from 'components/common/IconButton';
import { faBackward, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'utils/url';
import PricingDefaultHeader from '../PricingDefaultHeader';
import PricingDefaultCard from '../PricingDefaultCard';
import { pricingData } from '../pricingData';
import useUserStore from 'store/userStore';
import paths from 'routes/paths';
import { ChartBar } from '@phosphor-icons/react';

const ExpertSubscriptionCard = () => {
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  console.log(authenticatedUser) 
  const [activePlan, setActivePlan] = useState(authenticatedUser.plans);
  const [activeExpertPlan, setActiveExpertPlan] = useState(authenticatedUser.plans);
  console.log(activePlan) 
  const [
    openSalesPlatformPricingPageDrawer,
    setOpenSalesPlatformPricingPageDrawer
  ] = useState(false);

  const navigate = useNavigate();
  const handleCloseSalesPlatformPricingPage = () => {
    setOpenSalesPlatformPricingPageDrawer(!openSalesPlatformPricingPageDrawer);
  };

  const [upgradingIndex, setupgradingIndex] = useState(-1);
  const [paymentForNewWorkspace] = useState(false);
    useState(false);
  const query = useQuery();

  const refreshLoggedInUserData = () => {
    APIService.fetchLatestMe((latestMeResponse, error) => {
      if (error) {
        toast.error(error, { theme: 'colored' });
        if (
          error
            .toLowerCase()
            .includes('Invalid credentials provided'.toLowerCase())
        ) {
          window.localStorage.clear();
          setTimeout(() => {
            navigate(paths.simpleLogin);
          }, 100);
        }
        return;
      }
      let latestUserData = latestMeResponse.data;
      setActivePlan(latestUserData.plans);
      setActiveExpertPlan(latestUserData.plans);
    });
  };

  const handleSubscription = (item, index) => {
    setupgradingIndex(index);
    APIService.billUser(
      paymentForNewWorkspace,
      null,
      false,
      item.plan,
      (response, error) => {
        if (error) {
          setupgradingIndex(-1);
          toast.error(error);
          return;
        }
        let { data } = response;
        window.location = data;
        setupgradingIndex(-1);
      }
    );
  };

  useEffect(() => {
    let paymentFor = query.get('payment_for');
    if (paymentFor) {
      try {
        let data = JSON.parse(decodeURIComponent(paymentFor));
        let { pricingData, coupon, user } = data;
        if (coupon) {
          pricingData.coupon = coupon;
        }
        if (pricingData) {
          console.log(pricingData);
          handleSubscription(pricingData, user);
        }
      } catch (e) {}
    }
  }, [query]);

  useEffect(() => {
    refreshLoggedInUserData();
  }, []);
  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <Flex className="position-relative">
                <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                  <ChartBar width={25} height={25} className={`text-success fs-0`} />
                </div>
                <div className="flex-1">
                  <h5 className="mt-1">Plans</h5>
                </div>
              </Flex>
            </Col>
            <Col xs="auto">
              <IconButton
                onClick={() => setOpenSalesPlatformPricingPageDrawer(true)}
                iconClassName="fs--2 me-1"
                variant="falcon-default"
                size="sm"
                // icon="pencil-alt"
              >
                Select a plan
              </IconButton>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-white border-top">
          <Row>
            <div className="position-relative z-index-2">
              <div>
                <p className="text-700">
                  <b>{activePlan?.active?.expert?.Name} plan</b>
                </p>
                {/* {!isFreeUser(authenticatedUser) && (
              <Button
                variant="text"
                className="btn btn-sm btn-link ps-0 text-danger mb-2"
                style={{
                  textDecoration: "none",
                }}
                onClick={() => {
                  setOpenSubscriptionCancelPrompt(true);
                }}
              >
                {cancellingSubscription
                  ? "Cancelling subscription..."
                  : "Contact us to cancel your subscription"}
              </Button>
            )} */}
              </div>
              <Flex className="py-2">
                <div className="pe-3">
                  <p className="fs--1 fw-medium">
                    <FontAwesomeIcon
                      icon="check"
                      className="me-1 text-success"
                    />
                    {activeExpertPlan?.active?.expert?.PitchableRequests} pitches per month
                  </p>
                </div>
              </Flex>
              <Flex>
                <div className="pe-3">
                  <p className=" fs--1">
                    <FontAwesomeIcon
                      icon="check"
                      className="me-1 text-success"
                    />
                    {activePlan?.sales_platform?.MonthlyEmailSendingLimit} 2-hour response delay to opportunities
                  </p>
                  {/* <h4 className="text-800 mb-0">$21,349.29</h4> */}
                </div>
                
              </Flex>
            </div>
          </Row>
          <ul className="mb-0 list-unstyled">
            <li className="mt-2">
              <Row className="flex-between-center">
                <Col>
                  <Flex>
                    <FontAwesomeIcon
                      icon="check"
                      className={classNames('text-success mt-1 fs--1')}
                    />
                    <p
                      className="fs--1 ps-2 mb-0"
                      dangerouslySetInnerHTML={createMarkup('Team members')}
                    ></p>
                  </Flex>
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <Link
                    to={RoutePaths.TEAM}
                    className="alert-link fs--1 fw-medium"
                  >
                    {`Manage users`}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    />
                  </Link>
                </Col>
              </Row>
            </li>
            <li className="mt-4">
              <Row className="flex-between-center">
                <Col>
                  <Flex>
                    <FontAwesomeIcon
                      icon="check"
                      className={classNames('text-success mt-1 fs--1')}
                    />
                    <p
                      className="fs--1 ps-2 mb-0"
                      
                    >1</p>
                  </Flex>
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <Link
                    to="#"
                    
                    className="alert-link fs--1 fw-medium"
                  >
                    {`View workspace`}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    />
                  </Link>
                </Col>
              </Row>
            </li>
          </ul>
        </Card.Body>
        {/* <Card.Footer className="border-top text-end">
        <IconButton
          iconClassName="fs--2 me-1"
          variant="falcon-default"
          size="sm"
          icon="dollar-sign"
        >
          Refund
        </IconButton>
        <IconButton
          className="ms-2"
          iconClassName="fs--2 me-1"
          variant="falcon-default"
          size="sm"
          icon="check"
        >
          Save changes
        </IconButton>
      </Card.Footer> */}
      </Card>

      <Card className="mb-3">
        <Card.Body className="p-0"></Card.Body>
      </Card>
      <Offcanvas
        style={{
          width: '80%'
        }}
        fullscreen={true}
        placement="end"
        show={openSalesPlatformPricingPageDrawer}
        onHide={handleCloseSalesPlatformPricingPage}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            <Button
              onClick={() => {
                handleCloseSalesPlatformPricingPage();
              }}
              icon={faBackward}
              variant={'link'}
              style={{
                textDecoration: 'none'
              }}
            >
              <Flex alignItems={'center'} className={'gap-2'}>
                <FontAwesomeIcon color={'#B0BEC5'} icon={faChevronLeft} />
                <span>Close</span>
              </Flex>
            </Button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="mb-3">
            <Card.Body>
              <Row className="g-3">
                <PricingDefaultHeader />
                {pricingData
                  .sort(x => (x.activePlan ? -1 : 1))
                  .map(pricing => {
                    if (activePlan?.Name === pricing?.title) {
                      pricing.activePlan = true;
                    }
                    return (
                      <PricingDefaultCard
                        key={pricing.id}
                        pricing={pricing}
                        handleBilling={data => {
                          handleSubscription(data, {
                            ...authenticatedUser,
                            workSpaceId: 0
                          });
                        }}
                      />
                    );
                  })}
              </Row>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ExpertSubscriptionCard;
