/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import React, { forwardRef, useRef, useState } from 'react';
import Avatar from "components/common/Avatar";
import Flex from "components/common/Flex";
import { Link } from "react-router-dom";
import { abbreviateNumber, getFullName } from "utils/user_utils";
import { useTranslation } from 'react-i18next';
import { formatRelativeTime } from "utils/time";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import ContinousExpansionTextView from "./ContinuousExpansionTextView";
import useReplyBoxStore from "store/reply_box_store";
import ReplyComposeBox from './ReplyComposeBox';
import useUserStore from 'store/userStore';
import { Button, Modal } from 'react-bootstrap';

const ReplyListTile = forwardRef((props, ref) => {

    const { t } = useTranslation();
    const commentId = props.commentId;
    const replyId = props._id;
    const activeReplyBoxes = useReplyBoxStore(state => state.activeReplyBoxes);
    const setActiveReplyBoxes = useReplyBoxStore(state => state.setActiveReplyBoxes);
    const replyBoxInputRef = useRef();
    const authenticatedUser = useUserStore(state => state.authenticatedUser);
    const [openEditBox, setOpenEditBox] = useState(false);
    const [openDeleteConsent, setOpenDeleteConsent] = useState(false);
    const deleteReply = props.deleteReply;
    const toggleReplyLike = props.toggleReplyLike;

    return (
        openEditBox ?
            <ReplyComposeBox
                boxId={replyId}
                className={'ms-8'}
                _id={props._id}
                text={props?.text}
                ref={replyBoxInputRef}
                commentId={commentId}
                mentionables={props.mentionables}
                onNewReplyUpserted={(reply) => {
                    setOpenEditBox(false);
                    if (props?.onNewReplyUpserted) {
                        props?.onNewReplyUpserted({ ...reply, author: authenticatedUser });
                    }
                }}
                handleOnCancel={() => {
                    setOpenEditBox(false);
                }}
            /> :
            <Flex className={'fs-10 gap-3'} justifyContent={'center'}>
                <Flex direction={'column'}>
                    <Flex className={'gap-2'}>
                        <Avatar src={props?.author?.avatar} size="s" />
                        <Flex className={'flex-1 gap-2 p-1 rounded-3'}>
                            <Link className="fw-semibold text-900" to={`/${props?.author?.username}`}>{getFullName(props.author)}</Link>
                            <div className="flex-1">
                                <ContinousExpansionTextView
                                    maxLengthInView={100}
                                    text={`${props.text ?? ''}`}
                                    className="flex-1"
                                    mentions={props.mentions}
                                />
                            </div>
                        </Flex>
                    </Flex>
                    <Flex alignItems={'center'} className={'ms-4 gap-2 mt-1'}>
                        <span style={{ fontSize: 11 }}>{formatRelativeTime(new Date(props.created_at ?? props.createdAt))}</span>
                        •
                        <span
                            onClick={() => {
                                if (activeReplyBoxes[replyId]) {
                                    if (!replyBoxInputRef?.current?.value) {
                                        delete activeReplyBoxes[replyId];
                                    }
                                } else {
                                    activeReplyBoxes[replyId] = true;
                                }
                                setActiveReplyBoxes({ ...activeReplyBoxes });
                            }}
                            style={{ fontSize: 11 }} className='fs-12 text-gray cursor-pointer'>
                            {t('reply')}
                        </span>
                        {
                            authenticatedUser?._id === props?.author?._id &&
                            <Flex style={{ fontSize: 11 }} alignItems={'center'} className={'fs-12 gap-1'}>
                                •
                                <span className='text-warning cursor-pointer'
                                    onClick={() => {
                                        setOpenEditBox(true);
                                    }}
                                >Edit</span>
                                •
                                <span className='text-danger cursor-pointer'
                                    onClick={() => {
                                        setOpenDeleteConsent(true);
                                    }}>Delete
                                </span>
                            </Flex>
                        }
                    </Flex>
                    {
                        activeReplyBoxes[replyId] &&
                        <ReplyComposeBox
                            boxId={replyId}
                            className={'ms-8'}
                            ref={replyBoxInputRef}
                            commentId={commentId}
                            mentionables={props.mentionables}
                            onNewReplyUpserted={(reply) => {
                                if (props?.onNewReplyUpserted) {
                                    props?.onNewReplyUpserted(reply);
                                }
                            }}
                        />
                    }
                </Flex>
                <div className="flex-1"></div>
                <Flex className={'flex-1'} direction={'column'}>
                    <Flex
                        className={'cursor-pointer'}
                        style={{ paddingRight: 20 }}
                        alignItems={'center'}
                        direction={'column'}>
                        <FontAwesomeIcon
                            className={`text-${props.liked ? 'danger' : 'default'}`}
                            icon={faHeart}
                            onClick={() => toggleReplyLike()}
                        />
                        {
                            (props?.likesCount ?? 0) > 0 &&
                            <span>{abbreviateNumber(props?.likesCount)}</span>
                        }
                    </Flex>
                </Flex>
                <Modal show={openDeleteConsent}>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">{t('delete_comment_question')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{t('delete_comment_consent')}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setOpenDeleteConsent(false)} variant="default">
                            {t('no')}
                        </Button>
                        <Button
                            onClick={() => {
                                setOpenDeleteConsent(false);
                                deleteReply(replyId);
                            }}
                            variant="danger"
                        >
                            {t('yes')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Flex>
    );
});

export default ReplyListTile;