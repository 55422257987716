/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import usePublishablePostStore from 'store/publishable_post_store';
import FalconLightBoxGallery from './FalconLightBoxGallery';
import { Col, Row } from 'react-bootstrap';
import RemovableMedia from './RemovableMedia';

const MediaPreview = ({ hideRemoveIcons }) => {
  const setPost = usePublishablePostStore(state => state.setPost);
  const post = usePublishablePostStore(state => state.post);
  const [mediaItems, setMediaItems] = useState([]);

  useEffect(() => {
    const loadMediaContent = async () => {
      const promises = (post.media ?? []).map(async media => {
        if (media.file) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve({ ...media, preview: reader.result });
            reader.onerror = error => reject(error);
            reader.readAsDataURL(media.file);
          });
        } else {
          return { ...media, preview: media.url };
        }
      });
      try {
        const updatedMediaItems = await Promise.all(promises);
        setMediaItems(updatedMediaItems);
      } catch (error) {
        console.error('Error loading media:', error);
      }
    };
    loadMediaContent();
  }, [post.media]);

  const handleOnRemove = media => {
    const updatedMedia = post.media.filter(item => item._id !== media._id);
    setPost({ ...post, media: updatedMedia });
  };

  return (
    <FalconLightBoxGallery images={mediaItems.map(item => item.preview)}>
      {setImgIndex => (
        <Row className="g-2">
          {mediaItems.map(media => (
            <Col xs={3} key={`${media._id}-col`}>
              <RemovableMedia
                key={media._id}
                media={media}
                onClick={() => {
                  if (media.type === 'video') {
                    return;
                  }
                  return setImgIndex(mediaItems.indexOf(media));
                }}
                handleOnRemove={handleOnRemove}
                hideRemoveIcons={hideRemoveIcons}
              />
            </Col>
          ))}
        </Row>
      )}
    </FalconLightBoxGallery>
  );
};

export default MediaPreview;
