/* eslint-disable react/prop-types */
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SHA256 } from 'utils/crypto';
import React from 'react';

const OverlayedComponent = ({ tooltip, children }) => {
  return (
    <OverlayTrigger
      key={SHA256(tooltip)}
      overlay={<Tooltip>{tooltip}</Tooltip>}
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};
export default OverlayedComponent;
