export const topics = [
  'PR',
  'Marketing',
  'Cooking',
  'Yoga',
  'Reading',
  'Painting',
  'Traveling',
  'Gardening',
  'Music',
  'Cycling',
  'Writing',
  'Film',
  'Meditation',
  'Sculpture',
  'Birdwatching',
  'Dancing',
  'Fashion',
  'Programming',
  'Knitting',
  'Fishing'
];
