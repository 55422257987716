/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Dropdown,
    Modal,
    Button,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faHeart } from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';
import { abbreviateNumber, getFullName } from 'utils/user_utils';
import { formatRelativeTime } from 'utils/time';
import createMarkup from 'helpers/createMarkup';
import ReplyListTile from './ReplyListTile';
import DeletedContentBanner from './DeletedContentBanner';
import ReplyComposeBox from './ReplyComposeBox';
import useUserStore from 'store/userStore';
import { useTranslation } from 'react-i18next';
import useReplyBoxStore from 'store/reply_box_store';
import Avatar from 'components/common/Avatar';
import { toast } from 'react-toastify';
import PulseLoader from "react-spinners/PulseLoader";

const Comment = ({
    _id: commentId,
    created_at,
    createdAt,
    deleted,
    likes = [],
    liked,
    likesCount,
    index,
    replies = [],
    repliesCount = 0,
    author: { _id: userId, first_name, last_name, username, avatar },
    postId,
    deleteReply,
    onNewReplyUpserted,
    deleteComment,
    toggleCommentLike,
    toggleReplyLike,
    ...others
}) => {

    const { t } = useTranslation();
    const authenticatedUser = useUserStore(state => state.authenticatedUser);
    const [openDeleteConsent, setOpenDeleteConsent] = useState(false);
    const activeReplyBoxes = useReplyBoxStore(state => state.activeReplyBoxes);
    const setActiveReplyBoxes = useReplyBoxStore(state => state.setActiveReplyBoxes);
    const replyBoxInputRef = React.useRef();
    const [openForEdit, setOpenForEdit] = useState(false);
    const [text, setText] = useState(others?.text);
    const [fetchingReplies, setFetchingReplies] = useState(false);

    const getNamesOfLikers = (likes) => {
        return likes.map(like =>
            like?.user?._id === authenticatedUser?._id
                ? t('you')
                : `${like?.user?.first_name} ${like?.user?.last_name}`
        );
    };

    const fetchCommentReplies = async () => {
        setFetchingReplies(true);
        try {
            await others.fetchCommentReplies({ postId, commentId, replies });
            setFetchingReplies(false);
        } catch (e) {
            toast.error(e, { theme: 'colored' });
            setFetchingReplies(false);
        }
    }

    return (
        <div
            key={commentId}>
            {deleted && (
                <DeletedContentBanner
                    message={userId === authenticatedUser?._id ? t('comment_removed_by_you') : t('comment_removed_by_author')}
                />
            )}
            {
                openForEdit &&
                <ReplyComposeBox
                    boxId={`${commentId}-update`}
                    autoFocus
                    hint={t('update_comment')}
                    inReplyToPost={postId}
                    {...{ text, _id: commentId }}
                    handleOnCancel={() => {
                        setOpenForEdit(false);
                    }}
                    onNewReplyUpserted={(updatedComment) => {
                        setOpenForEdit(false);
                        setText(updatedComment?.text);
                    }}
                />
            }
            {!deleted && !openForEdit && (
                <Flex className="mt-3">
                    <Link to={`/user/${userId}`}>
                        <Avatar src={avatar} size="xl" />
                    </Link>
                    <div className="flex-1 ms-2 fs-10">
                        <Flex>
                            <p className="mb-0 flex-1 rounded-3 p-2">
                                <Link className="fw-semibold text-900" to={`/${username}`}>
                                    {`${first_name} ${last_name}`} {authenticatedUser?._id === userId && `(${t('you')})`}
                                </Link>
                                <span className="ms-1" dangerouslySetInnerHTML={createMarkup(text)} />
                            </p>
                            {
                                authenticatedUser?._id === userId && (
                                    <Dropdown className="font-sans-serif me-2 py-2 btn-reveal-trigger">
                                        <Dropdown.Toggle variant="link" size="sm" className="px-1 text-600 btn-reveal">
                                            <FontAwesomeIcon icon={faEllipsisH} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align="end">
                                            {userId === authenticatedUser._id && (
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setActiveReplyBoxes({ ...activeReplyBoxes, [`${commentId}-update`]: true });
                                                        setOpenForEdit(!openForEdit);
                                                    }}
                                                >
                                                    {t('edit')}
                                                </Dropdown.Item>
                                            )}
                                            <Dropdown.Divider />
                                            {userId === authenticatedUser._id && (
                                                <Dropdown.Item
                                                    onClick={() => setOpenDeleteConsent(true)}
                                                    className="text-danger"
                                                >
                                                    {t('delete')}
                                                </Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )
                            }
                        </Flex>
                        <Flex className="my-0 px-2" alignItems="center">
                            <span className="pe-2">
                                {(created_at ?? createdAt) ? formatRelativeTime(new Date(created_at ?? createdAt)) : ''}
                            </span>
                            {(created_at ?? createdAt) && <span className="pe-2">{' • '}</span>}
                            <span
                                className="text-primary cursor-pointer pe-2"
                                onClick={() => toggleCommentLike({ liked, postId, commentId })}
                            >
                                {t(`${liked ? 'unlike' : 'like'}`)}
                            </span>
                            •
                            <span
                                className="ps-2 text-primary cursor-pointer pe-2"
                                onClick={() => {
                                    if (activeReplyBoxes[commentId]) {
                                        if (!replyBoxInputRef?.current?.value) {
                                            delete activeReplyBoxes[commentId];
                                        }
                                    } else {
                                        activeReplyBoxes[commentId] = true;
                                    }
                                    setActiveReplyBoxes({ ...activeReplyBoxes });
                                }}
                            >
                                {t('reply')}
                            </span>
                            <div className="flex-1"></div>
                            {likesCount > 0 && (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-for-likers">
                                            <Flex direction="column" className="gap-1" justifyContent="flex-start" alignItems="flex-start">
                                                {getNamesOfLikers(likes).map((name) => (
                                                    <span key={name}>{name}</span>
                                                ))}
                                            </Flex>
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        <Flex className="gap-1 cursor-pointer" alignItems="center">
                                            <FontAwesomeIcon icon={faHeart} className={`text-${liked ? 'danger' : 'default'}`} />
                                            <span>{abbreviateNumber(likesCount)}</span>
                                        </Flex>
                                    </span>
                                </OverlayTrigger>
                            )}
                        </Flex>
                    </div>
                    <Modal show={openDeleteConsent}>
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">{t('delete_comment_question')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t('delete_comment_consent')}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => setOpenDeleteConsent(false)} variant="default">
                                {t('no')}
                            </Button>
                            <Button
                                onClick={() => {
                                    setOpenDeleteConsent(false);
                                    deleteComment(postId, commentId);
                                }}
                                variant="danger"
                            >
                                {t('yes')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Flex>
            )}
            {replies.length > 0 && (
                <Flex
                    justifyContent="flex-end" alignItems="flex-end" className="ms-5">
                    <Flex style={{ justifyContent: 'flex-end' }}>
                        <Flex direction="column" className="mt-3 gap-2">
                            {replies.map((reply) => (
                                <ReplyListTile
                                    key={reply._id}
                                    commentId={commentId}
                                    postId={postId}
                                    deleteReply={() => deleteReply(postId, replies, reply, index)}
                                    toggleReplyLike={() => toggleReplyLike({ replies, liked: reply.liked, postId, commentId, replyId: reply._id })}
                                    compact={true}
                                    mentionables={{ [getFullName(reply.author, { replaceSpacesWithUnderscores: true })]: reply.author.username }}
                                    onNewReplyUpserted={(rep) => onNewReplyUpserted(replies, { ...rep, push: true }, index)}
                                    {...reply}
                                />
                            ))}
                            {repliesCount - (replies ?? []).length > 0 && (
                                <Flex style={{ marginLeft: 15 }} className="gap-3 mt-2" alignItems="center">
                                    {
                                        !fetchingReplies && <hr style={{ width: 30, marginLeft: 15 }} />
                                    }
                                    {
                                        fetchingReplies ?
                                            <Flex
                                                alignItems={'center'}
                                                variant="light"
                                                className="text-500 py-2"
                                            >
                                                <PulseLoader size={5} color="#2c7be5" />
                                                <span> {t('loading')}</span>
                                            </Flex> :
                                            <span onClick={fetchCommentReplies}
                                                className="fs-10 text-700 d-inline-block cursor-pointer">
                                                {t('replies', { count: repliesCount - (replies ?? []).length })}
                                            </span>
                                    }
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            )}
            {
                activeReplyBoxes[commentId] && (
                    <ReplyComposeBox
                        boxId={commentId}
                        className="ms-8"
                        ref={replyBoxInputRef}
                        commentId={commentId}
                        mentionables={{ [getFullName({ first_name, last_name }, { replaceSpacesWithUnderscores: true })]: username }}
                        onNewReplyUpserted={(reply) => onNewReplyUpserted(replies, reply, index)}
                    />
                )
            }
        </div>
    );
};

export default Comment;