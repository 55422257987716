/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import IconAlert from "components/common/IconAlert";
import { useAppContext } from 'providers/AppProvider';

const DeletedContentBanner = ({ message }) => {

    const { config: { isDark } } = useAppContext();

    return (
        <IconAlert variant={isDark ? 'dark' : 'light'} className="mt-3">
            <span className="fs-10 fw-semibold">{message}</span>
        </IconAlert>
    );
}
export default DeletedContentBanner;