import React from 'react';
import App from 'App';
import paths, { rootPaths } from './paths';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';
import Borders from 'components/utilities/Borders';
import Colors from 'components/utilities/Colors';
import Background from 'components/utilities/Background';
import ColoredLinks from 'components/utilities/ColoredLinks';
import Display from 'components/utilities/Display';
import Visibility from 'components/utilities/Visibility';
import StretchedLink from 'components/utilities/StretchedLink';
import Float from 'components/utilities/Float';
import Position from 'components/utilities/Position';
import Spacing from 'components/utilities/Spacing';
import Sizing from 'components/utilities/Sizing';
import TextTruncation from 'components/utilities/TextTruncation';
import Typography from 'components/utilities/Typography';
import VerticalAlign from 'components/utilities/VerticalAlign';
import Flex from 'components/utilities/Flex';
import Grid from 'components/utilities/Grid';
import Starter from 'components/pages/Starter';
import Analytics from 'components/dashboards/analytics';
import Crm from 'components/dashboards/crm';
import Saas from 'components/dashboards/expert';
// import Profile from 'components/pages/user/profile/Profile';
import Associations from 'components/pages/asscociations/Associations';
import Followers from 'components/app/social/followers/Followers';
import Notifications from 'components/app/social/notifications/Notifications';
import ActivityLog from 'components/app/social/activity-log/ActivityLog';
import Feed from 'components/app/social/feed/Feed';
import Calendar from 'components/app/calendar/Calendar';
import FaqAlt from 'components/pages/faq/faq-alt/FaqAlt';
import FaqBasic from 'components/pages/faq/faq-basic/FaqBasic';
import FaqAccordion from 'components/pages/faq/faq-accordion/FaqAccordion';
import PrivacyPolicy from 'components/pages/miscellaneous/privacy-policy/PrivacyPolicy';
import InvitePeople from 'components/pages/miscellaneous/invite-people/InvitePeople';
import PricingDefault from 'components/pages/pricing/pricing-default/PricingDefault';
import PricingAlt from 'components/pages/pricing/pricing-alt/PricingAlt';
import Invoice from 'components/app/e-commerce/Invoice';
import Billing from 'components/app/e-commerce/billing/Billing';
import Checkout from 'components/app/e-commerce/checkout/Checkout';
import ShoppingCart from 'components/app/e-commerce/cart/ShoppingCart';
import CustomersDetails from 'components/app/e-commerce/customers-details/CustomersDetails';
import OrderDetails from 'components/app/e-commerce/orders/order-details/OrderDetails';
import Products from 'components/app/e-commerce/product/Products';
import ProductDetails from 'components/app/e-commerce/product/product-details/ProductDetails';
import AddProduct from 'components/app/e-commerce/product/add-product/AddProduct';
import Orders from 'components/app/e-commerce/orders/order-list/Orders';
import Customers from 'components/app/e-commerce/customers/Customers';
import EmailDetail from 'components/app/email/email-detail/EmailDetail';
import Compose from 'components/app/email/compose/Compose';
import Inbox from 'components/app/email/inbox/Inbox';
import Chat from 'components/app/chat/Chat';
import Ecommerce from 'components/dashboards/e-commerce';
import Lms from 'components/dashboards/lms';
import ProjectManagement from 'components/dashboards/project-management';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import SimpleLogin from 'components/authentication/simple/Login';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimpleRegistration from 'components/authentication/simple/Registration';
import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail';
import Dashboard from 'components/dashboards/default';
import SupportDesk from 'components/dashboards/support-desk';
import TableView from 'components/app/support-desk/tickets-layout/TableView';
import CardView from 'components/app/support-desk/tickets-layout/CardView';
import Contacts from 'components/app/support-desk/contacts/Contacts';
import ContactDetails from 'components/app/support-desk/contact-details/ContactDetails';
import TicketsPreview from 'components/app/support-desk/tickets-preview/TicketsPreview';
import QuickLinks from 'components/app/support-desk/quick-links/QuickLinks';
import Reports from 'components/app/support-desk/reports/Reports';

// New
import CreatorPostPage from 'components/app/social/feed/CreatorPostPage';
import CreatorPodcastPage from 'components/app/social/feed/CreatorPodcastPage';
import SubscribersPage from 'components/app/audience/subscribers';
import UpdateName from 'components/authentication/name/UpdateName';
import UpdateDOB from 'components/authentication/dob/UpdateDOB';
import UpdateGender from 'components/authentication/gender/UpdateGender';
import UpdateInterests from 'components/authentication/interests/UpdateInterests';
import UserProfile from 'components/app/user-profile/profile-details/UserProfileDetails';
import UserProfileEdit from 'components/app/user-profile/edit/Settings';
import UpdatePhoto from 'components/authentication/photo/UpdatePhoto';
import MediaRequests from 'components/app/media-requests';
import MyMediaRequest from 'components/app/media-requests/my-requests';
import AddMediaRequest from 'components/app/media-requests/add-media-request/AddMediaRequest';
import UpdateContentAndOrProfilePreferences from 'components/authentication/content-and-profile-preferences/ContentAndProfilePreferences';
import FeedDetailsPage from 'components/app/social/feed/FeedDetailsPage';
import BillingPage from 'components/app/billing';

const routes = [
  {
    element: <App />,
    children: [
      {
        index: true,
        element: <Landing />
      },
      {
        path: rootPaths.errorsRoot,
        element: <ErrorLayout />,
        children: [
          {
            path: paths.error404,
            element: <Error404 />
          },
          {
            path: paths.error500,
            element: <Error500 />
          }
        ]
      },
      {
        path: rootPaths.authRoot,
        children: [
          {
            path: rootPaths.authSimpleRoot,
            element: <AuthSimpleLayout />,
            children: [
              {
                path: paths.simpleLogin,
                element: <SimpleLogin />
              },
              {
                path: paths.simpleRegister,
                element: <SimpleRegistration />
              },
              {
                path: paths.simpleLogout,
                element: <SimpleLogout />
              },
              {
                path: paths.simpleConfirmMail,
                element: <SimpleConfirmMail />
              },
              {
                path: paths.simpleUpdateName,
                element: <UpdateName />
              },
              {
                path: paths.simpleUpdateDOB,
                element: <UpdateDOB />
              },
              {
                path: paths.simpleUpdateGender,
                element: <UpdateGender />
              },
              {
                path: paths.simpleUpdateInterests,
                element: <UpdateInterests />
              },
              {
                path: paths.simpleUpdatePhoto,
                element: <UpdatePhoto />
              },
              {
                path: paths.simpleUpdateContentPreferences,
                element: <UpdateContentAndOrProfilePreferences />
              }
            ]
          }
        ]
      },
      {
        path: rootPaths.root,
        element: <MainLayout />,
        children: [
          {
            path: rootPaths.dashboardRoot,
            element: <Dashboard />
          },
          {
            path: rootPaths.mediaRequestsRoot,
            children: [
              {
                path: paths.mediaRequests,
                element: <MediaRequests />
              },
              {
                path: paths.myMediaRequests,
                element: <MyMediaRequest />
              },
              {
                path: paths.addMediaRequest,
                element: <AddMediaRequest />
              }
            ]
          },
          {
            path: rootPaths.dashboardRoot,
            children: [
              {
                path: paths.analytics,
                element: <Analytics />
              },
              {
                path: paths.crm,
                element: <Crm />
              },
              {
                path: paths.expert,
                element: <Saas />
              },
              {
                path: paths.ecommerce,
                element: <Ecommerce />
              },
              {
                path: paths.lms,
                element: <Lms />
              },
              {
                path: paths.projectManagement,
                element: <ProjectManagement />
              },
              {
                path: paths.supportDesk,
                element: <SupportDesk />
              }
            ]
          },
          {
            path: rootPaths.appsRoot,
            children: [
              {
                path: paths.calendar,
                element: <Calendar />
              },
              {
                path: paths.chat,
                element: <Chat />
              }
            ]
          },
          {
            path: rootPaths.emailRoot,
            children: [
              {
                path: paths.emailInbox,
                element: <Inbox />
              },
              {
                path: paths.emailDetail,
                element: <EmailDetail />
              },
              {
                path: paths.emailCompose,
                element: <Compose />
              }
            ]
          },
          {
            path: rootPaths.socialRoot,
            children: [
              {
                path: paths.feed,
                element: <Feed />,
                children: [
                  {
                    path: paths.feedItem,
                    element: <FeedDetailsPage />
                  }
                ]
              },
              {
                path: paths.subscribersPage,
                element: <SubscribersPage />
              },
              {
                path: paths.creatorPostPage,
                element: <CreatorPostPage />
              },
              {
                path: paths.creatorPodcastPage,
                element: <CreatorPodcastPage />
              },
              {
                path: paths.activityLog,
                element: <ActivityLog />
              },
              {
                path: paths.notifications,
                element: <Notifications />
              },
              {
                path: paths.followers,
                element: <Followers />
              }
            ]
          },
          {
            path: rootPaths.eCommerceRoot,
            children: [
              {
                path: paths.orderDetails,
                element: <OrderDetails />
              },
              {
                path: paths.orderList,
                element: <Orders />
              },
              {
                path: paths.customers,
                element: <Customers />
              },
              {
                path: paths.customerDetails,
                element: <CustomersDetails />
              },
              {
                path: paths.shoppingCart,
                element: <ShoppingCart />
              },
              {
                path: paths.checkout,
                element: <Checkout />
              },
              {
                path: paths.billing,
                element: <Billing />
              },
              {
                path: paths.invoice,
                element: <Invoice />
              },
              {
                path: paths.addProduct,
                element: <AddProduct />
              },
              {
                path: paths.products(':productLayout'),
                element: <Products />
              },
              {
                path: paths.productDetails(':productId'),
                element: <ProductDetails />
              },
              {
                path: paths.productDetails(),
                element: <ProductDetails />
              }
            ]
          },
          {
            path: rootPaths.supportDeskRoot,
            children: [
              {
                path: paths.ticketsTable,
                element: <TableView />
              },
              {
                path: paths.ticketsCard,
                element: <CardView />
              },
              {
                path: paths.contacts,
                element: <Contacts />
              },
              {
                path: paths.contactDetails,
                element: <ContactDetails />
              },
              {
                path: paths.ticketsPreview,
                element: <TicketsPreview />
              },
              {
                path: paths.quickLinks,
                element: <QuickLinks />
              },
              {
                path: paths.reports,
                element: <Reports />
              }
            ]
          },
          {
            path: rootPaths.pagesRoot,
            children: [
              {
                path: paths.starter,
                element: <Starter />
              }
            ]
          },
          {
            path: rootPaths.userRoot,
            children: [
              {
                path: paths.userProfile(),
                element: <UserProfile />
              },
              {
                path: paths.userProfile(':username'),
                element: <UserProfile />
              },
              {
                path: paths.userProfileEdit,
                element: <UserProfileEdit />
              },
              {
                path: paths.plansAndBillingPage,
                element: <BillingPage />
              }
            ]
          },
          {
            path: rootPaths.pricingRoot,
            children: [
              {
                path: paths.pricingDefault,
                element: <PricingDefault />
              },
              {
                path: paths.pricingAlt,
                element: <PricingAlt />
              }
            ]
          },
          {
            path: rootPaths.faqRoot,
            children: [
              {
                path: paths.faqBasic,
                element: <FaqBasic />
              },
              {
                path: paths.faqAlt,
                element: <FaqAlt />
              },
              {
                path: paths.faqAccordion,
                element: <FaqAccordion />
              }
            ]
          },
          {
            path: rootPaths.miscRoot,
            children: [
              {
                path: paths.associations,
                element: <Associations />
              },
              {
                path: paths.invitePeople,
                element: <InvitePeople />
              },
              {
                path: paths.privacyPolicy,
                element: <PrivacyPolicy />
              }
            ]
          },
          {
            path: rootPaths.utilitiesRoot,
            children: [
              {
                path: paths.backgroundColor,
                element: <Background />
              },
              {
                path: paths.borders,
                element: <Borders />
              },
              {
                path: paths.colors,
                element: <Colors />
              },
              {
                path: paths.coloredLinks,
                element: <ColoredLinks />
              },
              {
                path: paths.display,
                element: <Display />
              },
              {
                path: paths.flex,
                element: <Flex />
              },
              {
                path: paths.float,
                element: <Float />
              },
              {
                path: paths.grid,
                element: <Grid />
              },
              {
                path: paths.position,
                element: <Position />
              },
              {
                path: paths.spacing,
                element: <Spacing />
              },
              {
                path: paths.sizing,
                element: <Sizing />
              },
              {
                path: paths.stretchedLink,
                element: <StretchedLink />
              },
              {
                path: paths.textTruncation,
                element: <TextTruncation />
              },
              {
                path: paths.typography,
                element: <Typography />
              },
              {
                path: paths.verticalAlign,
                element: <VerticalAlign />
              },
              {
                path: paths.visibility,
                element: <Visibility />
              }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to={paths.error404} replace />
      }
    ]
  }
];

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL
});

export default routes;
