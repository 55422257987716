/* eslint-disable no-undef */
import MainLayout from 'layouts/MainLayout';
import React from 'react';
const { default: useUserStore } = require('store/userStore');
import { Navigate, useLocation } from 'react-router-dom';
import paths from 'routes/paths';

const withAuth = (Component, landing = false) => {
  const AuthenticatedComponent = () => {
    const authenticatedUser = useUserStore(state => state.authenticatedUser);
    const location = useLocation();

    if (authenticatedUser) {
      const firstName = authenticatedUser.first_name;
      const lastName = authenticatedUser.last_name;
      if (!firstName && !lastName) {
        return <Navigate to={paths.simpleUpdateName} />;
      }
      // const birthDate = authenticatedUser.birth_date;
      // if (!birthDate) {
      //   return <Navigate to={paths.simpleUpdateDOB} />;
      // }
      // const gender = authenticatedUser.gender;
      // if (!gender) {
      //   return <Navigate to={paths.simpleUpdateGender} />;
      // }
      const interests = authenticatedUser.interests ?? [];
      if (interests.length === 0) {
        return <Navigate to={paths.simpleUpdateInterests} />;
      }
      // const avatar = authenticatedUser.avatar;
      // if (!avatar) {
      //   return <Navigate to={paths.simpleUpdatePhoto} />;
      // }
      // const preferences = authenticatedUser.preferences ?? {};
      // if (!preferences.discovery) {
      //   return <Navigate to={paths.simpleUpdateContentPreferences} />;
      // }
      if (landing) {
        return <MainLayout />;
      }
      return <Component />;
    }
    if (location.pathname !== '/auth/s/login') {
      return <Navigate to={'/auth/s/login'} />;
    }
    return <Component />;
  };

  return AuthenticatedComponent;
};
export default withAuth;
