/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Card,
  Col,
  Form,
  Row,
  Button,
  OverlayTrigger,
  Tooltip,
  Spinner
} from 'react-bootstrap';
import TextareaAutoSize from 'react-textarea-autosize';
import APIService from 'http/api_service';
import useMediaRequestStore from 'store/media_request_store';
import usePublishableMediaRequestStore from 'store/publishable_media_request_store';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import MultiSelect from 'components/common/MultiSelect';
import { profileTopics } from 'components/app/social/feed/data/profileTopics';
import Flex from 'components/common/Flex';

const AddMediaRequest = ({ setOpenAddMediaRequestDialog }) => {

  const { t } = useTranslation();
  const { control, reset } = useForm();
  const mediaRequest = usePublishableMediaRequestStore(state => state.mediaRequest);
  const setMediaRequest = usePublishableMediaRequestStore(state => state.setMediaRequest);
  const mediaRequests = useMediaRequestStore(state => state.mediaRequests);
  const setMediaRequests = useMediaRequestStore(state => state.setMediaRequests);
  const [posting, setPosting] = useState(false);
  const status = [
    {
      "label": "Free to pitch",
      "value": "free_to_pitch"
    },
    {
      "label": "Easy win",
      "value": "easy_win"
    },
    {
      "label": "No deadline",
      "value": "no_deadline"
    }
  ];

  const handleSubmit = async e => {
    e.preventDefault();
    setPosting(true);
    setMediaRequest({ ...mediaRequest });
    APIService.upsertMediaRequest(mediaRequest, (response, error) => {
      setPosting(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      setMediaRequest({});
      reset({});
      let { message, data } = response;
      let mediaRequestsCopy = [...mediaRequests];
      let requestIndex = mediaRequestsCopy.findIndex(req => req._id === data._id);
      if (requestIndex !== -1) {
        mediaRequestsCopy[requestIndex] = data;
      } else {
        mediaRequestsCopy.unshift(data);
      }
      setMediaRequests([...mediaRequestsCopy]);
      if (setOpenAddMediaRequestDialog) {
        setOpenAddMediaRequestDialog(false);
      } else {
        toast.success(message, { theme: 'colored' });
      }
    });
  }

  const preRequestTooltip = () => {
    if (
      !mediaRequest.question ||
      !mediaRequest.publication_url ||
      !mediaRequest.expected_delivery_date
    ) {
      return t('please_fill_all_required_fields');
    }
    return null;
  };

  return (
    <Row className="g-2">
      <Col lg={10}>
        <Form onSubmit={handleSubmit} style={{ transition: 'all .2s' }}>
          <Card className="mb-3 shadow-none border border-200">
            <Card.Body>
              <Row className="gx-2 gy-3">
                <Col md="12">
                  <Form.Group controlId="title">
                    <Form.Label>{t('media_request_question_title')}</Form.Label>
                    <Form.Control
                      type="text"
                      value={mediaRequest?.title ?? ''}
                      name="title"
                      onChange={e => {
                        setMediaRequest({
                          ...mediaRequest,
                          title: e.target.value
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12">
                  <Form.Group>
                    <Form.Label>{t('media_request_question')}</Form.Label>
                    <TextareaAutoSize
                      id="media_request_question"
                      minRows={4}
                      as="textarea"
                      value={mediaRequest?.question ?? ''}
                      placeholder={t('give detail explanation of what you are looking for')}
                      className="form-control outline-none resize-none rounded-0"
                      style={{
                        width: '100%',
                        outline: 'none',
                        whiteSpace: 'pre-wrap',
                        display: 'inherit',
                        background: '#fff',
                        color: 'inherit',
                        fontSize: 'inherit'
                      }}
                      maxRows={10}
                      onChange={e => {
                        setMediaRequest({
                          ...mediaRequest,
                          question: e.target.value
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group controlId="publication_url">
                    <Form.Label>{t('media_request_publication_url')}</Form.Label>
                    <Form.Control
                      type="text"
                      value={mediaRequest?.publication_url ?? ''}
                      name="publication_url"
                      onChange={e => {
                        setMediaRequest({
                          ...mediaRequest,
                          publication_url: e.target.value
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group controlId="expected_delivery_date">
                    <Form.Label>{t('media_request_expected_delivery_date')}</Form.Label>
                    <ReactDatePicker
                      selected={mediaRequest?.expected_delivery_date ?? ''}
                      onChange={newDate => {
                        setMediaRequest({
                          ...mediaRequest,
                          expected_delivery_date: newDate
                        });
                      }}
                      minDate={new Date()}
                    />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group>
                    <Form.Label>
                      Topics (Select as many as you wish)<span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      name="topics"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <MultiSelect
                          {...field}
                          closeMenuOnSelect={false}
                          isMulti
                          options={profileTopics}
                          placeholder="Select topics..."
                          onChange={selected => {
                            field.onChange(selected);
                            setMediaRequest({
                              ...mediaRequest,
                              topics: selected.map(item => item.value)
                            });
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group>
                    <Form.Label>
                      Select status <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <MultiSelect
                          {...field}
                          closeMenuOnSelect={false}
                          isMulti
                          options={status}
                          placeholder="Select status..."
                          onChange={selected => {
                            field.onChange(selected);
                            setMediaRequest({
                              ...mediaRequest,
                              status: selected.map(item => item.value)
                            });
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <OverlayTrigger
                overlay={
                  preRequestTooltip() != null ? (
                    <Tooltip
                      style={{ position: 'fixed' }}
                      id="pre-publish-request-trigger"
                    >
                      {preRequestTooltip()}
                    </Tooltip>
                  ) : (
                    <div></div>
                  )
                }
              >
                <span>
                  <Button
                    disabled={
                      posting ||
                      !mediaRequest.title ||
                      !mediaRequest.question ||
                      !mediaRequest.expected_delivery_date
                    }
                    type="submit"
                  >
                    {posting ? <Flex alignItems={'center'} className={'gap-1'}><Spinner animation='border' size='sm' /><span>Submitting...</span></Flex> : mediaRequest._id ? t('update') : t('submit')}
                  </Button>
                </span>
              </OverlayTrigger>
            </Card.Footer>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export default AddMediaRequest;
