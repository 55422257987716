/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
import FeedCardBody from './FeedCardBody';

const FeedCard = ({ feed, onDeleteDoneCallback, ...rest }) => {
  const post = JSON.parse(JSON.stringify(feed));
  const {
    _id,
    created_at,
    topics,
    author,
    text,
    media,
    likesCount,
    commentsCount,
    liked,
    commented,
    likes,
    privacy,
    comments
  } = feed;
  const navigate = useNavigate();

  const handleCardClick = (_event) => {
    navigate(`${paths.feed}/${post._id}`);
  };

  return (
    <Card
      id={`${post._id}-card`}
      onClick={handleCardClick}
      {...rest}
      className='elevation-none shadow-none'>
      <FeedCardBody onDeleteDoneCallback={onDeleteDoneCallback} {...{ author, _id, liked, likesCount, commentsCount, commented, likes, comments, created_at, privacy, topics, text, media, post }} />
    </Card>
  );
};
export default FeedCard;
