import { version } from 'config';
import packageInfo from '../../package.json';

const { homepage } = packageInfo;

export const faqs = [
  {
    id: 0,
    title: `How will you bill me?`,
    description: `We offer monthly billing and annual billing. Depending on what option you choose, your membership will be renewed at the end of each month or at the end of each year. If you choose to pay annually, you could save up to 33% compared to paying monthly.`
  },
  {
    id: 1,
    title: 'Can I change or cancel my plan later on?',
    description: `Yes. You can upgrade or cancel anytime from your settings page. When you cancel, you will lose your Premium features at the end of your billing cycle.`
  },
  {
    id: 3,
    title: `Can I expense my Premium membership?`,
    description: `Many employers find Publishable Premium so valuable that they’ll pay for their employees’ memberships. After your purchase, you’ll receive the receipt in your email that you can use to file an expense report.`
  },
  {
    id: 6,
    title: `Do you offer academic pricing?`,
    description: `We're happy to work with student groups using Publishable. Contact Us`
  },
  {
    id: 8,
    title: `What is your refund policy?`,
    description: `We do not offer refunds apart from exceptions listed below. If you cancel your plan before the next renewal cycle, you will retain access to paid features until the end of your subscription period. When your subscription expires, you will lose access to paid features and all data associated with those features. Exceptions to our refund policy: canceling within 48 hours of initial charge will result in a full refund. If you cancel within this timeframe, you will lose access to paid features and associated data immediately upon canceling.`
  }
];

export const faqAccordions = [
  {
    id: 0,
    title: 'How long do payouts take?',
    description:
      '<p class = "mb-0">Once you’re set up, payouts arrive in your bank account on a 2-day rolling basis. Or you can opt to receive payouts weekly or monthly.</p>'
  },
  {
    id: 1,
    title: 'How do refunds work?',
    description:
      '<p class = "mb-0">You can issue either partial or full refunds. There are no fees to refund a charge, but the fees from the original charge are not returned.</p>'
  },
  {
    id: 2,
    title: 'How much do disputes costs?',
    description:
      '<p class = "mb-0">Disputed payments (also known as chargebacks) incur a $15.00 fee. If the customer’s bank resolves the dispute in your favor, the fee is fully refunded.</p>'
  },
  {
    id: 3,
    title: 'Is there a fee to use Apple Pay or Google Pay?',
    description:
      '<p class = "mb-0">There are no additional fees for using our mobile SDKs or to accept payments using consumer wallets like Apple Pay or Google Pay.</p>'
  }
];

export const faqDoc = [
  {
    id: 1,
    title: 'How to start working with Falcon React',
    description: `<p class='mb-0'>Install latest version of Node js to your PC <a href='https://nodejs.dev/' target="_blank">https://nodejs.dev/</a> Download the template file and unzip it.
    You will get 2 files in the folder. Now unzip the <code>falcon-react-${version}</code> directory and open this directory from your terminal.
    Run <code>npm install </code> from your terminal.
    After installing all the required packages, run <code>npm start</code>. <br>
    Please check the following link to see How to Getting started in Falcon React: <a target='_blank' href='//${homepage}/documentation/getting-started'>${homepage}/documentation/getting-started<a/>.</p>`
  },
  {
    id: 2,
    title:
      'Is there a specific version of Node.js I should use for Falcon React?',
    description: `<p class="mb-0">Node version to <code>16.15.1</code> or any further updated version.</p>`
  },

  {
    id: 3,
    title: 'What React library is used for general API integration?',
    description: `<p class="mb-0">We used react context API for general API integration.</p>`
  },
  {
    id: 4,
    title: 'How are the SEO headers built?',
    description: `<p class="mb-0">We didn't build with any SEO headers by default. You have to integrate it manually by yourself.</p>`
  },
  {
    id: 5,
    title:
      'Which bootstrap react framework you are using? Reactstrap or React-Bootstrap?',
    description: `<p class="mb-0">We used the <b>reactstrap</b> for Falcon React <code>v2.x</code> and <b>React bootstrap</b> for Falcon React <code>v3.x</code>.</p>`
  },
  {
    id: 6,
    title: 'Where will I get the Figma Files?',
    description: `<p >You will find the Figma link in the <code>README.md</code> file on your downloaded copy of Falcon. Here is the detailed documentation: <a target='_blank' href='//${homepage}/documentation/design-file'> ${homepage}/documentation/design-file</a>.</p>
    <p class='mb-0'>If you still have any problem with the link, reach us out with your purchase ID at <a href="mailto:support@themewagon.com">support@themewagon.com</a></p>`
  },
  {
    id: 7,
    title:
      'What are the bare minimum files just to get the layout and navigation?',
    description: `<p >You can start from the Falcon’s Starter page where the minimum required components are included to get layout and navigation.
    More specifically, the below list of files are required to work with layout and navigation:<br>
    <code>src/assets</code><br>
    <code>src/components/common</code><br>
    <code>src/components/footer</code><br>
    <code>src/components/navbar</code><br>
    <code>src/components/notification</code><br>
    <code>src/components/pages/Starter.js</code><br>
    <code>src/components/settings-panel</code><br>
    <code>src/context</code><br>
    <code>src/data</code><br>
    <code>src/helpers</code><br>
    <code>src/hooks</code><br>
    <code>src/layouts/Layout.js</code><br>
    <code>src/layouts/MainLayout.js</code><br>
    <code>src/layouts/MainRoutes.js</code><br>
    <code>src/reducers</code><br>
    <code>src/routes</code><br>
    <code>src/App.js</code><br>
    <code>src/Config.js</code><br>
    <code>src/Index.js</code><br>
    <code>src/Main.js</code></p>

    <p>Note:  Remember deleting other files/directories will be the cause of errors. You have to remove the corresponding import statement and unnecessary lines from your code manually.
    </p>
    <p class="mb-0">We have created a mini version of Falcon React with all the essential files only. If you are interested, please reach us out with your purchase ID at <a href="mailto:support@themewagon.com">support@themewagon.com</a>.
    </p>`
  },
  {
    id: 8,
    title: 'How to compile SCSS files with Falcon React?',
    description: `<p class="mb-0">To compile scss files in Falcon react run npm run <code>build:css</code> from your terminal, or you can watch the scss files by running npm run <code>watch:css</code> to a separate terminal. 
    Check the link below to have a look on the styling documentation in Falcon React <a target='_blank' href='//${homepage}/documentation/styling'> ${homepage}/documentation/styling</a>
    </p>`
  },
  {
    id: 9,
    title: 'How to update from Falcon React v2.x to the upper version?',
    description: `<p><b>Updating from any earlier version of 3.x to ${version}:</b></br>
    Check out the following link and follow the changes: <a target='_blank' href='//${homepage}/changelog'> ${homepage}/changelog</a>
    </p>
    <p class='mb-0'><b>Updating from version 2 to version 3:</b></br>
    As the latest versions of templates and all their packages have different structures behind their components, you have to update all the components manually into your project.<br>
    So, it would be better to start building applications with the Bootstrap 5 version of Falcon from scratch. You can include any components from your existing project to your current project. Bring the component and its required SCSS and place it into your current project.
    </p>`
  }
];
