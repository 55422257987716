import React, { useState } from 'react';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import SubmitButton from 'components/common/SubmitButton';
import SwitchEmail from 'components/authentication/simple/EmailSwitch';
import ReactDatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import IconButton from 'components/common/IconButton';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import useUserStore from 'store/userStore';
import { useNavigate } from 'react-router-dom';
import { rootPaths } from 'routes/paths';

const UpdateDOB = () => {
  const { t } = useTranslation();
  const minAge = 16;
  const maxBirthYear = DateTime.local().minus({ years: minAge }).year;
  const minBirthYear = DateTime.local().minus({ years: minAge * 4 }).year;
  const years = Array.from(
    { length: maxBirthYear - minBirthYear + 1 },
    (_, i) => maxBirthYear - i
  );
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const [processing, setProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const setAuthenticatedUser = useUserStore(
    state => state.setAuthenticatedUser
  );
  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    setProcessing(true);
    APIService.updateAccountDetails(formData, (response, err) => {
      setProcessing(false);
      if (err) {
        toast.error(err);
        return;
      }
      let { data } = response;
      setAuthenticatedUser(data);
      navigate(rootPaths.root);
    });
  };

  return (
    <>
      <Flex
        justifyContent="between"
        direction={'column'}
        alignItems="center"
        className="mb-2"
      >
        <h5>{t('date_of_birth')}</h5>
        <p>{t('when_were_you_born')}</p>
      </Flex>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <ReactDatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            }) => (
              <Flex
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <IconButton
                  className="box-shadow-none shadow-none"
                  variant="falcon-default"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {'<'}
                </IconButton>
                <Form.Select
                  value={months[DateTime.fromJSDate(date).month - 1]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Select>
                <Form.Select
                  value={DateTime.fromJSDate(date).year}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Select>

                <IconButton
                  className="box-shadow-none shadow-none"
                  variant="falcon-default"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {'>'}
                </IconButton>
              </Flex>
            )}
            value={
              formData.birth_date
                ? DateTime.fromJSDate(formData.birth_date).toFormat(
                    "MMMM',' dd yyyy"
                  )
                : ''
            }
            className="form-control"
            selected={
              formData.birth_date ??
              DateTime.fromObject({ year: maxBirthYear }).toJSDate()
            }
            dateFormat="dd/MM/yyyy"
            placeholderText={t('select_dob')}
            onChange={date => setFormData({ birth_date: date })}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <SubmitButton
            className="w-100"
            disabled={!formData.birth_date}
            processing={processing}
            label={t('next')}
          />
        </Form.Group>
      </Form>
      <SwitchEmail />
    </>
  );
};

export default UpdateDOB;
