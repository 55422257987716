/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import CryptoJS from 'crypto-js';

const encryptionAlg = 'aes-256-ctr';

const SHA256 = (data) => {
    return CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex);
};

const encryptData = (data, secretKey) => {
    const iv = CryptoJS.lib.WordArray.random(16);
    const key = CryptoJS.enc.Hex.parse(secretKey);
    const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
    return {
        iv: iv.toString(CryptoJS.enc.Hex),
        content: encrypted.ciphertext.toString(CryptoJS.enc.Hex),
    };
};

const decryptData = (hash, secretKey) => {
    const key = CryptoJS.enc.Hex.parse(secretKey);
    const iv = CryptoJS.enc.Hex.parse(hash.iv);
    const encryptedHex = CryptoJS.enc.Hex.parse(hash.content);
    const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHex);
    const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
};

export { SHA256, encryptData, decryptData };
