/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { useTranslation } from 'react-i18next';
import AddMediaRequest from 'components/app/media-requests/add-media-request/AddMediaRequest';
import { useBreakpoints } from 'hooks/useBreakpoints';

const MediaRequestHeader = ({ setShowMediaRequestFilterOffcanvas }) => {

  const { getSelectedRowModel } = useAdvanceTableContext();
  const { t } = useTranslation();
  const [openAddMediaRequestDialog, setOpenAddMediaRequestDialog] = useState(false);
  const { breakpoints } = useBreakpoints();

  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h5 className="mb-0">{t('media_request_page_title')}</h5>
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="filter"
          transform="shrink-4"
          iconAlign="middle"
          onClick={() => setShowMediaRequestFilterOffcanvas(true)}
          className="d-xl-none"
        >
          <span className="d-none d-sm-inline-block ms-1">Filter</span>
        </IconButton>
        <div
          className="bg-300 mx-3 d-none d-lg-block d-xl-none"
          style={{ width: '1px', height: '29px' }}
        ></div>
        {getSelectedRowModel().rows.length > 0 ? (
          <div className="d-flex">
            <Form.Select size="sm" aria-label="Bulk actions">
              <option>Bulk Actions</option>
              <option value="refund">Refund</option>
              <option value="delete">Delete</option>
              <option value="archive">Archive</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
            >
              Apply
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
            <IconButton
              onClick={() => setOpenAddMediaRequestDialog(true)}
              variant="primary"
              size="sm"
              icon="plus"
              transform="shrink-3"
              iconAlign="middle"
            >
              <span className="ms-1">Submit a request</span>
            </IconButton>
          </div>
        )}
      </div>

      <Offcanvas
        style={{ width: breakpoints.down('xl') ? '100%' : '60%' }}
        fullscreen={true}
        placement="end"
        show={openAddMediaRequestDialog}
        onHide={() => setOpenAddMediaRequestDialog(false)}
      >
        <Offcanvas.Header closeButton closeVariant="dark">
          <Offcanvas.Title>Submit a media request</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddMediaRequest setOpenAddMediaRequestDialog={setOpenAddMediaRequestDialog} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

MediaRequestHeader.propTypes = {
  setShowMediaRequestFilterOffcanvas: PropTypes.func.isRequired,
  layout: PropTypes.string
};

export default MediaRequestHeader;
