import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Section from 'components/common/Section';
import recentbackgroundshape1 from 'assets/img/generic/recent-background-shape-2.png';
import recentbackgroundshape3 from 'assets/img/generic/recent-background-shape-3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Cta = () => {
  let loggedIn = localStorage.getItem('cs_user_id');
  return (
    <Section
      overlay
      position="center top"
      style={{ backgroundColor: '#2c7be5' }}
    >
      <img
        src={recentbackgroundshape1}
        alt=""
        height="220"
        className="position-absolute l-0 b-0 d-none d-md-block"
      />

      <div className="">
        <img
          src={recentbackgroundshape3}
          alt=""
          className="position-absolute r-0 b-0 d-none d-md-block"
          style={{ bottom: '-79px', right: '-79px' }}
        />
        <Row className="justify-content-center text-center">
          <Col lg={8}>
            <p className="fs-6">
              Join the fastest growing community of Creators
            </p>
            {loggedIn ? (
              <Button
                tag={Link}
                to="/dashboard/swap"
                color="outline-light"
                size="lg"
                className="border-2x rounded-pill mt-2 fs-0 py-2"
              >
                GO TO DASHBOARD
              </Button>
            ) : (
              <Button
                tag={Link}
                to="/register"
                color="outline-light"
                size="lg"
                className="border-2x rounded-pill mt-2 fs-0 py-2"
              >
                Sign up
              </Button>
            )}
            <p
              className="font-weight-medium mb-1 mt-3"
              style={{
                paddingLeft: '1.5em',
                textIndent: '-1.5em',
                fontSize: '16px'
              }}
            >
              <FontAwesomeIcon
                icon="check-circle"
                className="text-success mr-2"
              />
              Reachable is free
            </p>
          </Col>
        </Row>
      </div>
    </Section>
  );
};
export default Cta;
