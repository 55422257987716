/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import APIService from 'http/api_service';
import { useTranslation } from 'react-i18next';
import useTempStore from 'store/tempStore';
import SubmitButton from 'components/common/SubmitButton';

// eslint-disable-next-line react/prop-types
const LoginForm = ({ hasLabel, loosedSocials }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: ''
  });
  const [processing, setProcessing] = useState(false);
  const setAuthenticatingEmail = useTempStore(
    state => state.setAuthenticatingEmail
  );
  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    setProcessing(true);
    setAuthenticatingEmail(formData.email);
    APIService.authenticateWithMagicLink(formData.email, (_, error) => {
      setProcessing(false);
      if (error) {
        toast.error(error);
        return;
      }
      navigate(paths.simpleConfirmMail);
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t('email_address')}</Form.Label>}
        <Form.Control
          disabled={processing}
          placeholder={!hasLabel ? `${t('email_address')}` : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
        <Form.Text className="text-muted">
          A verification email will be sent to the email address for login.
        </Form.Text>
      </Form.Group>
      <Form.Group>
        <SubmitButton
          className="w-100"
          disabled={!formData.email || processing}
          label={`${t('log')} ${t('in')}`}
          processing={processing}
        />
      </Form.Group>
    </Form>
  );
};

export default LoginForm;
