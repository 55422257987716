export const RoutePaths = {
  DEFAULT: '/',
  VERIFY_EMAIL_ON_SIGNUP: '/verify_email_on_signup',
  LOGIN: '/login',
  LOGOUT: '/logout',
  TELEGRAM_LOGIN_PROXY: '/telegram_login_proxy',
  PAYMENT_PORTAL_PROXY: '/payment_portal_proxy',
  REGISTER: '/register',
  NOT_FOUND: '/404',
  BROKEN_PAGE: '/500',
  FORGOT_PASSWORD: '/forgotpwd',
  RESET_PASSWORD: '/reset_pwd',
  INVITATIONS: '/invitations',
  PASSWORD_LESS_SIGN_IN: '/passwordless_login',
  VERIFY_NEW_EMAIL: '/verify_new_email',
  SWITCH_WORKSPACE: '/switch',
  WHITELABEL_SETUP: '/white-label-setup',
  WHITELABEL_SETTINGS: '/white-label-settings',
  IMAGE_EDITOR_FULLSCREEN: '/image_editor',
  VIDEO_EDITOR_FULLSCREEN: '/video_editor',
  IMAGE_BACKGROUND_REMOVAL: '/image_background_removal',
  MANDATORY_PLAN_UPGRADE: '/pick-a-plan',
  LTD_PURCHASE_PAGE: '/purchase-ltd',
  LTD_UPGRADE_PAGE: '/ltd_upgrade',
  get IDEA_SPACE() {
    return `${this.WORKSPACE}/ideas-space`;
  },
  get POSTS() {
    return `${this.WORKSPACE}/posts`;
  },
  get SCHEDULE_A_POLL() {
    return `${this.WORKSPACE}/schedule-a-poll`;
  },
  get PUBLISHED_POSTS() {
    return `${this.WORKSPACE}/posts/published`;
  },
  get SCHEDULED_POSTS() {
    return `${this.WORKSPACE}/posts/scheduled`;
  },
  get RECURRING_POSTS() {
    return `${this.WORKSPACE}/posts/recurring`;
  },
  get PENDING_APPROVAL() {
    return `${this.WORKSPACE}/posts/pending_approval`;
  },
  get FAILED_POSTS() {
    return `${this.WORKSPACE}/posts/failed`;
  },
  get POST_CONTENT_PREVIEW_EDITOR() {
    return `${this.WORKSPACE}/creating-content/preview`;
  },
  get PEXELS_CONTENTS() {
    return `${this.WORKSPACE}/posts/pexels_contents`;
  },
  get SHUTTERSTOCK_CONTENTS() {
    return `${this.WORKSPACE}/posts/shutterstock_contents`;
  },
  get GDRIVE_CONTENTS() {
    return `${this.WORKSPACE}/gdrive_contents`;
  },
  get DRAFTS_POSTS() {
    return `${this.WORKSPACE}/posts/drafts`;
  },
  get PLATFORMS() {
    return `${this.WORKSPACE}/platforms`;
  },
  get PLANNER() {
    return `${this.WORKSPACE}/content-planner`;
  },
  get RSS_FEED() {
    return `${this.WORKSPACE}/rss-feed`;
  },
  get BULK_WORKSPACES() {
    return `${this.WORKSPACE}/bulk-workspaces`;
  },
  get BULK_POSTS() {
    return `${this.WORKSPACE}/posts/bulk-posts`;
  },
  get TEAM() {
    return `${this.WORKSPACE}/team`;
  },
  get FACEBOOK_ANALYTICS() {
    return `${this.WORKSPACE}/analytics/facebook`;
  },
  get LINKEDIN_ANALYTICS() {
    return `${this.WORKSPACE}/analytics/linkedin`;
  },
  get INSTAGRAM_ANALYTICS() {
    return `${this.WORKSPACE}/analytics/instagram`;
  },
  get YOUTUBE_ANALYTICS() {
    return `${this.WORKSPACE}/analytics/youtube`;
  },
  get ACCOUNT() {
    return `${this.WORKSPACE}/account`;
  },
  get BILLING3() {
    return `${this.WORKSPACE}/billing?t=scheduler`;
  },
  get REFER() {
    return `${this.WORKSPACE}/refer`;
  },
  get CREDIT() {
    return `${this.WORKSPACE}/account/credits`;
  },
  get BILLING() {
    return `${this.WORKSPACE}/billing`;
  },
  get SCHEDULER_BILLING() {
    return `${this.WORKSPACE}/billing/scheduler`;
  },
  get ALl_BILLING() {
    return `${this.WORKSPACE}/billing/all`;
  },
  get UNLIMITED_AI_BILLING() {
    return `${this.WORKSPACE}/billing/unlimited_ai`;
  },
  get REDEEM_CODE() {
    return `${this.WORKSPACE}/billing?t=billing`;
  },
  get WORKSPACE_SETTINGS() {
    return `${this.WORKSPACE}/settings`;
  },
  get NEW_WORKSPACE() {
    return `/new_workspace`;
  },
  get SUPPORT() {
    return `${this.WORKSPACE}/support`;
  },
  get NOTIFICATIONS() {
    return `${this.WORKSPACE}/notifications`;
  },
  get AI_WRITER() {
    return `${this.WORKSPACE}/ai_writer`;
  },
  get AI_DASHBOARD() {
    return `${this.WORKSPACE}/ai/dashboard`;
  },
  get AI_WRITER_PRODUCTION_DESCRIPTION() {
    return `${this.WORKSPACE}/ai_writer/ProductDescription`;
  },
  get AI_WRITER_POST_CAPTIONS() {
    return `${this.WORKSPACE}/ai_writer/postCaptions`;
  },
  get AI_WRITER_POST_IDEAS() {
    return `${this.WORKSPACE}/ai_writer/postIdeas`;
  },
  get AI_WRITER_SOCIAL_MEDIA_INSTAGRAM_CAPTIONS() {
    return `${this.WORKSPACE}/ai_writer/instagramCaptions`;
  },
  get AI_WRITER_POST_CALL_TO_ACTION() {
    return `${this.WORKSPACE}/ai_writer/postCallToAction`;
  },
  get AI_WRITER_VIDEO_IDEAS() {
    return `${this.WORKSPACE}/ai_writer/videoIdeas`;
  },
  get AI_WRITER_VIDEO_CAPTIONS() {
    return `${this.WORKSPACE}/ai_writer/videoCaptions`;
  },
  get AI_WRITER_VIDEO_CALL_TO_ACTION() {
    return `${this.WORKSPACE}/ai_writer/videoCallToAction`;
  },
  get AI_WRITER_SEO_META_TITLE() {
    return `${this.WORKSPACE}/ai_writer/seoMetaTitle`;
  },
  get AI_WRITER_HASHTAG_GENERATOR() {
    return `${this.WORKSPACE}/ai_writer/hashtagGenerator`;
  },
  get AI_WRITER_SEO_META_DESCRIPTION() {
    return `${this.WORKSPACE}/ai_writer/seoMetaDescription`;
  },
  get AI_WRITER_BLOG_SECTION() {
    return `${this.WORKSPACE}/ai_writer/blogSection`;
  },
  get AI_WRITER_BLOG_CAPTIONS() {
    return `${this.WORKSPACE}/ai_writer/blogCaptions`;
  },
  get AI_WRITER_BLOG_IDEAS() {
    return `${this.WORKSPACE}/ai_writer/blogIdeas`;
  },
  get AI_WRITER_GOOGLE_ADS() {
    return `${this.WORKSPACE}/ai_writer/googleAds`;
  },
  get AI_WRITER_FACEBOOK_ADS() {
    return `${this.WORKSPACE}/ai_writer/facebookAds`;
  },
  get AI_WRITER_LINKEDIN_AD() {
    return `${this.WORKSPACE}/ai_writer/linkedInAds`;
  },
  get AI_WRITER_TWITTER_AD() {
    return `${this.WORKSPACE}/ai_writer/twitterAds`;
  },
  get AI_WRITER_INSTAGRAM_AD() {
    return `${this.WORKSPACE}/ai_writer/instagramAds`;
  },
  get AI_WRITER_AttentionInterestDesireAction() {
    return `${this.WORKSPACE}/ai_writer/AttentionInterestDesireAction`;
  },
  get AI_WRITER_PainAgitateSolution() {
    return `${this.WORKSPACE}/ai_writer/PainAgitateSolution`;
  },
  get AI_WRITER_FeatureToBenefits() {
    return `${this.WORKSPACE}/ai_writer/FeatureToBenefits`;
  },
  get AI_WRITER_FeatureAdvantageBenefits() {
    return `${this.WORKSPACE}/ai_writer/FeatureAdvantageBenefits`;
  },
  get AI_ART() {
    return `${this.WORKSPACE}/ai_art`;
  },
  get IMAGE_EDITOR() {
    return `${this.WORKSPACE}/image-editor`;
  },
  get VIDEO_EDITOR() {
    return `${this.WORKSPACE}/video-editor`;
  },
  get LINK_SHORTENER() {
    return `${this.WORKSPACE}/link-tools/link-shortener`;
  },
  get CAMPAIGN_BUILDER() {
    return `${this.WORKSPACE}/link-tools/campaign-builder`;
  },
  get QR_CODE() {
    return `${this.WORKSPACE}/link-tools/qr-code`;
  },
  get INTEGRATION() {
    return `${this.WORKSPACE}/link-tools/integration`;
  },
  get CUSTOM_DOMAIN() {
    return `${this.WORKSPACE}/link-tools/custom-domain`;
  },
  get REVIEW_LINK_GENERATOR() {
    return `${this.WORKSPACE}/link-tools/google-pro-link`;
  },
  get GOOGLE_MY_BUSINESS_LOCATIONS() {
    return `${this.WORKSPACE}/link-tools/google-business-locations`;
  }
};

export const Strings = {
  ACTIVE_WORKSPACE: '__postly_active_workspace_data__',
  LOGGED_IN_USER: '__postly_logged_in_user__',
  LOGGED_IN_USER_API_KEY: '_x_key',
  LOGGED_IN_ALT_USERS: '__postly_logged_in_alt_users__',
  CACHED_WORKSPACES: '_postly_cached_workspaces_list',
  API_KEY: 'X-API-Key',
  LOGGED_IN: 'logged_in',
  ALTABLE_USER: '_altable_postly_user',
  POST_CATEGORY_MAPPINGS: '_postly_post_category_mappings_'
};

export const KeyCodes = {
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  M: 77
};

export const AuthMethod = {
  PASSWORD_LESS: 'password_less',
  PASSWORD: 'password',
  GOOGLE_AUTH: 'google_auth',
  FACEBOOK_AUTH: 'facebook_auth'
};

export const PlatformIdentifier = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  TIKTOK: 'tiktok',
  GOOGLE_MY_BUSINESS: 'google_my_business',
  LINKED_IN: 'linked_in',
  PINTEREST: 'pinterest',
  REDDIT: 'reddit',
  TELEGRAM: 'telegram',
  GOOGLE: 'google',
  WHATSAPP: 'whatsapp',
  WORDPRESS: 'wordpress',
  RSS_FEED: 'rss_feed',
  BLOGGER: 'blogger'
};

export const TermsAndPolicies = {
  PRIVACY_POLICY: 'https://reachable.chat/privacy-policy',
  TERMS_OF_SERVICE: 'https://reachable.chat/tos',
  COOKIE_POLICY: 'https://www.reachable.chat/cookie-policy',
  DISCLAIMER: 'https://reachable.chat/disclaimer',
  REFUND_POLICY: 'https://reachable.chat/refund-policy',
  GDPR_COMPLIANCE: 'https://reachable.chat/gdpr-compliance'
};

export const appDomain = 'https://reachable.chat';
export const SupportLink = 'https://reachable.chat/support/';
export const FAQLink = 'https://reachable.chat/faq';
export const BulkContentUploadKnowlegdeLink =
  'https://reachable.chat/bulk-upload';
export const BulkWorkspacesUploadKnowlegdeLink =
  'https://reachable.chat/workspace';
export const LearnMoreLink = 'https://reachable.chat';
export const SocketEvents = {
  AUTHENTICATION_STATE_CHANGED_EVENT: 'auth_state_change_event'
};

export const WorkSpaceRole = {
  OWNER: 'owner',
  ADMIN: 'admin',
  EDITOR: 'editor',
  REVIEWER: 'reviewer',
  APPROVER: 'approver',
  VIEWER: 'viewer'
};

export const PlatformData = {
  APP_NAME: 'Reachable',
  MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE: 1e9,
  TIKTOK_MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE: 50e6,
  TWITTER_MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE: 512e6,
  INSTAGRAM_MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE: 100e6,
  LINKEDIN_MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE: 2e8
};

export const PanelNames = {
  CreatePost: 'CreatePost',
  PostAudience: 'PostAudience'
};

export const WorkSpaceContentApprovalWorkFlow = {
  SCHEDULE_POSTS_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE:
    'schedule_posts_after_approval',
  LOCK_CONTENT_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE:
    'lock_content_after_approval',
  ACTIVE_WORKSPACE_APPROVAL_OPTION: 'approval_option',
  ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL:
    'allow_owners_post_go_through_approval',
  ALLOW_ADMINS_POST_GO_THROUGH_APPROVAL:
    'allow_admins_post_go_through_approval',
  TURN_OFF_ALL_POSTS_AND_CAMPAIGNS: 'turn_off_all_posts_and_campaigns'
};

export const PERMISSION_EDIT_WORKSPACE_LOGO = 'edit_workspace_logo';
export const PERMISSION_EDIT_POST = 'edit_post_in_workspace';
export const PERMISSION_DELETE_WORKSPACE = 'delete_workspace';
export const PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW =
  'update_workspace_approval_workflow';
export const PERMISSION_APPROVE_POST = 'approve_post_in_workspace';
export const PERMISSION_PAY_FOR_WORKSPACE = 'pay_for_workspace';
export const PERMISSION_UPDATE_WORKSPACE_NAME = 'update_workspace_name';
export const PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE =
  'remove_member_from_workspace';
export const PERMISSION_INVITE_TEAM_MEMBER = 'invite_team_members';
export const PERMISSION_SEE_PAGE_SUBTITLE = 'see_page_subtitle';
export const PERMISSION_CREATE_MORE_WORKSPACE = 'create_more_workspace';
export const PERMISSION_VIEW_AND_MANAGE_PLATFORMS = 'view_and_manage_platforms';
export const PERMISSION_VIEW_WORKSPACE_BILLING_DETAILS =
  'view_workspace_billing_details';
export const PERMISSION_ADD_CUSTOM_EVENT_TO_WORKSPACE =
  'add_custom_event_to_workspace';
export const PERMISSION_VIEW_PENDING_INVITES = 'view_pending_invites';
export const PERMISSION_TURN_OFF_ALL_WORKSPACE_POSTS_AND_CAMPAIGNS =
  'turn_off_all_posts_and_campaigns';
export const PERMISSION_CREATE_NEW_WORKSPACES = 'create_new_workspaces';

export const RolePermissions = {
  OWNER: {
    [PERMISSION_EDIT_WORKSPACE_LOGO]: true,
    [PERMISSION_EDIT_POST]: true,
    [PERMISSION_DELETE_WORKSPACE]: true,
    [PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW]: true,
    [PERMISSION_APPROVE_POST]: true,
    [PERMISSION_PAY_FOR_WORKSPACE]: true,
    [PERMISSION_UPDATE_WORKSPACE_NAME]: true,
    [PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE]: true,
    [PERMISSION_INVITE_TEAM_MEMBER]: true,
    [PERMISSION_SEE_PAGE_SUBTITLE]: true,
    [PERMISSION_CREATE_MORE_WORKSPACE]: true,
    [PERMISSION_VIEW_AND_MANAGE_PLATFORMS]: true,
    [PERMISSION_VIEW_WORKSPACE_BILLING_DETAILS]: true,
    [PERMISSION_ADD_CUSTOM_EVENT_TO_WORKSPACE]: true,
    [PERMISSION_VIEW_PENDING_INVITES]: true,
    [PERMISSION_TURN_OFF_ALL_WORKSPACE_POSTS_AND_CAMPAIGNS]: true,
    [PERMISSION_CREATE_NEW_WORKSPACES]: true
  },
  ADMIN: {
    [PERMISSION_EDIT_WORKSPACE_LOGO]: true,
    [PERMISSION_EDIT_POST]: true,
    [PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW]: true,
    [PERMISSION_APPROVE_POST]: true,
    [PERMISSION_UPDATE_WORKSPACE_NAME]: true,
    [PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE]: true,
    [PERMISSION_INVITE_TEAM_MEMBER]: true,
    [PERMISSION_SEE_PAGE_SUBTITLE]: true,
    [PERMISSION_VIEW_AND_MANAGE_PLATFORMS]: true,
    [PERMISSION_ADD_CUSTOM_EVENT_TO_WORKSPACE]: true,
    [PERMISSION_VIEW_PENDING_INVITES]: true,
    [PERMISSION_TURN_OFF_ALL_WORKSPACE_POSTS_AND_CAMPAIGNS]: true
  },
  APPROVER: {
    [PERMISSION_EDIT_WORKSPACE_LOGO]: false,
    [PERMISSION_EDIT_POST]: true,
    [PERMISSION_DELETE_WORKSPACE]: false,
    [PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW]: false,
    [PERMISSION_APPROVE_POST]: true,
    [PERMISSION_PAY_FOR_WORKSPACE]: false,
    [PERMISSION_UPDATE_WORKSPACE_NAME]: false,
    [PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE]: false
  },
  EDITOR: {
    [PERMISSION_EDIT_WORKSPACE_LOGO]: false,
    [PERMISSION_EDIT_POST]: true,
    [PERMISSION_DELETE_WORKSPACE]: false,
    [PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW]: false,
    [PERMISSION_APPROVE_POST]: false,
    [PERMISSION_PAY_FOR_WORKSPACE]: false,
    [PERMISSION_UPDATE_WORKSPACE_NAME]: false,
    [PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE]: false
  },
  REVIEWER: {
    [PERMISSION_EDIT_WORKSPACE_LOGO]: false,
    [PERMISSION_EDIT_POST]: true,
    [PERMISSION_DELETE_WORKSPACE]: false,
    [PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW]: false,
    [PERMISSION_APPROVE_POST]: false,
    [PERMISSION_PAY_FOR_WORKSPACE]: false,
    [PERMISSION_UPDATE_WORKSPACE_NAME]: false,
    [PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE]: false
  }
};

export const AccountTabs = {
  PROFILE: 'profile',
  BILLING: 'billing',
  REDEEM_CODE: 'redeem_code',
  INVOICE_HISTORY: 'invoice_history'
};
