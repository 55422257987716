/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Card, Col, Nav, Offcanvas, Row, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SavedMediaRequests from './SavedMediaRequests';
import PitchedMediaRequests from './PitchedMediaRequests';
import PublishedMediaRequests from './PublishedMediaRequests';
import AddMediaRequest from 'components/app/media-requests/add-media-request/AddMediaRequest';
import { useBreakpoints } from 'hooks/useBreakpoints';
import APIService from 'http/api_service';
import useMediaRequestsStore from 'store/media_request_store';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import useUserStore from 'store/userStore';

const MyMediaRequests = () => {
  const [show, setShow] = useState(false);
  const { breakpoints } = useBreakpoints();
  const handleClose = () => setShow(false);
  const [fetching, setFetching] = useState(false);
  const [selectedMediaRequest, setSelectedMediaRequest] = useState(null);
  const [showMediaRequestDetails, setShowMediaRequestDetails] = useState(false);
  const authenticatedUser = useUserStore(state => state.authenticatedUser);

  const mediaRequests =
    useMediaRequestsStore(state => state.mediaRequests) || [];
  const setMediaRequests = useMediaRequestsStore(
    state => state.setMediaRequests
  );
  const handleShowMediaRequestDetails = mediaRequest => {
    setSelectedMediaRequest(mediaRequest);
    setShowMediaRequestDetails(true);
  };
  const fetchMediaRequests = () => {
    setFetching(true);
    APIService.fetchMediaRequests({}, (response, error) => {
      setFetching(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      const { data } = response;
      if (Array.isArray(data)) {
        data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setMediaRequests(data);
      } else {
        setMediaRequests([]);
      }
    });
  };

  useEffect(() => {
    fetchMediaRequests();
  }, []);

  return (
    <>
      <Row className="g-3">
        <Col lg={9}>
          <Card className="shadow-none border border-200">
            <Tab.Container defaultActiveKey="published">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    {/* {authenticatedUser.is_publisher === true && ( */}
                      <Nav.Item>
                        <Nav.Link
                          eventKey="submit-request"
                          className="d-flex align-items-center py-3 px-x1 mb-0"
                        >
                          <FontAwesomeIcon
                            icon="stream"
                            className="text-600 tab-icon"
                          />
                          <h6 className="text-600 mb-0 ms-1">Submit request</h6>
                        </Nav.Link>
                      </Nav.Item>
                    {/* )} */}
                    {/* {authenticatedUser.is_publisher === true && ( */}
                      <Nav.Item>
                        <Nav.Link
                          eventKey="submited-requests"
                          className="d-flex align-items-center py-3 px-x1 mb-0"
                        >
                          <FontAwesomeIcon
                            icon="stream"
                            className="text-600 tab-icon"
                          />
                          <h6 className="text-600 mb-0 ms-1">Submited requests</h6>
                        </Nav.Link>
                      </Nav.Item>
                    {/* )} */}
                    <Nav.Item>
                      <Nav.Link
                        eventKey="saved"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">Saved</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="pitched"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="ticket-alt"
                          className="text-600"
                        />
                        <h6 className="text-600 mb-0 ms-1">Pitched</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="published"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon icon="file-alt" className="text-600" />
                        <h6 className="text-600 mb-0 ms-1">Published</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey="submit-request">
                      <AddMediaRequest />
                  </Tab.Pane>
                  <Tab.Pane eventKey="saved">
                    {mediaRequests?.map((mediaRequest, index) => (
                      <Flex
                        className={'cursor-pointer'}
                        key={mediaRequest._id}
                        direction={'column'}
                        style={{ position: 'relative' }}
                        onClick={() =>
                          handleShowMediaRequestDetails(mediaRequest)
                        }
                      >
                        <SavedMediaRequests mediaRequest={mediaRequest} />
                      </Flex>
                    ))}
                  </Tab.Pane>
                  <Tab.Pane eventKey="pitched">
                    {mediaRequests?.map((mediaRequest, index) => (
                      <Flex
                        className={'cursor-pointer'}
                        key={mediaRequest._id}
                        direction={'column'}
                        style={{ position: 'relative' }}
                        onClick={() =>
                          handleShowMediaRequestDetails(mediaRequest)
                        }
                      >
                        <PitchedMediaRequests mediaRequest={mediaRequest} />
                      </Flex>
                    ))}
                  </Tab.Pane>
                  <Tab.Pane eventKey="published">
                    {mediaRequests?.map((mediaRequest, index) => (
                      <Flex
                        className={'cursor-pointer'}
                        key={mediaRequest._id}
                        direction={'column'}
                        style={{ position: 'relative' }}
                        onClick={() =>
                          handleShowMediaRequestDetails(mediaRequest)
                        }
                      >
                        <PublishedMediaRequests mediaRequest={mediaRequest} />
                      </Flex>
                    ))}
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MyMediaRequests;
