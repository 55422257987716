/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import { Carousel, Image, } from 'react-bootstrap';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import FalconLightBox from 'components/common/FalconLightBox';
import InAppVideoPlayer from './InAppVideoPlayer';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FeedGallery = props => {

  const { media = [] } = props;

  return (
    <div
      onKeyDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}>
      {media.length === 1 && (
        <div>
          {media[0].type === 'video' ? (
            <Flex className={'bg-black rounded-3'} alignItems={'center'} justifyContent={'center'}>
              <InAppVideoPlayer borderRadius={0} media={media[0]} />
            </Flex>
          ) : (
            <FalconLightBox
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              image={media[0].url}>
              <Image
                fluid
                src={media[0].url}
                className='rounded-3'
              />
            </FalconLightBox>
          )}
        </div>
      )}
      {media.length > 1 && (
        <FalconLightBoxGallery images={media.filter(item => item.type === 'image').map(item => item.url)}>
          {setImgIndex => (
            <Carousel
              interval={null}
              className='theme-slider'
              nextIcon={
                <FontAwesomeIcon icon="angle-right" />
              }
              prevIcon={
                <FontAwesomeIcon icon="angle-left" />
              }
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            >
              {media.map((file, index) => {
                return file.type === 'video' ? (
                  <Carousel.Item>
                    <Flex className={'bg-black rounded-3'} alignItems={'center'} justifyContent={'center'}>
                      <InAppVideoPlayer
                        borderRadius={0}
                        media={file}
                        controls={true}
                        className="react-player"
                      />
                    </Flex>
                  </Carousel.Item>
                ) : (
                  <Carousel.Item>
                    <Image
                      src={file.url}
                      fluid
                      rounded
                      style={{
                        cursor: 'pointer',
                        height: 480,
                        width: '100%',
                        objectFit: 'cover'
                      }}
                      onClick={() => setImgIndex(index)}
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          )}
        </FalconLightBoxGallery>
      )}
    </div>
  );
};

export default FeedGallery;
