/* eslint-disable react/prop-types */
import React from 'react';
import FeedCard from 'components/app/social/feed/FeedCard';
import Flex from 'components/common/Flex';
import APIService from 'http/api_service';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import usePostsStore from 'store/posts_store';

const UserCreatedPosts = ({ user }) => {
  const { t } = useTranslation();
  const [fetchingPosts, setFetchingPosts] = useState(false);
  const posts = usePostsStore(state => state.posts);
  const setPosts = usePostsStore(state => state.setPosts);
  const [hasMore, setHasMore] = useState(false);
  const navigate = useNavigate();

  const fetchPosts = ({ skip }) => {
    if (fetchingPosts) {
      return;
    }
    setFetchingPosts(true);
    APIService.fetchPostsCreatedByUser(
      { from: user._id, skip },
      (response, error) => {
        setFetchingPosts(false);
        if (error) {
          toast.error(error, { theme: 'colored' });
          return;
        }
        let { data } = response;
        let postsCopy = [...posts];
        if (skip === 0) {
          postsCopy = [];
        }
        data.forEach(incomingPost => {
          const existingIndex = postsCopy.findIndex(
            post => post._id === incomingPost._id
          );
          if (existingIndex !== -1) {
            postsCopy[existingIndex] = incomingPost;
          } else {
            postsCopy.push(incomingPost);
          }
        });
        postsCopy.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setPosts([...postsCopy]);
        setHasMore(data.length > 0);
      }
    );
  };

  useEffect(() => {
    fetchPosts({ skip: 0 });
  }, [user]);

  const handleCardClick = post => {
    navigate(`./posts/${post._id}`, {
      state: { from: window.location.pathname }
    });
  };

  return (
    <Flex direction={'column'} className={'pt-1'}>
      {fetchingPosts && posts.length < 1 && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          <Spinner animation="border" />
        </Flex>
      )}
      {!fetchingPosts && posts.length.length < 1 && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          {t('user_has_not_posted')}
        </Flex>
      )}
      <InfiniteScroll
        dataLength={posts.length}
        hasMore={hasMore}
        next={() => fetchPosts({ skip: posts.length })}
        loader={
          <Flex
            alignItems={'center'}
            className={'p-3'}
            justifyContent={'center'}
          >
            <Spinner animation="border" />
          </Flex>
        }
      >
        {posts.map((post, index) => (
          <Flex
            className={'cursor-pointer'}
            key={post._id}
            direction={'column'}
            style={{ position: 'relative' }}
          >
            <FeedCard
              feed={post}
              className={'p-0 pt-3 card shadow-none elevation-none'}
              onClick={() => handleCardClick(post)}
            />
            {index !== posts.length - 1 && (
              <hr className="text-200" style={{ margin: 0 }} />
            )}
          </Flex>
        ))}
      </InfiniteScroll>
    </Flex>
  );
};
export default UserCreatedPosts;
