/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import FeedEvent from './FeedEvent';
import FeedUrl from './FeedUrl';
import FeedGallery from 'components/app/social/feed/FeedGallery';
import ContinousExpansionTextView from './ContinuousExpansionTextView';

const FeedCardContent = ({ text, media = [], feedEvent, url }) => {
  return (
    <div>
      {text && <ContinousExpansionTextView text={text} inputStyle={{ fontSize: 14 }} />}
      {media.length > 0 && <FeedGallery media={media} />}
      {feedEvent && <FeedEvent {...feedEvent} />}
      {url && <FeedUrl {...url} />}
    </div>
  );
};
export default FeedCardContent;
