import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, CloseButton } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

const IconAlert = ({
  variant,
  dismissible,
  children,
  className,
  onClose,
  ...rest
}) => {
  const icon = {
    success: faCheckCircle,
    light: faInfoCircle,
    dark: faInfoCircle,
    info: faInfoCircle,
    warning: faExclamationCircle,
    danger: faTimesCircle
  };
  return (
    <Alert
      variant={variant}
      className={classNames(
        className,
        'p-0 px-3 py-2 d-flex align-items-center rounded-3 m-0'
      )}
      {...rest}
    >
      <div
        className={`bg-${variant} d-flex flex-center me-3 rounded-circle shadow-sm border-0`}
        style={{
          height: '1.8rem',
          width: '1.8rem'
        }}
      >
        <FontAwesomeIcon
          style={{
            width: 20,
            height: 20
          }}
          icon={icon[variant]}
          className="fs-6"
        />
      </div>
      <div className="flex-1">{children}</div>
      {dismissible && <CloseButton onClick={onClose} />}
    </Alert>
  );
};

IconAlert.propTypes = {
  variant: PropTypes.oneOf(['success', 'danger', 'warning', 'info']).isRequired,
  dismissible: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func
};

export default IconAlert;
