/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import ReactPlayer from 'react-player';
import React, { useState, useEffect } from 'react';
import { Stream } from "@cloudflare/stream-react";
import StringUtils from 'utils/string';
import { Placeholder, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';

export default function InAppVideoPlayer({
  media,
  borderRadius = 0,
  style = {}
}) {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [streamKey, setStreamKey] = useState(StringUtils.generateRandomString(360));

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setLoading(false);
        setLoading(true);
        setStreamKey(StringUtils.generateRandomString(360));
      }, 10000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [loading]);

  if (media.file) {
    return (
      <ReactPlayer
        url={media.preview}
        controls
        muted
        style={{ ...style }}
      />
    );
  }

  return (
    <>
      {media.url.includes('cloudflarestream') ? (
        <div
          style={{
            position: 'relative',
            clipPath: `inset(0% 0% 0% 0% round ${borderRadius}px)`,
            transition: 'all .5s',
            width: 270,
            height: loading ? 480 : 'auto'
          }}>
          <Stream
            key={streamKey}
            width='100%'
            height='100%'
            poster={media.thumbnail}
            onLoadStart={() => setLoading(true)}
            onCanPlay={() => setLoading(false)}
            controls src={StringUtils.substringAfterLast(StringUtils.substringBeforeLast(media.url, "/"), "/")}
          />
          {
            loading &&
            <Flex
              style={{
                height: 480,
                width: '100%',
                position: 'absolute',
                zIndex: 1,
                top: 0,
                left: 0,
                right: 0,
              }}>
              <div
                style={{
                  position: 'relative',
                  height: '100%',
                  width: '100%'
                }}>
                <Placeholder
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%'
                  }}
                />
                <Flex
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={'gap-2'}
                  style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0
                  }}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span>{t('preparing_video')}</span>
                </Flex>
              </div>
            </Flex>
          }
        </div>
      ) : (
        <ReactPlayer
          url={media.url}
          controls
          muted
          style={{ ...style }}
        />
      )}
    </>
  );
}
