/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import FeedActionButtons from './FeedActionButtons';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import Comments from './Comments';
import useUserStore from 'store/userStore';
import { useTranslation } from 'react-i18next';
import TextareaAutoSize from 'react-textarea-autosize';
import { useAppContext } from 'providers/AppProvider';
import SubmitButton from 'components/common/SubmitButton';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { SHA256 } from 'utils/crypto';

const FeedCardFooter = ({
  _id,
  likesCount,
  sharesCount,
  liked,
  likes,
  ...others
}) => {

  const { t } = useTranslation();
  const { config: { isDark } } = useAppContext();
  const [comment, setComment] = useState({});
  const [commentsCount, setCommentsCount] = useState(others.commentsCount ?? 0);
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const [commenting, setCommenting] = useState(false);
  const [comments, setComments] = useState(others.comments);
  const [commented, setCommented] = useState(others.commented);
  const [openCommentBox, setOpenCommentBox] = useState(false);

  const submitComment = e => {
    e.preventDefault();
    let commentsCopy = [...(comments ?? [])]
    setCommenting(true);
    APIService.upsertComment({ content: comment, inReplyToPost: _id }, (response, error) => {
      setCommenting(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      setComment({});
      let { data } = response;
      let dataIndex = commentsCopy.findIndex(existingComment => existingComment._id === data._id);
      if (dataIndex !== -1) {
        commentsCopy[dataIndex] = { ...data };
      } else {
        commentsCopy.unshift(data);
        setCommentsCount(commentsCount + 1);
      }
      setComments(commentsCopy);
      setCommented(true);
    });
  };

  return (
    <div
      onKeyDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}>
      <FeedActionButtons
        liked={liked}
        commented={commented}
        _id={_id}
        openCommentBox={openCommentBox}
        handleCommentBoxToggle={(open) => setOpenCommentBox(open)}
        topics={others.topics}
        author={others.author}
        {...{
          likesCount,
          commentsCount,
          sharesCount,
          _id,
          commented,
          liked,
          likes,
          topics: others.topics
        }}
      />
      {
        openCommentBox &&
        <Form onSubmit={submitComment}>
          <Flex alignItems="center" className="border-top border-200 pt-3 mt-3">
            <Avatar src={authenticatedUser.avatar ?? ''} size="xl" />
            <TextareaAutoSize
              id={`${_id}-comment-box`}
              minRows={1}
              maxRows={3}
              as="textarea"
              value={comment?.text ?? ''}
              placeholder={t('add_comment_as', { name: authenticatedUser?.first_name ?? authenticatedUser?.last_name })}
              className={`ms-2 p-2 border outline-none ms-2 fs-10 resize-none ${!comment?.text && 'rounded-pill'
                } ${comment?.text && 'rounded-3'}`}
              style={{
                flex: 1,
                whiteSpace: 'pre-wrap',
                display: 'inherit',
                background: isDark ? '#0b1727' : '#fff',
                color: 'inherit',
                fontSize: 'inherit',
                transition: 'all .2s'
              }}
              onChange={e => setComment({ ...comment, text: e.target.value })}
            />
            <SubmitButton
              label={t('comment')}
              disabled={commenting || !comment?.text}
              processing={commenting}
              className="mx-2 rounded-pill"
              variant="primary"
              size="sm"
              transform="shrink-3"
            />
          </Flex>
        </Form>
      }
      {
        openCommentBox && comments && <Comments
          key={`${SHA256(`${_id}${JSON.stringify(comments, null, 2)}`)}`}
          postId={_id}
          comments={comments.map(comment => {
            return { ...comment, postId: _id }
          })}
          commentsCount={commentsCount}
        />
      }
    </div>
  );
};

export default FeedCardFooter;
