/* eslint-disable prettier/prettier */
import { create } from 'zustand';

const useSearchStore = create(set => ({
    searchText: null,
    searchResults: {},
    searchFilters: {},
    setSearchText: text => set(state => ({
        ...state,
        searchText: text
    })),
    setSearchFilters: newSearchFilters => (set(state => ({
        ...state,
        searchFilters: newSearchFilters
    }))),
    setSearchResults: newSearchResults => set(state => ({
        ...state,
        searchResults: {
            ...state.searchResults,
            ...newSearchResults
        }
    }))
}));

export default useSearchStore;
