/* eslint-disable prettier/prettier */
/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import EndPoints from './endpoints';
import HttpSuccessDataHandler from '../utils/http_success_data_handler';
import HttpErrorHandler from '../utils/http_error_handler';
import { AuthMethod, Strings } from '../constants';
import numberToWordConverter from 'number-to-words';
import StorageEngine from 'utils/storage_engine';
import StringUtils from 'utils/string';
import { Upload } from 'tus-js-client';

export default class APIService {

  static async uploadFile(file, { onUploadProgress } = {}) {
    try {
      let fileType = StringUtils.substringBeforeLast(file.type, '/');
      let { data } = await axios.get(
        `${EndPoints.UPLOAD_FILE}?fileType=${fileType}`,
        {
          headers: await this.getRequestHeaders(
            fileType === 'video' ? { 'Upload-Length': file.size } : {}
          )
        }
      );
      let uploadUrl = data.data.uploadURL;
      const fileId = StringUtils.substringBeforeLast(
        StringUtils.substringAfterLast(uploadUrl, '/'),
        '?'
      );
      if (fileType === 'video') {
        return new Promise(async (resolve, reject) => {
          let upload = new Upload(file, {
            uploadUrl,
            metadata: {
              filename: file.name,
              filetype: file.type
            },
            onError: function (error) {
              reject(error);
            },
            onProgress: function (bytesUploaded, bytesTotal) {
              let percentage = Math.round((bytesUploaded / bytesTotal) * 100);
              if (onUploadProgress) {
                onUploadProgress(percentage);
              }
            },
            onSuccess: () => {
              APIService.generateUploadedFileDataFromID(
                fileType,
                fileId,
                (res, err) => {
                  if (err) {
                    reject(err);
                  }
                  let { data } = res;
                  resolve(data);
                }
              );
            }
          });
          upload.start();
        });
      }
      let formData = new FormData();
      formData.append('file', file);
      let response = await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          if (onUploadProgress) {
            onUploadProgress(progress);
          }
        }
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw `There was an error uploading your ${StringUtils.substringBeforeLast(
        file.type,
        '/'
      )}. Please try again`;
    }
  }

  static async generateUploadedFileDataFromID(fileType, id, cb) {
    axios
      .get(`${EndPoints.UPLOADED_FILE_URL}?fileType=${fileType}&id=${id}`, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async upsertPost(data, cb) {
    axios
      .post(EndPoints.UPSERT_POST, data, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deletePost(postId, cb) {
    axios
      .delete(`${EndPoints.DELETE_POST}`.replace(':id', postId), {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deleteComment(postId, commentId, cb) {
    axios.delete(`${EndPoints.DELETE_COMMENT}`.replace(':id', postId).replace(':commentId', commentId), {
      headers: await this.getRequestHeaders()
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    });
  }

  static async fetchPostComments({ postId, skip = 0 }, cb) {
    axios.get(`${EndPoints.POST_COMMENTS}`.replace(':postId', postId).concat(`?skip=${skip}`), {
      headers: await this.getRequestHeaders()
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    });
  }

  static async fetchCommentReplies({ postId, commentId, skip = 0 }, cb) {
    axios.get(`${EndPoints.COMMENT_REPLIES}`.replace(':id', postId).replace(':commentId', commentId).concat(`?skip=${skip}`), {
      headers: await this.getRequestHeaders()
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    });
  }

  static async likeOrUnLikePost(postId, cb) {
    axios
      .post(
        `${EndPoints.LIKE_OR_UN_LIKE_POST}`.replace(':postId', postId),
        {},
        {
          headers: await this.getRequestHeaders()
        }
      )
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async likeOrUnLikeComment(postId, commentId, cb) {
    axios
      .post(
        `${EndPoints.LIKE_OR_UN_LIKE_COMMENT}`.replace(':postId', postId).replace(":commentId", commentId),
        {},
        {
          headers: await this.getRequestHeaders()
        }
      )
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async upsertComment(data, cb) {
    axios.post(`${EndPoints.UPSERT_COMMENT}`, data, {
      headers: await this.getRequestHeaders()
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    }).catch(e => {
      console.log(e);
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    });
  }

  static async fetchPostsOfInterest(cb) {
    axios
      .get(EndPoints.POSTS_OF_INTEREST, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchPost(postId, cb) {
    axios
      .get(EndPoints.POST.replace(':postId', postId), {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchPostsCreatedByUser({ from, skip = 0 }, cb) {
    axios.get(`${EndPoints.POSTS_OF_INTEREST}?from=${from}&skip=${skip}`, {
      headers: await this.getRequestHeaders()
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    });
  }

  static async upsertMediaRequest(data, cb) {
    axios
      .post(EndPoints.UPSERT_MEDIA_REQUEST, data, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchMediaRequests(params, cb) {
    const url = new URL(EndPoints.MEDIA_REQUESTS);
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params ?? {})) {
      searchParams.append(key, value);
    }
    url.search = searchParams.toString();
    axios
      .get(url.toString(), {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async searchContent(endPoint, params, cb) {
    const url = new URL(endPoint);
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params ?? {})) {
      searchParams.append(key, value);
    }
    url.search = searchParams.toString();
    axios
      .get(url.toString(), {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchCountries(cb) {
    axios
      .get(EndPoints.SUPPORTED_COUNTRIES, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async authenticateWithMagicLink(email, cb) {
    let requestBody = { email, auth_type: AuthMethod.PASSWORD_LESS };
    axios
      .post(EndPoints.SIGN_UP, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async validatePasswordLessConfirmationCode(code, owner_email, cb) {
    let requestBody = { confirmation_code: code, owner_email };
    axios
      .post(EndPoints.CONFIRM_PASSWORD_LESS_CONFIRMATION_CODE, requestBody)
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async verifyNewEmailAddress(token, cb) {
    let requestBody = { token };
    axios
      .post(EndPoints.VERIFY_NEW_EMAIL_ADDRESS, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async logInManually(email, password, cb) {
    let requestBody = {
      email,
      auth_method: AuthMethod.PASSWORD,
      password,
      fromWeb: true
    };
    axios
      .post(EndPoints.SIGN_IN, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async logOut() {
    axios
      .get(EndPoints.LOG_OUT, {
        headers: await this.getRequestHeaders()
      })
      .catch(e => {
        console.log(e);
      });
  }

  static async createAccountManually(email, password, displayName, cb) {
    let requestBody = {
      email,
      password,
      displayName,
      auth_method: AuthMethod.PASSWORD
    };
    axios
      .post(EndPoints.SIGN_UP, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updatePersonalInfo(data, cb) {
    let requestBody = { data };
    axios
      .put(EndPoints.UPDATE_PERSONAL_INFO, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async checkIfEmailIsVerified(cb) {
    axios
      .get(EndPoints.CHECK_IF_EMAIL_IS_VERIFIED, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async requestCodeForEmailVerification(
    requestBody,
    processing_signup = 0,
    cb
  ) {
    axios
      .put(
        `${EndPoints.REQUEST_CODE_FOR_EMAIL_VERIFICATION}?processing_signup=${processing_signup}`,
        requestBody,
        {
          headers: await this.getRequestHeaders()
        }
      )
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async confirmCodeForEmailVerification(
    requestBody,
    processing_signup = 0,
    cb
  ) {
    axios
      .put(
        `${EndPoints.CONFIRM_CODE_FOR_EMAIL_VERIFICATION}?processing_signup=${processing_signup}`,
        requestBody,
        {
          headers: await this.getRequestHeaders()
        }
      )
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateSecurityInfo(oldPassword, newPassword, cb) {
    let requestBody = { newPassword, oldPassword };
    axios
      .put(EndPoints.UPDATE_ACCOUNT_PASSWORD, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async createGoogleAccount(
    email,
    displayName,
    photoURL,
    token,
    uid,
    cb
  ) {
    let requestBody = {
      email,
      displayName,
      auth_method: AuthMethod.GOOGLE_AUTH,
      photoURL,
      accessToken: token,
      uid
    };
    axios
      .post(EndPoints.SIGN_UP, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async createFacebookAccount(
    email,
    displayName,
    photoURL,
    token,
    uid,
    cb
  ) {
    let requestBody = {
      email,
      displayName,
      auth_method: AuthMethod.FACEBOOK_AUTH,
      photoURL,
      accessToken: token,
      uid
    };
    axios
      .post(EndPoints.SIGN_UP, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async initiatePasswordReset(email, cb) {
    let requestBody = { email };
    axios
      .post(EndPoints.INITIATE_PASSWORD_RESET, requestBody)
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async finalizePasswordReset(token, newPassword, cb) {
    let requestBody = { token, newPassword };
    axios
      .post(EndPoints.FINALIZE_PASSWORD_RESET, requestBody)
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchTimedPaymentData(apiKey, eventId) {
    try {
      const response = await axios.get(
        `${EndPoints.TIMED_PAYMENT_EVENT}?dataId=${eventId}`,
        {
          headers: {
            'X-API-Key': apiKey
          }
        }
      );
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      return HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static async updatePostEmailNotificationSettings(
    workSpaceId,
    notificationChoices,
    cb
  ) {
    let requestBody = { notificationChoices };
    axios
      .post(
        EndPoints.POST_EMAIL_NOTIFICATIONS.replace(':workSpaceId', workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders()
        }
      )
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateUserAvatar(requestBody, cb) {
    axios
      .put(EndPoints.USER_AVATAR, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateAccountLogo(requestBody, cb) {
    axios
      .put(EndPoints.ACCOUNT_LOGO, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateAccountDetails(requestBody, cb) {
    axios
      .put(EndPoints.UPDATE_PERSONAL_INFO, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deleteAccount(cb) {
    axios
      .delete(EndPoints.DELETE_ACCOUNT, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchUserNotifications(cb) {
    axios
      .get(EndPoints.USER_NOTIFICATIONS, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchUserLatestData(apiKey) {
    try {
      const response = await axios.get(EndPoints.ME, {
        headers: { 'X-API-Key': apiKey }
      });
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      throw HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static async fetchLatestMe(cb) {
    axios
      .get(EndPoints.ME, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchMyFingerPrint() {
    try {
      const response = await axios.get(EndPoints.MY_FINGER_PRINT, {
        headers: await this.getRequestHeaders()
      });
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      throw HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static async fetchLatestMeSync() {
    try {
      let response = await axios.get(EndPoints.ME, {
        headers: await this.getRequestHeaders()
      });
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      return HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static getOrdinalSuffix(index) {
    if (index === 0 || index === 1) {
      return ' ';
    }
    return numberToWordConverter.toOrdinal(index);
  }

  static async cancelSubscriptionSync(apiKey) {
    try {
      const response = await axios.delete(EndPoints.CANCEL_SUBSCRIPTION, {
        headers: {
          'X-API-Key': apiKey
        }
      });
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      return HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static async cancelSubscription(cb) {
    axios
      .delete(EndPoints.CANCEL_SUBSCRIPTION, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async shortenLink(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/shorten_link`, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //save link
  static async saveLink(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/save_link`, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  //create folder
  static async createLinkFolder(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/create_link_folder`, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //fetch folder
  static async fetchFolders(cb) {
    axios
      .get(`${EndPoints.LINK_SHORTENER}/fetch_folders`, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //get auto shortned linnk status
  static async getAutoShortenedLinkStatus(cb) {
    axios
      .get(`${EndPoints.LINK_SHORTENER}/get_auto_shortned_status`, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //save qrcode to database
  static async saveQrcode(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/save_qrcode`, requestBody, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //fetch qrcode to database
  static async getQrcodes(cb) {
    axios
      .get(`${EndPoints.LINK_SHORTENER}/get_qrcode`, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async persistUserAPIKey(key, value) {
    StorageEngine.setItem(Strings.LOGGED_IN_USER_API_KEY, value);
  }

  static async getRequestHeaders(moreHeaders = {}) {
    let headers = {};
    let apiKey = await StorageEngine.getItem(Strings.LOGGED_IN_USER_API_KEY);
    if (apiKey) {
      headers['X-API-Key'] = apiKey;
    }
    let allHeaders = { ...headers, ...moreHeaders };
    return allHeaders;
  }

  static async fetchUserInvoiceHistory(cb) {
    axios
      .get(EndPoints.TRANSACTION_HISTORY, {
        headers: await this.getRequestHeaders()
      })
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async fetchConversationsOfParticipant(participantId, cb) {
    axios
      .get(
        EndPoints.FETCH_CONVERSATIONS_OF_PARTICIPANT.replace(
          ':participantId',
          participantId
        ),
        {
          headers: await this.getRequestHeaders()
        }
      )
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async fetchAccountByUsername(username, cb) {
    axios
      .get(
        EndPoints.FETCH_ACCOUNT_BY_USERNAME.replace(':username', username),
        {
          headers: await this.getRequestHeaders()
        }
      )
      .then(response => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch(e => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
}
