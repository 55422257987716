/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/LoginForm';
import paths from 'routes/paths';
import { useTranslation } from 'react-i18next';

const Login = ({ loosedSocials = true }) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>
          {t('log')} {t('in')}
        </h5>
        <p className="fs-10 text-600 mb-0">
          {t('or')}{' '}
          <Link to={paths.simpleRegister}>{t('create_an_account')}</Link>
        </p>
      </Flex>
      <LoginForm loosedSocials={loosedSocials} />
    </>
  );
};

export default Login;
