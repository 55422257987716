/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
// import paths from 'routes/paths';

const PremiumAdCard = ({ className }) => {
  return (
    <>
      <Card
        className={`overflow-hidden border border-200 ${className} elevation-none shadow-none`}
      >
        <Card.Body className="position-relative p-3">
          <h5 className="text-warning fs-8">Are You Reaching Reporters Quickly?</h5>
          <p className="fs-10 mb-0">
            Free users need to wait 2 hours before submitting a pitch. Don't
            miss out on coverage opportunities. Upgrade to a paid Publishable
            plan to reach reporters faster.
          </p>
          <Button
            as={Link}
            // to={paths.plansAndBillingPage}
            variant="primary"
            className="fs-10 w-100 mt-3 p-2"
            to="/pricing/pricing-alt"
          >
            Upgrade to Publishable Premium
            <FontAwesomeIcon icon="chevron-right" className="ms-1 fs-11" />
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

PremiumAdCard.propTypes = {
  name: PropTypes.string.isRequired,
  profileLink: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default PremiumAdCard;
