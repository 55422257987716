/* eslint-disable prettier/prettier */
import React, { Suspense, useEffect } from 'react';
import is from 'is_js';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Chart as ChartJS, registerables } from 'chart.js';
import { CloseButton } from 'components/common/Toast';
import { useAppContext } from 'providers/AppProvider';
import { Spinner } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

ChartJS.register(...registerables);

const App = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useAppContext();

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  const Loader = () => (
    <div>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );

  return (
    <Suspense fallback={<Loader />}>
      <Outlet />
      <ToastContainer
        toastClassName={'rounded-4'}
        autoClose={2000}
        closeButton={CloseButton}
        icon={false}
        position="top-center"
        hideProgressBar
        theme='colored'
      />
    </Suspense>
  );
};

export default App;
