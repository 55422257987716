/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import { Button, Form, Spinner } from "react-bootstrap";
import useUserStore from "store/userStore";
import { useTranslation } from 'react-i18next';
import Flex from "components/common/Flex";
import Avatar from "components/common/Avatar";
import { IoSend } from "react-icons/io5";
import React, { forwardRef, useState } from "react";
import TextareaAutoSize from 'react-textarea-autosize';
import { useAppContext } from "providers/AppProvider";
import APIService from "http/api_service";
import useReplyBoxStore from "store/reply_box_store";

const ReplyComposeBox = forwardRef(({ autoFocus = false, boxId, commentId, onNewReplyUpserted, mentionables, ...others }, ref) => {

    const { t } = useTranslation();
    const authenticatedUser = useUserStore(state => state.authenticatedUser);
    const [reply, setReply] = useState({ text: others?.text ? others.text : mentionables ? Object.keys(mentionables ?? []).map(name => `@${name}`).join('') : '' });
    const { config: { isDark } } = useAppContext();
    const [processing, setProcessing] = useState(false);
    const activeReplyBoxes = useReplyBoxStore(state => state.activeReplyBoxes);
    const setActiveReplyBoxes = useReplyBoxStore(state => state.setActiveReplyBoxes);

    const checkAndHideBox = () => {
        if (activeReplyBoxes[boxId]) {
            delete activeReplyBoxes[boxId];
        }
        setActiveReplyBoxes({ ...activeReplyBoxes });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (others._id) {
            Object.assign(reply, { _id: others._id });
        }
        if (mentionables) {
            Object.assign(reply, { mentions: mentionables });
        }
        let requestBody = {
            content: reply,
        };
        if (others.inReplyToPost) {
            requestBody['inReplyToPost'] = others.inReplyToPost
        } else {
            requestBody['inReplyToComment'] = commentId;
        }
        setProcessing(true);
        APIService.upsertComment(requestBody, (response, error) => {
            setProcessing(false);
            if (error) {
                console.log(error);
                return;
            }
            let { data } = response;
            setReply({});
            checkAndHideBox();
            if (onNewReplyUpserted) {
                onNewReplyUpserted(data);
            }
        });
    }

    return (
        <Form {...others} onSubmit={handleSubmit}>
            <Flex alignItems={'flex-start'} justifyContent={'flex-start'} direction={'column'}>
                <Flex alignItems={'center'} className={'gap-1 pb-1 pt-3'}>
                    <Avatar src={authenticatedUser?.avatar} size="s" />
                    <TextareaAutoSize
                        autoFocus={autoFocus}
                        ref={ref}
                        id="reply-box"
                        minRows={1}
                        maxRows={3}
                        as="textarea"
                        disabled={processing}
                        value={reply?.text ?? ''}
                        placeholder={others?.hint ?? t('write_a_reply')}
                        className={`ms-2 p-2 border outline-none ms-2 fs-10 resize-none ${!reply?.text && 'rounded-pill'
                            } ${reply?.text && 'rounded-3'}`}
                        style={{
                            flex: 1,
                            whiteSpace: 'pre-wrap',
                            display: 'inherit',
                            background: isDark ? '#0b1727' : '#fff',
                            color: 'inherit',
                            fontSize: 'inherit',
                            transition: 'all .2s'
                        }}
                        onChange={e => setReply({ ...reply, text: e.target.value })}
                    />
                    {
                        processing ? <Spinner animation="border" size="sm" variant="primary" /> :
                            <IoSend onClick={(e) => handleSubmit(e)} className={`text-${reply?.text ? 'primary' : 'default'}`} style={{ width: 25, height: 25 }} />
                    }
                </Flex>
                {
                    others._id &&
                    <Button className="ms-4" onClick={others.handleOnCancel} style={{ alignSelf: 'flex-start' }} size="sm" variant="link">{t('cancel').toLowerCase()}</Button>
                }
            </Flex>
        </Form>
    );
});
export default ReplyComposeBox;