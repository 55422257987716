/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Card, Col, Placeholder, Row } from 'react-bootstrap';
import ProfileDetailsFooter from './ProfileDetailsFooter';
import Banner from './Banner';
import { Helmet } from 'react-helmet';
import APIService from 'http/api_service';
import { useTranslation } from 'react-i18next';
import useUserStore from 'store/userStore';
import { getFullName } from 'utils/user_utils';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import PlaceHolderSrc from 'assets/img/generic/placeholder_src.png';

const UserProfileDetails = () => {

  let { username } = useParams();
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const [user, setUser] = useState();
  const { t } = useTranslation();
  const [isProfileEditModalOpen, setIsProfileEditModalOpen] = useState(false);
  const openProfileEditModal = () => setIsProfileEditModalOpen(true);
  const closeProfileEditModal = () => setIsProfileEditModalOpen(false);

  const refinedUsername = (username) => {
    return username === 'profile' ? authenticatedUser?.username : username;
  }

  useEffect(() => {
    const fetchAccountByUsername = (username) => {
      APIService.fetchAccountByUsername(username, (response, error) => {
        if (error) {
          return;
        }
        let { data } = response;
        setUser(data);
      });
    };
    if (username) {
      fetchAccountByUsername(refinedUsername(username));
    }
  }, [username]);

  return (
    <>
      <Helmet>
        <title>
          {user ? (`${getFullName(user)} - ${t('app_name')}`) : `${t('loading')}`}
        </title>
        <meta charSet="utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@reachableonline " />
        <meta name="twitter:creator" content="@reachableonline " />
        <meta property="og:description" content={t('app_name')} />
        <meta
          content="https://twitter.com/reachableonline"
          property="og:see_also"
        />
        <meta name="twitter:title" content={t('app_name')} />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image:width" content="1001 " />
        <meta name="twitter:image:height" content="459 " />
        <link href="https://reachable.chat/" rel="canonical " />
        <link href="https://reachable.chat/" rel="home " />
      </Helmet>
      <Row>
        <Col lg={8} className="mb-4 mb-lg-0">
          <Card className="shadow-none border border-200">
            <Card.Body>
              {
                !user &&
                <Flex
                  style={{
                    height: '100vh',
                    width: '100%',
                    padding: 15
                  }}
                  direction={'column'}
                  alignItems={'stretch'}
                  justifyContent={'center'}>
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={12}
                      as={Avatar}
                      src={PlaceHolderSrc}
                      className={'avatar-4xl rounded-circle m-3'}
                    />
                    <Placeholder xs={7} />
                    <Placeholder xs={5} />
                    <Placeholder xs={4} />{' '}
                    <Placeholder xs={7} />
                    <Placeholder xs={12} />
                    <Placeholder xs={7} />
                    <Placeholder xs={5} />
                  </Placeholder>
                </Flex>
              }
              {
                user &&
                <Banner
                  user={user}
                  isProfileEditModalOpen={isProfileEditModalOpen}
                  setIsProfileEditModalOpen={setIsProfileEditModalOpen}
                  closeProfileEditModal={closeProfileEditModal}
                />
              }
              {
                user &&
                <ProfileDetailsFooter
                  authenticatedUser={authenticatedUser}
                  user={user}
                  openProfileEditModal={openProfileEditModal}
                />
              }
            </Card.Body>
          </Card>
        </Col>
        <Outlet />
      </Row>
    </>
  );
};

export default UserProfileDetails;
