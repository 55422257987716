/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ChatContentBodyIntro from './ChatContentBodyIntro';
import Message from './Message';
import SimpleBarReact from 'simplebar-react';
import ThreadInfo from './ThreadInfo';
// import { useChatContext } from 'providers/ChatProvider';

const ChatContentBody = ({ thread }) => {
  // let lastDate = null;
  const messagesEndRef = useRef();

  // const { getUser, messages, scrollToBottom, setScrollToBottom } =
  //   useChatContext();
  // const user = getUser(thread);
  // const { content } = messages.find(({ id }) => id === thread.messagesId);

  // useEffect(() => {
  //   if (scrollToBottom) {
  //     setTimeout(() => {
  //       messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  //     }, 500);
  //     setScrollToBottom(false);
  //   }
  // }, [scrollToBottom]);

  return (
    // <div className="chat-content-body" style={{ display: 'inherit' }}>
    <SimpleBarReact style={{ height: '100%' }}>
      <div className="chat-content-scroll-area">
        <ChatContentBodyIntro
          user={{
            avatarSrc: `https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg`,
            name: 'Bobby Bankz'
          }}
          // user={user}
        />
        {thread?.map(({ body, created_at, senderId, status }, index) => (
          <div key={index}>
            {/* {lastDate !== time.date && (
                <div className="text-center fs-11 text-500">{`${time.date}, ${time.hour}`}</div>
              )}
              {(() => {
                lastDate = time.date;
              })()} */}

            <Message
              message={{ text: body }}
              senderUserId={senderId}
              time={new Date(created_at)}
              status={'seen'}
              user={{
                avatarSrc: `https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg`,
                name: 'Bobby Bankz'
              }}
              // status={status}
              isGroup={thread?.type === 'group'}
            />
          </div>
        ))}
      </div>
    </SimpleBarReact>
    // </div>
  );
};

ChatContentBody.propTypes = {
  thread: PropTypes.object.isRequired
};

export default ChatContentBody;
