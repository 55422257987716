/* eslint-disable prettier/prettier */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import React, { useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import usePublishablePostStore from 'store/publishable_post_store';
import useUserStore from 'store/userStore';
import { topics } from './data/topics';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SubtleBadge from 'components/common/SubtleBadge';
import { abbreviateNumber } from 'utils/user_utils';

const TopicsComponent = () => {

  const { t } = useTranslation();
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const post = usePublishablePostStore(state => state.post);
  const setPost = usePublishablePostStore(state => state.setPost);
  const userInterests = authenticatedUser.interests.map(interest => {
    return { name: interest };
  });
  const otherInterests = topics
    .filter(topic => !authenticatedUser.interests.includes(topic))
    .map(interest => {
      return { name: interest, reachable: Math.floor(Math.random() * 1000000) };
    });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const addOrRemoveFromPostTopics = interest => {
    let existingTopics = post.topics ?? [];
    if (existingTopics.includes(interest.name)) {
      existingTopics = existingTopics.filter(topic => topic !== interest.name);
    } else {
      existingTopics.push(interest.name);
    }
    setPost({ ...post, topics: existingTopics });
    setDropdownOpen(false);
  };

  return (
    <Dropdown
      show={dropdownOpen}
      onToggle={() => setDropdownOpen(!dropdownOpen)}
    >
      <Dropdown.Toggle as="div" className="dropdown-caret-none">
        <Form className="position-relative">
          <Form.Group
            as={Flex}
            alignItems="center"
            className="ps-x1 border border-200"
          >
            <Form.Label
              onClick={() => {
                document.getElementById('help_the_right_people_to_see').click();
              }}
              className="text-nowrap mb-0 me-2"
            >
              <FontAwesomeIcon icon="plus" className="me-1 fs-11" />
              <span className="fw-medium fs-10">{t('add_a_topic')}</span>
            </Form.Label>
            <Flex alignItems={'center'} style={{ width: '100%' }}>
              {(post.topics ?? []).length > 0 && (
                <Flex>
                  {post.topics.slice(0, 2).map(topic => {
                    return (
                      <SubtleBadge
                        pill
                        bg={'success'}
                        className="ms-2"
                        key={topic}
                      >
                        {topic}
                      </SubtleBadge>
                    );
                  })}
                  {post.topics.length > 2 && (
                    <SubtleBadge pill bg={'info'} className="ms-2">
                      +{post.topics.length - 2} {t('more')}
                    </SubtleBadge>
                  )}
                </Flex>
              )}
              <Form.Control
                id="help_the_right_people_to_see"
                style={{ flex: 1 }}
                type="search"
                placeholder={t('help_the_right_people_to_see')}
                className="border-0 fs-10 shadow-none"
              />
            </Flex>
          </Form.Group>
        </Form>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="scrollbar py-3" style={{ maxHeight: '22rem' }}>
          <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
            {t('your_interests')}
          </Dropdown.Header>
          <Flex
            alignItems={'center'}
            className={'gap-2 p-3'}
            style={{
              flexWrap: 'wrap'
            }}
          >
            {userInterests.map(interest => {
              return (
                <Button
                  onClick={() => {
                    addOrRemoveFromPostTopics(interest);
                  }}
                  size="sm"
                  key={interest.name}
                  variant={
                    (post.topics ?? []).includes(interest.name)
                      ? 'success'
                      : 'outline-primary'
                  }
                  className="rounded-pill"
                >
                  <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={'gap-1'}
                  >
                    <span>{interest.name}</span>
                    {(post.topics ?? []).includes(interest.name) && (
                      <FontAwesomeIcon icon={faCheckCircle} />
                    )}
                  </Flex>
                </Button>
              );
            })}
          </Flex>
          <Dropdown.Header as="h6" className="px-x1 pt-3 pb-2 fw-medium">
            {t('other_interests')}
          </Dropdown.Header>
          <Flex direction={'column'} className={'gap-2 p-3'}>
            {otherInterests.map(interest => {
              return (
                <Flex key={`${interest.name}-div`} alignItems={'center'}>
                  <Button
                    onClick={() => {
                      addOrRemoveFromPostTopics(interest);
                    }}
                    size="sm"
                    key={interest.name}
                    variant={
                      (post.topics ?? []).includes(interest.name)
                        ? 'success'
                        : 'outline-secondary'
                    }
                    className="rounded-pill"
                  >
                    <Flex
                      alignItems={'center'}
                      justifyContent={'center'}
                      className={'gap-1'}
                    >
                      <span>{interest.name}</span>
                      {(post.topics ?? []).includes(interest.name) && (
                        <FontAwesomeIcon icon={faCheckCircle} />
                      )}
                    </Flex>
                  </Button>
                  <div style={{ flex: 1 }}></div>
                  <span className="text-600">
                    {abbreviateNumber(interest.reachable)} {t('users')}
                  </span>
                </Flex>
              );
            })}
          </Flex>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default TopicsComponent;
