/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import generic8 from 'assets/img/generic/8.jpg';

const Logo = ({ at = 'auth', width = 58, className, textClass, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Link
      to="/dashboard/expert"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center  fs-0 mb-4': at === 'auth'
          },
          className
        )}
      >
        {/* <img className="me-2" src={logo} alt="Logo" width={width} /> */}
        <span
          className={classNames(textClass)}
          style={{ fontFamily: 'Pacifico' }}
        >
          {t('app_name')}
        </span>
      </div>
    </Link>
  );
};

export default Logo;
