import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import paths from 'routes/paths';
import useUserStore from 'store/userStore';

const ProfileDropdown = () => {
  const authenticatedUser = useUserStore(state => state.authenticatedUser);

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        {authenticatedUser.avatar ? (
          <Avatar src={authenticatedUser.avatar} size="xl" className="me-2" />
        ) : (
          <Avatar
            size="xl"
            name={
              authenticatedUser?.first_name + ' ' + authenticatedUser?.last_name
            }
            className="me-2"
          />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item
            as={Link}
            // eslint-disable-next-line prettier/prettier
            to={`${paths.userProfile(authenticatedUser?.username)}`}
          >
            My Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to={paths.simpleLogout}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
