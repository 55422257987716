/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import Flex from "components/common/Flex";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import usePostsStore from "store/posts_store";
import { urlPathIsHexaDecimal } from "utils/url";
import FeedCard from "./FeedCard";
import { PulseLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import APIService from "http/api_service";
import paths from 'routes/paths';

const FeedDetailsPage = () => {

    const { t } = useTranslation();
    const { id } = useParams();
    const [openModal] = useState(true);
    const navigate = useNavigate();
    const posts = usePostsStore(state => state.posts);
    const [post, setPost] = useState();
    const [fetchingPost, setFetchingPost] = useState(false);
    const [fetchError, setFetchError] = useState();
    const location = useLocation();

    useEffect(() => {
        if (id && !urlPathIsHexaDecimal(id)) {
            navigate(-1);
            return;
        }
        const fetchPost = async () => {
            let postIndex = posts.findIndex(p => p._id === id);
            if (postIndex != -1) {
                setPost(posts[postIndex])
            } else {
                setFetchingPost(true);
                APIService.fetchPost(id, (response, error) => {
                    setFetchingPost(false);
                    if (error) {
                        setFetchError(error);
                        return;
                    }
                    let { data } = response;
                    setPost(data);
                });
            }
        }
        fetchPost();
    }, [id]);

    const hideModal = () => {
        if (location.state && location.state.from) {
            navigate(-1);
        } else {
            navigate(paths.feed);
        }
    }

    return (
        <Modal size="lg" show={openModal} onExit={hideModal} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2 style={{
                        fontSize: 18
                    }}>{t('post_details')}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='pt-0'>
                {
                    fetchingPost &&
                    <Flex alignItems={'center'} justifyContent={'center'}>
                        <PulseLoader size={5} color="#2c7be5" />
                    </Flex>
                }
                {
                    !fetchingPost && post &&
                    <FeedCard
                        feed={post}
                        onDeleteDoneCallback={() => {
                            hideModal();
                        }}
                    />
                }
                {
                    !fetchingPost && fetchError &&
                    <Flex alignItems={'center'} justifyContent={'center'}>
                        <span className="p-3 text-danger">{fetchError}</span>
                    </Flex>
                }
            </Modal.Body>
        </Modal>
    );

}

export default FeedDetailsPage;