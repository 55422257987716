import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TermsAndPolicies } from '../../constants';

export default function AuthTermsAndPolicies() {
  const { t } = useTranslation();
  return (
    <p className="text-align-center">
      {t('auth_terms_disclaimer_pre_line')}{' '}
      <Link to={TermsAndPolicies.TERMS_OF_SERVICE} target="_blank">
        <b>{t('terms_of_service')}</b>
      </Link>{' '}
      {t('auth_acknowledgement')}{' '}
      <Link to={TermsAndPolicies.PRIVACY_POLICY} target="_blank">
        <b>{t('privacy_policy')}</b>
      </Link>
      .
    </p>
  );
}
