import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import creatorPostPage from 'assets/img/illustrations/post.png';
// import paths from 'routes/paths';
const PostPage = () => {
  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col lg={5}>
            <img src={creatorPostPage} className="img-fluid" alt="" />
          </Col>
          <Col lg={7} className="ps-lg-4 my-5 text-center text-lg-left">
            <h3>Start podcasting on Reachable</h3>
            <p className="lead">
              Bring your posts to life with audio notes, read alouds, or full
              podcast episodes.
            </p>
            <Link className="btn btn-falcon-primary" to="#">
              Join waitlist
            </Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default PostPage;
