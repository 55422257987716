/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom';
import FeedDropdown from './FeedDropdown';
import { getFullName, getUsername } from 'utils/user_utils';
import { formatRelativeTime } from 'utils/time';
import FeedCardContent from './FeedCardContent';
import FeedCardFooter from './FeedCardFooter';
import useUserStore from 'store/userStore';

const FeedCardBody = ({
  // eslint-disable-next-line react/prop-types
  _id,
  author,
  created_at,
  shared,
  post,
  text,
  likesCount,
  commentsCount,
  sharesCount,
  liked,
  commented,
  topics,
  likes,
  comments,
  media,
  onDeleteDoneCallback,
}) => {
  const authenticatedUser = useUserStore(state => state.authenticatedUser);

  return (
    <Card.Body className='pb-2'>
      <Flex>
        <Flex className={'flex-grow-1'}>
          <Flex style={{ height: 40 }}>
            <Link to={`/${getUsername(author)}`}>
            <Avatar
                {...(authenticatedUser.avatar
                  ? { src: authenticatedUser.avatar }
                  : { name: `${authenticatedUser?.first_name ?? ''} ${authenticatedUser?.last_name ?? ''}` })}
                size="xl"
                className="me-2"
              />
            </Link>
          </Flex>
          <Flex direction={'column'} className={'flex-1 ms-1'}>
            <Flex
              alignItems={'center'}
              style={{
                flexWrap: 'wrap'
              }}>
              <Link className="fw-semibold text-900 me-1" to={`/${getUsername(author)}`}>
                {getFullName(author)} {" "}
              </Link>
              <Link className="text-700" to={`/${getUsername(author)}`}>
                @{author.username}
              </Link>
              <span className='mx-1'>•</span>
              <p className="mb-0" style={{ fontSize: 12 }}>{`${formatRelativeTime(new Date(created_at))}`}</p>
            </Flex>
            <div className='mt-1'>
              {(text || media) && <FeedCardContent {...{ text, media }} />}
            </div>
            <div style={{ width: '100%' }} className='mt-2'>
              <FeedCardFooter author={author} _id={_id} {...{ likesCount, shared, sharesCount, commentsCount, comments, liked, likes, commented, topics }} />
            </div>
          </Flex>
        </Flex>
        <FeedDropdown
          onDeleteDoneCallback={onDeleteDoneCallback}
          authorId={author._id}
          post={post}
        />
      </Flex>
    </Card.Body>
  );
};

export default FeedCardBody;
