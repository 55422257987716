export default class HttpErrorHandler {
  static spitHttpErrorMsg(e) {
    if (e !== undefined && e !== null) {
      if (Object.prototype.hasOwnProperty.call(e, 'response')) {
        let response = e.response;
        if (response !== undefined && response !== null) {
          if (Object.prototype.hasOwnProperty.call(response, 'data')) {
            let responseData = response['data'];
            if (
              responseData &&
              Object.prototype.hasOwnProperty.call(responseData, 'message')
            ) {
              return responseData.message;
            }
          }
        }
      }
    }
    return navigator.onLine
      ? 'Sorry, something went wrong. Please try again later'
      : 'Oops! An error occurred!.\nEnsure your data connection is still right.';
  }
}
