/* eslint-disable react/prop-types */
import { faHeading } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { useRef, useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React from 'react';

const Heading = ({ editor }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  let headingCount = 6;
  let headings = Array.from({ length: headingCount }, (_, i) => i + 1);

  const handleOnMouseEnter = () => {
    setShow(true);
  };

  const handleOnMouseLeave = () => {
    setShow(false);
  };

  return (
    <OverlayTrigger
      show={show}
      placement="bottom"
      container={ref}
      overlay={
        <Popover
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          <Popover.Body>
            <Flex
              direction={'column'}
              className={'gap-3'}
              alignContent={'flex-start'}
              justifyContent={'flex-start'}
            >
              {headings.map(heading => {
                return (
                  <Button
                    key={`heading-${heading}`}
                    size={'sm'}
                    variant="link"
                    onClick={e => {
                      e.stopPropagation();
                      editor
                        .chain()
                        .focus()
                        .toggleHeading({ level: heading })
                        .run();
                    }}
                    className={`text-decoration-none m-0 py-0 px-2 text-600 rounded-2 ${
                      !editor.isActive('heading', { level: heading })
                        ? 'bg-50'
                        : 'bg-300'
                    } hover-bg-200`}
                  >
                    <Flex alignItems={'center'}>
                      <span className="me-2">
                        <b>H</b>
                        <sub>
                          <b>{heading}</b>
                        </sub>
                      </span>
                      <span className="fs-12">Heading {heading}</span>
                    </Flex>
                  </Button>
                );
              })}
            </Flex>
          </Popover.Body>
        </Popover>
      }
    >
      <span
        ref={ref}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        className={`cursor-pointer ${
          headings.some(heading =>
            editor.isActive('heading', { level: heading })
          )
            ? 'bg-200 px-2 rounded-4'
            : ''
        }`}
      >
        <Flex alignItems={'center'} className={'gap-2'}>
          <FontAwesomeIcon icon={faHeading} style={{ width: 14, height: 14 }} />
          <span>{t('format')}</span>
        </Flex>
      </span>
    </OverlayTrigger>
  );
};
export default Heading;
