/* eslint-disable no-unused-vars */
import React from 'react';
import Flex from 'components/common/Flex';
import { Button, Modal } from 'react-bootstrap';
import useUserStore from 'store/userStore';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';

export default function SwitchEmail() {
  const { t } = useTranslation();
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const [smShow, setSmShow] = useState(false);
  const navigate = useNavigate();

  const handleLogOut = () => {
    navigate(paths.simpleLogout);
  };

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      style={{
        flexWrap: 'wrap'
      }}
    >
      <span
        style={{
          fontSize: 12
        }}
      >
        ({authenticatedUser?.email})
      </span>
      <Button
        size="sm"
        variant="link"
        style={{
          fontSize: 12
        }}
        onClick={() => setSmShow(true)}
      >
        {t('switch_email')}
      </Button>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="switch-email-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="switch-email-modal-sizes-title-sm">
            {`${t('logout')}?`}
          </Modal.Title>
          <FalconCloseButton onClick={() => setSmShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <span
            dangerouslySetInnerHTML={{
              __html: `${t('switch_email_consent', {
                email: `<b>"${authenticatedUser?.email}"</b>`
              })}`
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setSmShow(false);
            }}
          >
            {t('no')}
          </Button>
          <Button variant="primary" onClick={handleLogOut}>
            {t('yes')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Flex>
  );
}
