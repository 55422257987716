import React from 'react';
import Hero from './Hero';
import LandingNavbar from './LandingNavbar';
// import Partners from './Partners';
// import Processes from './Processes';
// import Services from './Services';
// import Testimonial from './Testimonial';
import Cta from './Cta';
import FooterStandard from './FooterStandard';
import withAuth from 'components/authentication/withAuth';

const Landing = () => {
  return (
    <>
      <LandingNavbar />
      <Hero />
      {/* <Partners /> */}
      {/* <Processes /> */}
      {/* <Services /> */}
      {/* <Testimonial /> */}
      <Cta />
      <FooterStandard />
    </>
  );
};

export default withAuth(Landing, true);
