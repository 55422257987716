/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import CreatePost from './CreatePost';
import FeedCard from './FeedCard';
import classNames from 'classnames';
import usePostsStore from 'store/posts_store';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { useTranslation } from 'react-i18next';
import useUserStore from 'store/userStore';
import { useAppContext } from 'providers/AppProvider';
import { Link } from 'react-router-dom';
import { quickLinks } from 'data/media-request/quickLinksData';

const AdminItem = ({ item, onClick }) => {
  return (
    <Col md={3}>
      <Link to={item.linkURL} className="text-decoration-none" onClick={onClick}>
        <div className="hover-bg-200 bg-200 py-x1 text-center rounded-3 position-relative mb-2">
          <div className={`icon-item icon-item-xl  mx-auto bg-${item.color}-subtle`}>
            {item.icon}
          </div>
          <h5 className="fs-9 mt-2 mb-2">{item.title}</h5>
          <h6 className="ps-1 pe-1 mx-auto text-600">{item.description}</h6>
        </div>
      </Link>
    </Col>
  );
};

const FeedContent = () => {
  const posts = usePostsStore(state => state.posts);
  const setPosts = usePostsStore(state => state.setPosts);
  const [fetching, setFetching] = useState(false);
  const { t } = useTranslation();
  const [openPostModal, setOpenPostModal] = useState(false);
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const {
    config: { isDark }
  } = useAppContext();
  const [openFooter, setOpenFooter] = useState(false);

  const fetchPostsOfInterest = () => {
    setFetching(true);
    APIService.fetchPostsOfInterest((response, error) => {
      setFetching(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let { data } = response;
      data.forEach(incomingPost => {
        const existingIndex = posts.findIndex(
          post => post._id === incomingPost._id
        );
        if (existingIndex !== -1) {
          posts[existingIndex] = incomingPost;
        } else {
          posts.unshift(incomingPost);
        }
      });
      posts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setPosts([...posts]);
    });
  };

  useEffect(() => {
    fetchPostsOfInterest();
  }, []);

  const toggleFooter = () => {
    setOpenFooter(!openFooter);
  };

  const handleItemClick = (item) => {
    if (item.title === 'Create Post' || item.title === 'Share work') {
      setOpenPostModal(true);
    }
  };

  return (
    <Flex className={'rounded-3 border border-200'} direction={'column'}>
      <Flex className={'cursor-pointer p-4 px-3'} alignItems={'center'}>
        <Flex className={'flex-grow-1'} alignItems="center">
          <Avatar
            {...(authenticatedUser.avatar
              ? { src: authenticatedUser.avatar }
              : {
                  name: `${authenticatedUser?.first_name ?? ''} ${
                    authenticatedUser?.last_name ?? ''
                  }`
                })}
            size="m"
          />
          <div className="flex-1 ms-2" onClick={toggleFooter}>
            <span onClick={toggleFooter} className='text-500'>{t('what_are_you_looking_for_today')} </span>
          </div>
        </Flex>
        <Button
          className="rounded-4"
          onClick={toggleFooter}
          size="sm"
          variant={`${isDark ? 'outline-light' : 'falcon-default'}`}
        >
          {openFooter ? t('close') : t('share')}
        </Button>
      </Flex>
      {openFooter && (
        <React.Fragment>
          <Card className="shadow-none p-1">
            <Row className="g-2">
              {quickLinks.map((item, index) => (
                <AdminItem key={index} item={item} onClick={() => handleItemClick(item)} />
              ))}
            </Row>
          </Card>
        </React.Fragment>
      )}
      <hr className="text-200" style={{ margin: 0 }} />
      {fetching && (
        <Flex className={'p-3'} alignItems={'center'} justifyContent={'center'}>
          <Spinner animation="border" size="small" />
        </Flex>
      )}
      {posts.map((post, index) => (
        <Flex
          className={'cursor-pointer'}
          key={post._id}
          direction={'column'}
          style={{ position: 'relative' }}
        >
          <FeedCard
            feed={post}
            className={classNames({
              'mb-3': index + 1 !== posts.length,
              'mb-3 mb-lg-0': index + 1 === posts.length
            })}
          />
          {index !== posts.length - 1 && (
            <hr className="text-200" style={{ margin: 0 }} />
          )}
        </Flex>
      ))}

      <Modal
        centered
        show={openPostModal}
        onHide={() => {
          setOpenPostModal(false);
        }}
        onExit={() => {
          setOpenPostModal(false);
        }}
      >
        <Modal.Body>
          <CreatePost
            onDoneCallback={() => {
              setOpenPostModal(false);
            }}
            footerOpen
            className="mb-3"
          />
        </Modal.Body>
      </Modal>
    </Flex>
  );
};

export default FeedContent;
