/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import Avatar from "components/common/Avatar";
import Flex from "components/common/Flex";
import SubmitButton from "components/common/SubmitButton";
import { forwardRef, useState } from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
    getFullName,
    getUsername
} from "utils/user_utils";

const TipContent = forwardRef(({ tippee }, ref) => {

    const { t } = useTranslation();
    const tippableAmounts = [5, 10, 25, 50, 100];
    const [selectedTip, setSelectedTip] = useState(tippableAmounts[1]);
    const [processing, setProcessing] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessing(true);
    }

    return (
        <Flex ref={ref} className={'gap-2'} direction={'column'}>
            <Flex alignItems={'center'} className={'gap-2'}>
                <Avatar src={tippee.avatar} size="2xl" />
                <Flex direction={'column'} className={'fs-10'}>
                    <Link className="fw-semibold text-900 me-2" to={`/${getUsername(tippee)}`}>
                        {getFullName(tippee)} {" "}
                    </Link>
                    <Link className="text-700" to={`/${getUsername(tippee)}`}>
                        @{tippee.username}
                    </Link>
                </Flex>
            </Flex>
            <Flex alignItems={'center'} className={'gap-2 mt-2'}>
                {
                    tippableAmounts.map((tip) => {
                        return (<Button disabled={processing} key={tip} onClick={() => setSelectedTip(tip)} className={`rounded-5`} variant={`${selectedTip === tip ? 'outline-warning' : 'light'}`}>{`$${tip}`}</Button>)
                    })
                }
            </Flex>
            <Form onSubmit={handleSubmit}>
                <InputGroup className="my-3">
                    <InputGroup.Text>$</InputGroup.Text>
                    <FormControl
                        disabled={processing}
                        type="number"
                        value={selectedTip}
                        onChange={(e) => setSelectedTip(parseInt(e.target.value))}
                        placeholder={t('enter_amount_to_the_nearest_dollar')}
                        aria-label={t('enter_amount_to_the_nearest_dollar')}
                    />
                </InputGroup>
                <SubmitButton
                    processing={processing}
                    disabled={!selectedTip}
                    label={t('tip')}
                    className={'rounded-pill my-2'}
                    style={{ width: '100%' }}
                />
            </Form>
        </Flex>
    );
});

export default TipContent;