import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BusinessesForSaleGrid from 'components/pages/landing/BusinessesForSaleGrid';

const BusinessesForSale = () => {
  const staticCourses = [
    {
      id: 1,
      name: 'Liam Cunningham',
      avatar:
        'https://falconreact.prium.me/static/media/9.57c5c6012dba7d53ee2e.jpg',
      job_title: 'AI Creator | 200k+ followers',
      price: 100,
      rating: 4.5,
      review: 20
    },
    {
      id: 2,
      name: 'Gwendoline Christie',
      avatar:
        'https://falconreact.prium.me/static/media/15.7b35fdd1920172819548.jpg',
      job_title: 'YouTube | 770k subscribers',
      price: 200,
      rating: 4.0,
      review: 15
    },
    {
      id: 3,
      name: 'Course 3',
      avatar:
        'https://falconreact.prium.me/static/media/8.f10cab45621e51a3042a.jpg',
      job_title: 'Spotify | 451k followers',
      price: 150,
      rating: 4.8,
      review: 30
    },
    {
      id: 4,
      name: 'Maisie Williams',
      avatar:
        'https://falconreact.prium.me/static/media/13.0abf9d251d37a62bcb11.jpg',
      job_title: 'OnlyFans | 1m+ fans',
      price: 150,
      rating: 4.8,
      review: 30
    }
  ];
  return (
    <>
      <h2 className="text-dark fs-3 font-weight-bold mt-4 mb-6">
        Trusted by <span style={{ color: '#00d27a' }}>big creators</span>
      </h2>
      <Row className="g-3">
        {staticCourses.map(item => (
          <Col md={3} xxl={3} key={item.id}>
            <BusinessesForSaleGrid {...item} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default BusinessesForSale;
