/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SubmitButton from 'components/common/SubmitButton';
import SwitchEmail from 'components/authentication/simple/EmailSwitch';
import { useAppContext } from 'providers/AppProvider';
import useUserStore from 'store/userStore';
import { useNavigate } from 'react-router-dom';
import APIService from 'http/api_service';
import { rootPaths } from 'routes/paths';
import { toast } from 'react-toastify';
import { profileTopics } from 'components/app/social/feed/data/profileTopics';
import MultiSelect from 'components/common/MultiSelect';

const UpdateInterests = () => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();
  const [processing, setProcessing] = useState(false);
  const setAuthenticatedUser = useUserStore(
    state => state.setAuthenticatedUser
  );
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const [formData, setFormData] = useState(authenticatedUser?.interests ?? []);

  const {
    config: { isDark }
  } = useAppContext();

  const navigate = useNavigate();

  const onSubmit = data => {
    setProcessing(true);
    APIService.updateAccountDetails(
      { interests: formData },
      (response, err) => {
        setProcessing(false);
        if (err) {
          toast.error(err);
          return;
        }
        let { data } = response;
        setAuthenticatedUser(data);
        navigate(rootPaths.root);
      }
    );
  };

  return (
    <>
      <Flex
        justifyContent="between"
        direction={'column'}
        alignItems="center"
        className="mb-2"
      >
        <h5>{t('interests')}</h5>
        <p>{t('pick_your_interests')}</p>
      </Flex>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Label>
            Interests<span className="text-danger">*</span>
          </Form.Label>
          <Controller
            name="interests"
            control={control}
            defaultValue={formData}
            render={({ field }) => (
              <MultiSelect
                {...field}
                closeMenuOnSelect={false}
                isMulti
                options={profileTopics}
                placeholder="Select topics..."
                onChange={selected => {
                  field.onChange(selected);
                  setFormData(selected.map(item => item.value));
                }}
              />
            )}
          />
        </Form.Group>
        <Form.Group className="mb-4 mt-4">
          <SubmitButton
            className="w-100"
            disabled={formData.length < 1}
            processing={processing}
            label={t('next')}
          />
        </Form.Group>
      </Form>
      <SwitchEmail />
    </>
  );
};

export default UpdateInterests;
