import React from 'react';
import { Col, Form, FormCheck } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const PricingAltHeader = () => {
  return (
    <Col xs={12} className="text-center mb-4">
      <h3 className="fs-7 fs-md-6">Upgrade Your Account</h3>
      <div className="fs-8 mb-2">
        Secure placements in top publications to grow as a thought leader, and
        expand your organic reach.
      </div>
      <Flex justifyContent="center">
        <FormCheck.Label htmlFor="custom-switch" className="me-2">
          Monthly
        </FormCheck.Label>
        <Form.Check
          type="switch"
          style={{
            width: '30px',
            height: '30px',
            transform: 'scale(1.5)'
          }}
        >
          <Form.Check.Input
            type="checkbox"
            className="falcon-form-check-input"
            defaultChecked
          />
        </Form.Check>
        <Form.Check.Label className="ms-2 align-top">
          Yearly(Save 17%)
        </Form.Check.Label>
      </Flex>
    </Col>
  );
};

export default PricingAltHeader;
