import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import StarRating from 'components/common/StarRating';

const CourseFeatures = ({ data }) => {
  return (
    <Card className="mb-3 bg-transparent shadow-none">
      <Card.Body>
        <Row className="gy-4">
          {data.map(({ id, description }) => (
            <Col
              md={4}
              key={id}
              className="d-flex flex-column align-items-center text-center"
            >
              <StarRating readonly rating={5} />
              <p className="fs-10 mb-0">{description}</p>
              <Flex alignItems="center" className="gap-2">
                <Link to={''}>
                  <Avatar src={''} size="2xl" />
                </Link>
                <p className="mb-0">
                  <Link to={''} className="fw-semibold mb-0 text-800">
                    message
                  </Link>
                  <a className="mb-0 fs-10 d-block text-500" href={`mailto:`}>
                    message.email
                  </a>
                </p>
              </Flex>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

CourseFeatures.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};

export default CourseFeatures;
