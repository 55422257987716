const windowHeight = window.innerHeight;

const darkTransparency = () => {
  const scrollTop = window.scrollY;
  let alpha = (scrollTop / windowHeight) * 2;
  if (alpha >= 1) alpha = 1;
  document.getElementsByClassName(
    'navbar-theme'
  )[0].style.backgroundColor = `rgba(11, 23, 39, ${alpha})`;
};

const lightTransparency = () => {
  const scrollTop = window.scrollY;
  let alpha = (scrollTop / windowHeight) * 2;
  if (alpha >= 1) alpha = 1;
  document.getElementsByClassName(
    'navbar-theme'
  )[0].style.backgroundColor = `rgba(255, 255, 255, ${alpha})`;
};

export { darkTransparency, lightTransparency };
