/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Flex from 'components/common/Flex';
import { Card } from 'react-bootstrap';
import ChatContent from './content/ChatContent';
import ChatSidebar from './sidebar/ChatSidebar';
// import ChatProvider, { useChatContext } from 'providers/ChatProvider';
import useConversationsStore from 'store/conversationStore';
import usePubSub from 'pubsub';

const ChatTab = () => {
  const {
    updateConversations,
    conversations,
    activeConversation,
    setActiveConversation,
    messages,
    addMessages
  } = useConversationsStore(state => ({
    conversations: state.conversations,
    updateConversations: state.updateConversations,
    activeConversation: state.activeConversation,
    setActiveConversation: state.setActiveConversation,
    messages: state.messages,
    addMessages: state.addMessages
  }));

  console.log('🚀 ~ ChatTab ~ conversations:', conversations);

  const {
    // sendMessage,
    fetchMessageInConversations,
    listenToConversationMessages,
    fetchUserConversations,
    listenToUserConversationsFeedback
  } = usePubSub();

  const [hideSidebar, setHideSidebar] = useState(false);

  console.log('🚀 ~ ChatTab ~ messages OVERALL:', messages);

  const prepareConversationList = conversations => {
    let conversationList = [];
    for (let conversation of conversations) {
      let newConversationBody = {
        id: conversation?._id,
        messagesId: conversation?.lastMessageId,
        lastMessage: conversation?.lastMessage,
        read: false,
        _id: conversation?._id
      };
      if (conversation?.type === 'one_to_one') {
        (newConversationBody.type = 'user'),
          (newConversationBody.userId = conversation?._id);
      } else {
        (newConversationBody.type = 'group'),
          (newConversationBody.groupId = conversation?._id);
      }
      conversationList.push(newConversationBody);
    }
    console.log(conversationList, 'conversationList');
    updateConversations(conversationList);
  };

  const startListeningToConversations = () => {
    for (const conversation of conversations) {
      listenToConversationMessages(conversation?.id, messages => {
        addMessages(messages, conversation?.id);
      });
    }
  };

  const getConversationOfUser = userId => {
    listenToUserConversationsFeedback(userId, convos => {
      prepareConversationList(convos);
    });
    fetchUserConversations(userId);
  };

  useEffect(() => {
    getConversationOfUser('xxxxxxxxxxxxxxxxxxxxxxxxxxxx');
  }, []);

  useEffect(() => {
    startListeningToConversations();
  }, [conversations?.length]);

  // console.log('🚀 ~ ChatTab ~ activeConversation:', activeConversation);

  const handleSelect = e => {
    setHideSidebar(false);
    // setIsOpenThreadInfo(false);
    // const conversation = conversations.find(
    //   conversation => conversation.id === e?.id
    // );
    // console.log("🚀 ~ handleSelect ~ conversation:", conversation)
    // // setCurrentThread(conversation);
    setActiveConversation(e);
    fetchMessageInConversations({
      conversationId: e?.id
    });
    // threadsDispatch({
    //   type: 'EDIT',
    //   id: thread.id,
    //   payload: { ...thread, read: true }
    // });
    // setScrollToBottom(true);
  };

  return (
    // <Tab.Container
    //   id="left-tabs-example"
    //   defaultActiveKey="0"
    // // onSelect={handleSelect}
    // >
    <Card className="card-chat overflow-hidden">
      <Card.Body as={Flex} className="p-0 h-100">
        <ChatSidebar handleSelect={handleSelect} hideSidebar={hideSidebar} />
        <ChatContent setHideSidebar={setHideSidebar} />
      </Card.Body>
    </Card>
    // </Tab.Container>
  );
};

const Chat = () => {
  return (
    // <ChatProvider>
    <ChatTab />
    // {/* </ChatProvider> */}
  );
};

export default Chat;
