/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Offcanvas, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import MediaRequestsHeader from './MediaRequestsHeader';
import MediaRequestFilters from './MediaRequestFilters';
import { useBreakpoints } from 'hooks/useBreakpoints';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { columns } from 'hooks/useSupportDeskTable';
import APIService from 'http/api_service';
import useMediaRequestsStore from 'store/media_request_store';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import MediaRequestDetails from './MediaRequestDetails';
import { useLocation } from 'react-router-dom';
import useSearchStore from 'store/search_store';
import useAdvanceTable from 'hooks/useAdvanceTable';
import MediaRequestsCardLayout from './MediaRequestsCardLayout';

const MediaRequests = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const [showMediaRequestDetails, setShowMediaRequestDetails] = useState(false);
  const [showMediaRequestFilterOffcanvas, setShowMediaRequestFilterOffcanvas] = useState(false);
  const [selectedMediaRequest, setSelectedMediaRequest] = useState(null);
  const { breakpoints } = useBreakpoints();
  const handleCloseMediaRequestDetails = () => setShowMediaRequestDetails(false);
  const handleShowMediaRequestDetails = mediaRequest => {
    setSelectedMediaRequest(mediaRequest);
    // setShowMediaRequestDetails(!showMediaRequestDetails);
    setShowMediaRequestDetails(true);
  };
  const searchResults = useSearchStore(state => state.searchResults);
  const searchText = useSearchStore(state => state.searchText);
  const mediaRequests = useMediaRequestsStore(state => state.mediaRequests) || [];
  const table = useAdvanceTable({
    columns,
    data: mediaRequests,
    perPage: 10,
    pagination: true
  });
  const [filterableRequests, setFilterableRequests] = useState([]);
  const setMediaRequests = useMediaRequestsStore(state => state.setMediaRequests);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    let mediaSearch = searchResults[location.pathname] ?? [];
    if (mediaSearch.length > 0) {
      setMediaRequests(mediaSearch);
    } else {
      if ((searchText ?? "").length < 1) {
        setMediaRequests(filterableRequests);
      } else {
        setMediaRequests([]);
      }
    }
  }, [searchResults]);

  const fetchMediaRequests = ({ skip, paginated = false }) => {
    setFetching(true);
    APIService.fetchMediaRequests({ skip }, (response, error) => {
      setFetching(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      const { data } = response;
      if (Array.isArray(data)) {
        data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        if (paginated && data.length === 0) {
          return;
        }
        if (skip === 0) {
          setMediaRequests(data);
          setFilterableRequests(data);
        } else {
          let mediaRequestsCopy = [...mediaRequests, ...data];
          setMediaRequests(mediaRequestsCopy);
          setFilterableRequests(mediaRequestsCopy);
        }
      } else {
        setMediaRequests([]);
        setFilterableRequests([]);
      }
    });
  };

  useEffect(() => {
    fetchMediaRequests({ skip: 0 });
  }, []);

  const loadMoreLazily = (pagesCount, currentPage) => {
    if (pagesCount - 1 === currentPage) {
      fetchMediaRequests({ skip: mediaRequests.length, paginated: true });
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${t('media_request_page_title')} - ${t('app_name')}`}</title>
        <meta charSet="utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@reachableonline " />
        <meta name="twitter:creator" content="@reachableonline " />
        <meta property="og:description" content={t('app_name')} />
        <meta
          content="https://twitter.com/reachableonline"
          property="og:see_also"
        />
        <meta name="twitter:title" content={t('app_name')} />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image:width" content="1001 " />
        <meta name="twitter:image:height" content="459 " />
        <link href="https://reachable.chat/" rel="canonical " />
        <link href="https://reachable.chat/" rel="home " />
      </Helmet>

      <Row className="gx-3">
        <Col xxl={10} xl={9}>
          <AdvanceTableProvider {...table}>
            <Card className="shadow-none border border-200">
              <Card.Header className="border-bottom border-200 px-0  pr-0">
                <MediaRequestsHeader
                  layout="card-view"
                  setShowMediaRequestFilterOffcanvas={
                    setShowMediaRequestFilterOffcanvas
                  }
                />
              </Card.Header>
              <Card.Body className="bg-body-tertiary p-2">
                {fetching && (
                  <Flex
                    className={'p-3'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Spinner animation="grow" variant="primary" size="small" />
                  </Flex>
                )}
                <MediaRequestsCardLayout searchTerm={searchText} handleShowMediaRequestDetails={handleShowMediaRequestDetails} />
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <AdvanceTablePagination loadMoreLazily={loadMoreLazily} />
              </Card.Footer>
            </Card>
          </AdvanceTableProvider>
        </Col>
        <Col xxl={2} xl={3}>
          {breakpoints.down('xl') ? (
            <Offcanvas
              show={showMediaRequestFilterOffcanvas}
              onHide={() => setShowMediaRequestFilterOffcanvas(false)}
              placement="end"
              className="dark__bg-card-dark"
            >
              <Offcanvas.Header closeButton className="bg-body-tertiary">
                <h6 className="fs-9 mb-0 text-700">{t('filter')}</h6>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <MediaRequestFilters />
              </Offcanvas.Body>
            </Offcanvas>
          ) : (
            <MediaRequestFilters />
          )}
        </Col>
      </Row>
      {selectedMediaRequest && (
        <MediaRequestDetails
          open={showMediaRequestDetails}
          mediaRequest={selectedMediaRequest}
          closeDrawer={handleCloseMediaRequestDetails}
        />
      )}
    </>
  );
};

export default MediaRequests;
