/* eslint-disable react/react-in-jsx-scope */
import { FiFileText, FiSunrise } from 'react-icons/fi';
import { FaUserTie } from 'react-icons/fa';
import paths from 'routes/paths';

export const quickLinks = [
  {
    icon: <FaUserTie className="text-primary fs-7" />,
    color: 'primary',
    title: 'Experts & guests',
    description:
      'Connect with the right expertise for your next publication or podcast.',
    linkURL: paths.addMediaRequest
  },
  {
    icon: <FiSunrise className="text-warning fs-7" />,
    color: 'warning',
    title: 'Media requests',
    description: 'Find media requests to share your insights and be featured.',
    linkURL: paths.mediaRequests
  },
  {
    icon: <FiFileText className="text-success fs-7" />,
    color: 'success',
    title: 'Share work',
    description: 'Promote your published work to a global audience.',
    linkURL: paths.createPost
  },
  {
    icon: <FiFileText className="text-primary fs-7" />,
    color: 'primary',
    title: 'Create Post',
    description: 'Share your thoughts and updates with the community.',
    linkURL: ''
  }
];
