import { v4 as uuid } from 'uuid';
import user4 from 'assets/img/team/4.jpg';
import user13 from 'assets/img/team/13.jpg';
import img1 from 'assets/img/generic/4.jpg';
import img2 from 'assets/img/generic/5.jpg';
import img3 from 'assets/img/gallery/4.jpg';
import img4 from 'assets/img/gallery/5.jpg';
import img5 from 'assets/img/gallery/3.jpg';

export default [
  {
    _id: uuid(),
    author: {
      first_name: 'Rowan',
      last_name: 'Atkinson',
      avatar: user4
    },
    share: 'album',
    privacy: 'friends',
    createdAt: Date.now(),
    text: 'Rowan Sebastian Atkinson CBE is an English actor, comedian and screenwriter best known for his work on the sitcoms Blackadder and Mr. Bean.',
    media: [img1, img2, img3, img4, img5],
    likesCount: 0,
    commentsCount: 0,
    sharesCount: 0,
    comments: [
      {
        _id: uuid(),
        author: {
          avatar: user13,
          first_name: 'Rowan',
          last_name: 'Atkinson'
        },
        text: `She starred as Jane Porter in The <a href="#!">@Legend of Tarzan (2016)</a>, Tanya Vanderpoel in for which nominated for a Teen Choice Award, and many other awards.`,
        createdAt: Date.now()
      }
    ],
    otherComments: '2 of 34'
  }
];
