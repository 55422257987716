/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Comment from './Comment';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import { PulseLoader } from 'react-spinners';

const Comments = ({ commentsCount, ...others }) => {

  const { t } = useTranslation();
  const [comments, setComments] = useState(others.comments.slice(0, 2));
  const otherCommentsCount = commentsCount - comments.length;
  const [fetchingMoreComments, setFetchingMoreComments] = useState();

  const toggleCommentLike = ({ postId, commentId, liked }) => {
    let commentsCopy = [...comments];
    let commentIndex = commentsCopy.findIndex(comment => comment._id === commentId);
    if (commentIndex !== -1) {
      let val = !liked;
      let count = commentsCopy[commentIndex].likesCount ?? 0;
      commentsCopy[commentIndex].liked = val;
      commentsCopy[commentIndex].likesCount = val ? count + 1 : count - 1;
      setComments([...commentsCopy]);
    }
    APIService.likeOrUnLikeComment(postId, commentId, (_response, err) => {
      if (err) {
        console.log(err);
        if (commentIndex !== -1) {
          commentsCopy[commentIndex].liked = !liked;
          setComments([...commentsCopy]);
        }
        return;
      }
    });
  }

  const toggleReplyLike = ({ replies, liked, postId, commentId, replyId }) => {
    let repliesCopy = [...replies];
    let replyIndex = repliesCopy.findIndex(reply => reply._id === replyId);
    let commentsCopy = [...comments];
    let commentIndex = commentsCopy.findIndex(comment => comment._id === commentId);
    if (commentIndex !== -1 && replyIndex !== -1) {
      let val = !liked;
      let count = repliesCopy[replyIndex].likesCount ?? 0;
      repliesCopy[replyIndex].liked = val;
      repliesCopy[replyIndex].likesCount = val ? count + 1 : count - 1;
      commentsCopy[commentIndex]['replies'] = repliesCopy;
      setComments([...commentsCopy]);
    }
    APIService.likeOrUnLikeComment(postId, replyId, (_response, err) => {
      if (err) {
        console.log(err);
        if (commentIndex !== -1) {
          repliesCopy[replyIndex].liked = !liked;
          commentsCopy[commentIndex]['replies'] = repliesCopy;
          setComments([...commentsCopy]);
        }
        return;
      }
    });
  }

  const deleteComment = (postId, commentId) => {
    let commentsCopy = [...comments];
    let commentIndex = commentsCopy.findIndex(item => item._id === commentId);
    if (commentIndex !== -1) {
      let comment = commentsCopy[commentIndex]
      commentsCopy[commentIndex] = { ...comment, deleted: true };
      setComments([...commentsCopy]);
      APIService.deleteComment(postId, commentId, (response, err) => {
        if (err) {
          commentsCopy[commentIndex] = { ...comment, deleted: false };
          setComments([...commentsCopy]);
          toast.error(err, { theme: 'colored' });
          return;
        }
      });
    } else {
      toast.error(t('oops', { theme: 'colored' }));
    }
  }

  const onNewReplyUpserted = (replies, reply, index) => {
    let repliesCopy = [...(replies ?? [])];
    let replyIndex = repliesCopy.findIndex((entry) => reply._id === entry._id);
    if (replyIndex !== -1) {
      repliesCopy[replyIndex] = reply;
    } else {
      if (reply.push) {
        repliesCopy.push(reply);
      } else {
        repliesCopy.unshift(reply);
      }
    }
    let commentsCopy = [...comments];
    let comment = commentsCopy[index];
    if (comment) {
      let existingRepliesCount = comment['repliesCount'] ?? 0;
      comment['replies'] = repliesCopy;
      comment['repliesCount'] = existingRepliesCount + 1;
      commentsCopy[index] = comment;
      setComments([...commentsCopy]);
    }
  }

  const deleteReply = (postId, replies, reply, index) => {
    let originalReplies = [...(replies ?? [])]
    let repliesCopy = [...(replies ?? [])];
    let replyIndex = repliesCopy.findIndex((entry) => reply._id === entry._id);
    let deleted = false;
    if (replyIndex !== -1) {
      repliesCopy.splice(replyIndex, 1);
      deleted = true;
    }
    let commentsCopy = [...comments];
    let comment = commentsCopy[index];
    if (comment) {
      comment['replies'] = repliesCopy;
      if (deleted) {
        let existingRepliesCount = comment['repliesCount'] ?? 0;
        comment['repliesCount'] = Math.max(existingRepliesCount - 1, 0);
      }
      commentsCopy[index] = comment;
      setComments([...commentsCopy]);
    }
    APIService.deleteComment(postId, reply._id, (_response, err) => {
      if (err) {
        commentsCopy[index]['replies'] = originalReplies;
        setComments([...commentsCopy]);
        toast.error(err, { theme: 'colored' });
        return;
      }
    });
  }

  const fetchCommentReplies = ({ postId, commentId, replies = [] }) => {
    return new Promise((resolve, reject) => {
      APIService.fetchCommentReplies({ postId, commentId, skip: replies.length }, (response, error) => {
        if (error) {
          reject(error);
          return;
        }
        let { data } = response;
        let commentsCopy = [...comments];
        let commentIndex = commentsCopy.findIndex(item => item._id === commentId);
        if (commentIndex !== -1) {
          let comment = commentsCopy[commentIndex]
          const combined = [...comment.replies, ...data];
          const repliesMap = new Map(combined.map(reply => [reply._id, reply]));
          const replies = Array.from(repliesMap.values());
          comment['replies'] = replies;
          commentsCopy[commentIndex] = comment;
          setComments([...commentsCopy]);
          resolve(data);
        }
      });
    });
  }

  const fetchMoreComments = () => {
    setFetchingMoreComments(true);
    const postId = others.postId;
    APIService.fetchPostComments({ postId, skip: comments.length }, (response, error) => {
      setFetchingMoreComments(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let { data } = response;
      const combined = [...comments, ...data];
      const commentsMap = new Map(combined.map(comment => [comment._id, comment]));
      const newComments = Array.from(commentsMap.values());
      setComments([...newComments]);
    })
  }

  return (
    <>
      {(comments ?? []).map((comment, index) => (
        <Flex direction={'column'} key={comment._id}>
          <Comment
            index={index}
            onNewReplyUpserted={onNewReplyUpserted}
            deleteReply={deleteReply}
            deleteComment={deleteComment}
            toggleCommentLike={toggleCommentLike}
            toggleReplyLike={toggleReplyLike}
            fetchCommentReplies={fetchCommentReplies}
            postId={comment.post}
            {...comment}
            setComments={setComments}
          />
          {
            index !== comments.length - 1 &&
            <hr className="text-200 mt-3" style={{ margin: 0 }} />
          }
        </Flex>
      ))}
      {
        fetchingMoreComments ?
          <Flex
            alignItems={'center'}
            variant="light"
            className="text-500 py-2"
          >
            <PulseLoader size={5} color="#2c7be5" />
            <span> {t('loading')}</span>
          </Flex> :
          <>
            {commentsCount > comments.length && (
              <span onClick={fetchMoreComments} className="fs-10 text-700 d-inline-block mt-2 cursor-pointer">
                {t('load_more_comments')} {otherCommentsCount > 0 && `(${comments.length} of ${commentsCount})`}
              </span>
            )}
          </>
      }
    </>
  );
};

export default Comments;
