/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import queryString from 'query-string';
import { omit } from 'lodash';
import { useLocation } from 'react-router-dom';
import React from 'react';
import getUrls from 'get-urls';
import APIService from 'http/api_service';

export const queryStringToObject = (str, options = {}) =>
  queryString.parse(str, {
    arrayFormat: 'bracket',
    ...options,
  });

export const objectToQueryString = (obj, options = {}) =>
  queryString.stringify(obj, {
    arrayFormat: 'bracket',
    ...options,
  });

export const omitFromQueryString = (str, keys) =>
  objectToQueryString(omit(queryStringToObject(str), keys));

export const addToQueryString = (str, fields) =>
  objectToQueryString({
    ...queryStringToObject(str),
    ...fields,
  });

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const replaceLast = (parent, search, replace) => {
  return parent.replace(new RegExp(search + "([^" + search + "]*)$"), replace + "$1");
}

export const hasPopupBlocker = (poppedWindow) => {
  return poppedWindow == null || typeof (poppedWindow) == 'undefined';
}

export const detectBrowser = () => {
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera';
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return 'Firefox';
  } else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
    return 'IE';
  } else {
    return 'Unknown';
  }
}

export const recommendUrlToUnblockPopup = () => {
  let currentBrowser = detectBrowser();
  let url;
  switch (currentBrowser) {
    case 'Opera':
      url = 'https://browserhow.com/how-to-allow-or-block-pop-ups-and-redirects-on-opera/#:~:text=Click%20to%20open%20the%20Site,ups%20and%20redirect%20on%20Opera';
      break;
    case 'Chrome':
      url = 'https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop';
      break;
    case 'Safari':
      url = 'https://support.apple.com/en-ng/guide/safari/sfri40696/mac';
      break;
    case 'Firefox':
      url = 'https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting';
      break;
    default:
      url = null;
  }
  if (url) {
    return `Kindly follow this link to <a href=${url} style='color:#29B6F6;'>Disable Popup Blocker in ${currentBrowser}</a>`;
  }
  return url;
}

export const generateQueryFromFilterOptions = (filterOptions) => {
  if (!filterOptions) {
    console.log(`No filter options received`);
    return '';
  }
  let queryString = ''
  Object.entries(filterOptions).forEach((filter, index) => {
    queryString += `${filter[0]}=${encodeURIComponent(filter[1])}&`;
  });
  queryString = replaceLast(queryString, '&', '');
  return queryString;
}

export const extractLinksFromTweet = (tweet, retainProtocolStatus = false) => {
  let urls = getUrls(tweet, {
    normalizeProtocol: true,
    removeTrailingSlash: false,
    stripWWW: false,
  });
  if (retainProtocolStatus && urls) {
    let urlArr = Array.from(urls)
    const tweetArrays = tweet.split(/\s+|\n+/)
    const replacedUrls = [];
    for (let index = 0; index < tweetArrays.length; index++) {
      const t = tweetArrays[index];
      const indexInUrls = urlArr.findIndex(url => url.includes(t));
      if (indexInUrls !== -1) {
        replacedUrls.push(t);
      } else {
        if (urlArr[indexInUrls]) {
          replacedUrls.push(urlArr[indexInUrls]);
        }
      }
    }
    urlArr = replacedUrls;
    return urlArr;
  }

  if (urls) {
    return Array.from(urls);
  }
  return [];
}

export const getPreviewFromUrl = async (url) => {
  if (!url) {
    return null
  }
  try {
    let requestBody = {
      url
    }
    return new Promise((resolve, reject) => {
      APIService.getLinkPreview(requestBody, (response, error) => {
        if (error) {
          reject(error);
          return
        }
        resolve(response);
      });
    })
  } catch (error) {
  }
}

export const urlPathIsHexaDecimal = (str) => /^[0-9a-fA-F]{24}$/.test(str);
