import React, { useState } from 'react';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'react-bootstrap';
import SubmitButton from 'components/common/SubmitButton';
import SwitchEmail from 'components/authentication/simple/EmailSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from 'providers/AppProvider';
import useUserStore from 'store/userStore';
import { useNavigate } from 'react-router-dom';
import APIService from 'http/api_service';
import { rootPaths } from 'routes/paths';
import { toast } from 'react-toastify';

const UpdateContentAndOrProfilePreferences = () => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const [showOptions, setShowOptions] = useState({});
  const interests = [
    {
      name: t('find_love'),
      options_title: `${t('you')} ${t('are')} ${authenticatedUser.gender}`,
      options_subTitle: t('pick_gender_you_would_love_to_connect_with'),
      options: [t('male'), t('female')]
    },
    {
      name: t('make_friends')
    },
    {
      name: t('find_mentors')
    },
    {
      name: t('make_money_as_a_creator')
    },
    {
      name: t('find_work')
    },
    {
      name: t('find_businesses_to_partner_with'),
      premium: true
    },
    {
      name: t('hire_freelancers')
    },
    {
      name: t('mentor_others')
    },
    {
      name: t('grow_your_business')
    },
    {
      name: t('join_book_clubs')
    },
    {
      name: t('organize_events_and_meetups')
    },
    {
      name: t('collaborate_on_projects')
    },
    {
      name: t('find_study_buddies')
    }
  ];

  const [formData, setFormData] = useState(
    (authenticatedUser?.preferences?.discovery ?? []).map(discovery => {
      return {
        ...discovery,
        selectedOptions: discovery.options,
        options: undefined
      };
    })
  );

  const {
    config: { isDark }
  } = useAppContext();
  const setAuthenticatedUser = useUserStore(
    state => state.setAuthenticatedUser
  );
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(true);

  const normalizedFormData = () => {
    let preferences = {};
    let data = formData.map(data => {
      let newData = { name: data.name };
      if (
        data.selectedOptions &&
        data.selectedOptions.every(
          option => option !== null && option !== undefined
        )
      ) {
        newData['options'] = data.selectedOptions;
      }
      newData['options'] = newData['options'] ?? [];
      return newData;
    });
    preferences['discovery'] = data;
    return preferences;
  };

  const handleInterestClick = interest => {
    if ((interest.options ?? []).length > 0) {
      setShowOptions(prevState => ({ ...prevState, [interest.name]: true }));
      setOpenModal(true);
    } else {
      toggleInterest(interest);
    }
  };

  const toggleInterest = (interest, selectedOption) => {
    setFormData(prevData => {
      const index = prevData.findIndex(item => item.name === interest.name);
      if (index !== -1) {
        const newData = [...prevData];
        const existingInterest = newData[index];
        if (interest.options) {
          let selectedOptions = [...(existingInterest.selectedOptions || [])];
          if (selectedOptions.includes(selectedOption)) {
            selectedOptions = selectedOptions.filter(
              option => option !== selectedOption
            );
          } else {
            selectedOptions.push(selectedOption);
          }
          if (selectedOptions.length === 0) {
            newData.splice(index, 1);
          } else {
            newData[index] = { ...interest, selectedOptions: selectedOptions };
          }
        } else {
          newData.splice(index, 1);
        }
        return [...newData];
      } else {
        return [
          ...prevData,
          { ...interest, selectedOptions: [selectedOption] }
        ];
      }
    });
  };

  const handleSubOptionClick = (interest, subOption) => {
    toggleInterest(interest, subOption);
    setShowOptions(prevState => ({ ...prevState, [interest.name]: false }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    setProcessing(true);
    APIService.updateAccountDetails(
      { preferences: normalizedFormData() },
      (response, err) => {
        setProcessing(false);
        if (err) {
          toast.error(err);
          return;
        }
        let { data } = response;
        setAuthenticatedUser(data);
        navigate(rootPaths.root);
      }
    );
  };

  return (
    <>
      <Flex
        justifyContent="between"
        direction={'column'}
        alignItems="center"
        className="mb-2"
      >
        <h5>{t('content_and_or_profile_preferences')}</h5>
        <p>
          {t('what_would_you_like_to_do_on_app', { app_name: t('app_name') })}
        </p>
      </Flex>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-4">
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            className={'gap-1'}
            style={{
              flexWrap: 'wrap'
            }}
          >
            {interests.map(interest => {
              return (
                <React.Fragment key={interest.name}>
                  <Button
                    onClick={() => handleInterestClick(interest)}
                    variant={
                      formData.some(item => item.name === interest.name)
                        ? isDark
                          ? 'light'
                          : 'dark'
                        : `outline-${isDark ? 'light' : 'dark'}`
                    }
                    className="rounded-pill me-1 mb-1"
                  >
                    <Flex
                      alignItems={'center'}
                      justifyContent={'center'}
                      className={'gap-1'}
                    >
                      <span>{interest.name}</span>
                      {formData.some(item => item.name === interest.name) && (
                        <FontAwesomeIcon icon={faCheckCircle} />
                      )}
                    </Flex>
                  </Button>
                  {showOptions[interest.name] && (
                    <Modal show={openModal} centered>
                      <Modal.Header>
                        <Modal.Title>{interest.options_title}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>{interest.options_subTitle}</p>
                        <Flex
                          alignItems={'center'}
                          justifyContent={'center'}
                          className={'gap-1'}
                        >
                          {interest.options.map(option => (
                            <Button
                              key={option}
                              onClick={() =>
                                handleSubOptionClick(interest, option)
                              }
                              variant={
                                formData.some(
                                  item =>
                                    item.name === interest.name &&
                                    item.selectedOptions.includes(option)
                                )
                                  ? isDark
                                    ? 'light'
                                    : 'dark'
                                  : `outline-${isDark ? 'light' : 'dark'}`
                              }
                              className="rounded-pill me-1 mb-1"
                            >
                              <span>{option}</span>
                              {formData.some(
                                item =>
                                  item.name === interest.name &&
                                  item.selectedOptions.includes(option)
                              ) && <FontAwesomeIcon icon={faCheckCircle} />}
                            </Button>
                          ))}
                        </Flex>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          onClick={() => {
                            setOpenModal(false);
                          }}
                        >
                          {t('done')}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}
                </React.Fragment>
              );
            })}
          </Flex>
        </Form.Group>
        <Form.Group className="mb-4">
          <SubmitButton
            className="w-100"
            disabled={(formData ?? []).length < 1}
            processing={processing}
            label={t('next')}
          />
        </Form.Group>
      </Form>
      <SwitchEmail />
    </>
  );
};

export default UpdateContentAndOrProfilePreferences;
