/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Col, Dropdown, Modal,
  OverlayTrigger,
  Row, Tooltip
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import usePostsStore from 'store/posts_store';
import APIService from 'http/api_service';
import SubtleBadge from 'components/common/SubtleBadge';
import { abbreviateNumber } from 'utils/user_utils';
import useUserStore from 'store/userStore';
import {
  ShareFat,
  Heart,
  ChatCircle,
  CurrencyCircleDollar
} from "@phosphor-icons/react";
import TipContent from './TipContent';
import paths from 'routes/paths';
import { toast } from 'react-toastify';

const FeedActionButtons = ({ _id, author,
  likesCount = 0, commentsCount = 0,
  sharesCount = 0, likes = [],
  handleCommentBoxToggle, openCommentBox,
  ...others
}) => {

  const { t } = useTranslation();
  const liked = others.liked;
  const posts = usePostsStore(state => state.posts);
  const setPosts = usePostsStore(state => state.setPosts);
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const [openTipModal, setOpenTipModal] = useState(false);

  const getNamesOfLikers = () => {
    return likes.map(like =>
      like?.user?._id === authenticatedUser?._id
        ? t('you')
        : `${like?.user?.first_name} ${like?.user?.last_name}`
    );
  }

  const toggleLiked = () => {
    const postsCopy = [...posts];
    let postIndex = postsCopy.findIndex(post => post._id === _id);
    if (postIndex !== -1) {
      let val = !liked;
      let count = postsCopy[postIndex].likesCount ?? 1;
      postsCopy[postIndex].liked = val;
      postsCopy[postIndex].likesCount = val ? count + 1 : count - 1;
      let likesCopy = [...likes];
      let indexOfAuthenticatedLiker = likesCopy.findIndex(like => like.user._id === authenticatedUser._id);
      if (!val) {
        if (indexOfAuthenticatedLiker != -1) {
          likesCopy.splice(indexOfAuthenticatedLiker, 1);
        }
      } else {
        if (indexOfAuthenticatedLiker === -1) {
          likesCopy.unshift({ user: authenticatedUser });
        }
      }
      postsCopy[postIndex].likes = likesCopy;
      setPosts([...postsCopy]);
    }
    APIService.likeOrUnLikePost(_id, (response, err) => {
      if (err) {
        console.log(err);
        if (postIndex !== -1) {
          postsCopy[postIndex].liked = !liked;
          postsCopy[postIndex].likes = likes;
          postsCopy[postIndex].likesCount = likesCount + 1;
          setPosts([...postsCopy]);
        }
        return;
      }
      let { data } = response;
      let updatedPostsCopy = [...posts];
      let updatedPostIndex = updatedPostsCopy.findIndex(post => post._id === _id);
      if (updatedPostIndex !== -1) {
        updatedPostsCopy[updatedPostIndex] = data;
        setPosts([...updatedPostsCopy]);
      }
    });
  };

  return (
    <Flex
      onKeyDown={(e) => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      alignItems={'center'}>
      <Row className="g-3 fw-semibold text-center py-2 fs-10">
        <Col xs="auto">
          <OverlayTrigger
            overlay={
              likesCount > 0 ?
                <Tooltip
                  style={{ position: 'fixed' }}
                  id="overlay-trigger-for-likers"
                >
                  <Flex
                    direction={'column'}
                    className={'gap-1'}
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}
                  >
                    {getNamesOfLikers().map(name => {
                      return <span key={name}>{name}</span>;
                    })}
                  </Flex>
                </Tooltip>
                : <div></div>
            }
          >
            <span>
              <Flex
                alignItems="center"
                className="rounded text-700 cursor-pointer"
                onClick={toggleLiked}
              >
                <Heart
                  key={`${_id}-like`}
                  weight={liked ? 'fill' : 'regular'}
                  style={{
                    width: 20,
                    height: 20
                  }}
                  className={liked ? 'text-danger' : ''}
                />
                <span className={`ms-1`}>
                  {likesCount > 0 ? abbreviateNumber(likesCount) : ''}
                </span>
              </Flex>
            </span>
          </OverlayTrigger>
        </Col>
        <Col xs="auto">
          <OverlayTrigger overlay={others?.commented ? <Tooltip>
            {t('you_commented_on_this_post')}
          </Tooltip> : <div></div>}>
            <span>
              <Flex
                alignItems="center"
                className="rounded text-700 cursor-pointer"
                onClick={(e) => {
                  handleCommentBoxToggle(!openCommentBox);
                }}
              >
                <ChatCircle
                  weight={others.commented ? 'fill' : 'regular'}
                  style={{
                    width: 20,
                    height: 20
                  }}
                />
                <span
                  className="ms-1">
                  {
                    commentsCount > 0 ? abbreviateNumber(commentsCount) : ''
                  }
                </span>
              </Flex>
            </span>
          </OverlayTrigger>
        </Col>
        <Col xs="auto">
          <Dropdown
            className="font-sans-serif btn-reveal-trigger"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            style={{
              background: 'transparent'
            }}>
            <Dropdown.Toggle
              variant="link"
              size="sm"
              className="p-0 m-0 text-600 btn-reveal"
              style={{
                background: 'transparent',
                border: 'none',
                outline: 'none'
              }}
            >
              <Flex
                alignItems="center"
                className="rounded text-700 cursor-pointer"
              >
                <ShareFat
                  weight={others.shared ? 'fill' : 'regular'}
                  style={{
                    width: 20,
                    height: 20
                  }}
                />
                <span className={`ms-${sharesCount > 0 ? 1 : 0}`}>
                  {
                    sharesCount > 0 ? abbreviateNumber(sharesCount) : ''
                  }
                </span>
              </Flex>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item
                onClick={() => {
                  let link = `${window.location.origin}${paths.feed}/${_id}`;
                  navigator.clipboard.writeText(link).then(() => {
                    toast.warn('Link copied to clipboard', { theme: 'dark' });
                  }).catch(_err => {
                    toast.error('Failed to copy link', { theme: 'colored' });
                  });
                }}
              >
                {t('copy_link')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        {
          author._id !== authenticatedUser._id &&
          <Col xs="auto">
            <Flex
              alignItems="center"
              className="rounded text-700 cursor-pointer"
              onClick={(e) => {
                setOpenTipModal(true);
              }}
            >
              <CurrencyCircleDollar
                style={{
                  width: 22,
                  height: 22
                }}
              />
            </Flex>
          </Col>
        }
      </Row>
      <Flex
        className={'ms-0 ps-0 gap-1'}
        alignItems={'center'}
        style={{
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          flex: 1
        }}
      >
        <Flex alignItems={'center'}>
          {[...others.topics.slice(0, 1)].map(topic => {
            return (
              <OverlayTrigger
                key={topic}
                overlay={<Tooltip>{others.topics.length > 1 ? others.topics.slice(1).map(topic => (<p>{topic}</p>)) : t('post_published_to_topic', { topic: topic })}</Tooltip>}>
                <span className='cursor-pointer'>
                  <SubtleBadge pill bg={'info'} className="me-1" key={topic}>
                    {`${topic}${others.topics.length > 1 ? `+${others.topics.length - 1}` : ''}`}
                  </SubtleBadge>
                </span>
              </OverlayTrigger>
            );
          })}
        </Flex>
      </Flex>
      <Modal
        centered
        show={openTipModal}
        onHide={() => setOpenTipModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className='m-0' style={{ fontSize: '1.2rem' }}>{t('send_tip')}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TipContent tippee={author} />
        </Modal.Body>
      </Modal>
    </Flex>
  );
};

export default FeedActionButtons;
