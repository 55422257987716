/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import invite from 'assets/img/icons/spot-illustrations/41.png';
import gmail from 'assets/img/logos/gmail.png';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import InvitePeopleModal from './InvitePeopleModal';

const InvitePeople = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCopy = () => {
    setShowModal(true);
  };

  return (
    <>
      <Card className="shadow-none border border-200">
        <Card.Body className="text-center">
          <Row className="justify-content-center">
            <Col xs={7} md={5}>
              <img className="img-fluid" src={invite} alt="image" width="58%" />
            </Col>
          </Row>
          <h3 className="mt-3 fw-normal fs-7">Invite your Peers</h3>
          <p className="mb-3 fs-10">
            Invite your peers and colleagues to join Publishable with your
            unique invite link.
          </p>
          <Row className="justify-content-center">
            <Col md={12}>
              <Form as={Row} className="g-1">
                <Col sm>
                  <Form.Control
                    type="email"
                    placeholder="Email address"
                    aria-label="Recipient's username"
                  />
                </Col>
                <Col sm="auto">
                  <Button variant="primary" className="d-block w-100">
                    Invite
                  </Button>
                </Col>
              </Form>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-body-tertiary text-center pt-4">
          <Row className="justify-content-center">
            <Col xs={12}>
              <p className="fw-normal mb-3 fs-8">
                More ways to invite your peers
              </p>
              <Row className="g-2">
                <Col lg={12}>
                  <IconButton
                    className="d-block w-100 mb-2"
                    iconClassName="me-2"
                    variant="falcon-default"
                    icon="link"
                    onClick={handleCopy}
                  >
                    Copy Link
                  </IconButton>
                </Col>
                {/* <Col lg={12}>
                  <IconButton
                    className="d-block w-100"
                    variant="falcon-default"
                    icon={['fab', 'facebook-square']}
                    iconClassName="text-facebook me-2"
                    transform="grow-2"
                  >
                    Share
                  </IconButton>
                </Col> */}
              </Row>

              {/* <p className="mb-2 fs-10">
                Once you’ve invited friends, you can{' '}
                <Link to="#!">view the status of your referrals</Link>{' '}
                <br className="d-none d-lg-block d-xxl-none" /> or visit our{' '}
                <Link to="#!">Help Center</Link> if you have any questions.{' '}
              </p> */}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <InvitePeopleModal show={showModal} setShow={setShowModal} />
    </>
  );
};

export default InvitePeople;
