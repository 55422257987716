import React, { useState } from 'react';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import SubmitButton from 'components/common/SubmitButton';
import SwitchEmail from 'components/authentication/simple/EmailSwitch';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import useUserStore from 'store/userStore';
import { useNavigate } from 'react-router-dom';
import { rootPaths } from 'routes/paths';

const UpdateName = () => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: ''
  });
  const setAuthenticatedUser = useUserStore(
    state => state.setAuthenticatedUser
  );
  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    setProcessing(true);
    APIService.updateAccountDetails(formData, (response, err) => {
      setProcessing(false);
      if (err) {
        toast.error(err);
        return;
      }
      let { data } = response;
      setAuthenticatedUser(data);
      navigate(rootPaths.root);
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Flex
        justifyContent="between"
        direction={'column'}
        alignItems="center"
        className="mb-2"
      >
        <h5>{t('welcome_onboard')}</h5>
        <p>{t('what_do_we_call_you')}</p>
      </Flex>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Control
            placeholder={t('first_name')}
            value={formData.first_name}
            name="first_name"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            placeholder={t('last_name')}
            value={formData.last_name}
            name="last_name"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <SubmitButton
            className="w-100"
            disabled={!formData.first_name || !formData.last_name}
            processing={processing}
            label={t('submit')}
          />
        </Form.Group>
      </Form>
      <SwitchEmail />
    </>
  );
};

export default UpdateName;
