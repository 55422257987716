/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import SubtleBadge from 'components/common/SubtleBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFullName, getUsername } from 'utils/user_utils';

const PitchedMediaRequests = ({ mediaRequest }) => {
  return (
    <div className="d-flex flex-column gap-3 mt-2">
      <div
        className="bg-white dark__bg-1100 d-md-flex d-xl-inline-block d-xxl-flex align-items-center p-x1 rounded-3 shadow-sm card-view-height w-100"
      >
        <div className="d-flex align-items-start align-items-sm-center">
          <Link to={`/${getUsername(mediaRequest?.created_by)}`} className="d-none d-sm-block">
            {mediaRequest.avatar ? (
              <Avatar src={mediaRequest.avatar} size="3xl" />
            ) : (
              <Avatar size="3xl" name={getFullName(mediaRequest?.created_by)} />
            )}
          </Link>
          <div className="ms-1 ms-sm-3">
            <p className="fw-semibold mb-3 mb-sm-2">
              <Link to={`/${getUsername(mediaRequest?.created_by)}`}>
                {getFullName(mediaRequest?.created_by)}
              </Link>
            </p>
            <Row className="align-items-center gx-0 gy-2">
              <Col xs="auto" className="me-2">
                <h6 className="mb-0">
                  <Link
                    to={`/${getUsername(mediaRequest?.created_by)}`}
                    className="text-800 d-flex align-items-center gap-1"
                  >
                    <FontAwesomeIcon
                      icon="user"
                      transform="shrink-3 up-1"
                    />
                    <span>{mediaRequest.name}</span>
                  </Link>
                </h6>
              </Col>
              {/* <Col xs="auto" className="lh-1 me-3">
                <SubtleBadge bg={mediaRequest?.status.type}>{mediaRequest?.status.content}</SubtleBadge>
              </Col> */}
            </Row>
          </div>
        </div>
        <h4 className="mb-2 text-primary">{mediaRequest?.title}</h4>
        <p className="mb-0 text-500">{mediaRequest?.question}</p>
        <div className="border-bottom border-200 mt-4 mb-x1"></div>
        <div className="d-flex justify-content-between ms-auto">
        </div>
      </div>
    </div>
  );
};

export default PitchedMediaRequests;
