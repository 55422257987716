/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { Form, ProgressBar } from 'react-bootstrap';
import SubmitButton from 'components/common/SubmitButton';
import SwitchEmail from 'components/authentication/simple/EmailSwitch';
import APIService from 'http/api_service';
import useUserStore from 'store/userStore';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import Avatar from 'components/common/Avatar';
import { rootPaths } from 'routes/paths';

const UpdatePhoto = () => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const setAuthenticatedUser = useUserStore(
    state => state.setAuthenticatedUser
  );
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [photoPreview, setPhotoPreview] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      readFileAsDataURL(acceptedFiles[0])
        .then(result => {
          setPhotoPreview(result);
        })
        .catch(error => {
          console.error('Error reading file:', error);
        });
    }
  }, [acceptedFiles]);

  const handleSubmit = async e => {
    e.preventDefault();
    setUploading(true);
    try {
      let response = await APIService.uploadFile(acceptedFiles[0], {
        onUploadProgress: progress => {
          setUploadProgress(progress);
        }
      });
      setUploading(false);
      setProcessing(true);
      const { success, result } = response;
      if (success) {
        let uploadedPhotoUrl = (result.variants ?? [])[0];
        if (uploadedPhotoUrl) {
          APIService.updateAccountDetails(
            { avatar: uploadedPhotoUrl },
            (response, err) => {
              setProcessing(false);
              if (err) {
                toast.error(err);
                return;
              }
              let { data } = response;
              setAuthenticatedUser(data);
              navigate(rootPaths.root);
            }
          );
        }
        return;
      }
      toast.error(t('photo_upload_failed'), { theme: 'colored' });
    } catch (err) {
      setProcessing(false);
      setUploading(false);
      toast.error(err, { theme: 'colored' });
    }
  };

  const readFileAsDataURL = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Flex
        justifyContent="between"
        direction={'column'}
        alignItems="center"
        className="mb-2"
      >
        <h5>{t('photo')}</h5>
        <p>{t('share_top_photos')}</p>
      </Flex>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-4">
          <div {...getRootProps({ className: 'dropzone-area py-6' })}>
            <input {...getInputProps({ multiple: false })} />
            <Flex justifyContent="center">
              <img src={cloudUpload} alt="" width={25} className="me-2" />
              <p className="fs-9 mb-0 text-700">
                {t('photo_upload_instruction')}
              </p>
              {acceptedFiles.length > 0 && (
                <FalconLightBoxGallery images={[photoPreview]}>
                  {setImgIndex => (
                    <Avatar
                      src={photoPreview}
                      className="status-online"
                      size="4xl"
                      onClick={e => {
                        e.stopPropagation();
                        setImgIndex(0);
                      }}
                    />
                  )}
                </FalconLightBoxGallery>
              )}
            </Flex>
          </div>
        </Form.Group>
        <Form.Group className="mb-4">
          {uploading ? (
            <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
          ) : (
            <SubmitButton
              className="w-100"
              disabled={acceptedFiles.length < 1}
              processing={processing}
              label={t('submit')}
            />
          )}
        </Form.Group>
      </Form>
      <SwitchEmail />
    </>
  );
};

export default UpdatePhoto;
