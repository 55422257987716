import freeIcon from 'assets/img/icons/free.svg';
import proIcon from 'assets/img/icons/pro.svg';

export const pricingData = [
  {
    id: 0,
    title: 'Single',
    subTitle: 'For teams that need to create project plans with confidence.',
    price: 0,
    url: '/e-commerce/billing',
    buttonText: 'Start free trial',
    isFeatured: false,
    featureTitle: 'Track team projects with free:',
    features: [
      { id: 1, title: 'Timeline' },
      { id: 2, title: 'Advanced Search' },
      { id: 3, title: 'Custom fields', tag: { label: 'New', type: 'success' } },
      { id: 4, title: 'Task dependencies' },
      { id: 5, title: 'Private teams & projects' }
    ]
  },
  {
    id: 1,
    title: 'Business',
    subTitle:
      'For teams and companies that need to manage work across initiatives.',
    price: 39,
    url: '/e-commerce/billing',
    buttonText: 'Get Business',
    isFeatured: true,
    featureTitle: 'Everything in Premium, plus:',
    features: [
      { id: 1, title: 'Portfolios' },
      { id: 2, title: 'Lock custom fields' },
      { id: 3, title: 'Onboarding plan' },
      { id: 4, title: 'Resource Management' },
      { id: 5, title: 'Lock custom fields' }
    ]
  },
  {
    id: 2,
    title: 'Extended',
    subTitle: 'For organizations that need additional security and support.',
    price: 99,
    url: '/e-commerce/billing',
    buttonText: 'Purchase',
    isFeatured: false,
    featureTitle: 'Everything in Business, plus:',
    features: [
      { id: 1, title: 'Portfolios' },
      { id: 2, title: 'Tags', tag: { label: 'Coming soon', type: 'primary' } },
      { id: 3, title: 'Onboarding plan' },
      { id: 4, title: 'Resource Management' }
    ]
  }
];

export const pricingAltData = [
  {
    id: 0,
    title: 'Pro',
    price: {
      monthly: 49,
      yearly: 39
    },
    period: 'm',
    icon: freeIcon,
    url: '#!',
    buttonText: 'Upgrade to Pro',
    isFeatured: false,
    featureTitle: '',
    features: [
      {
        id: 0,
        title:
          'Submit unlimited pitches to media requests from 1,000+ publications',
        isActive: true
      },
      {
        id: 1,
        title: 'No response delay to opportunities',
        isActive: true
      },
      {
        id: 2,
        title: 'Daily opportunities email',
        isActive: true
      },
      {
        id: 3,
        title: 'Opportunity search',
        isActive: true
      }
    ]
  },

  {
    id: 1,
    title: 'Business',
    price: {
      monthly: 99,
      yearly: 79
    },
    period: 'm',
    icon: proIcon,
    url: '#!',
    buttonText: 'Upgrade to Business',
    isFeatured: true,
    featureTitle: 'Everything in Free, plus:',
    features: [
      {
        id: 0,
        title:
          'Submit unlimited pitches to media requests from 1,000+ publications',
        // body: 'Earn up to $4 for every 1,000 views on your long posts',
        isActive: true
      },
      {
        id: 1,
        title: 'No response delay to opportunities',
        // body: 'Earn a percentage of the ad revenue generated from ads shown on your content. The more views, the higher the earnings',
        isActive: true
      },
      {
        id: 2,
        title: 'Daily opportunities email',
        // body: 'Allow your audience to send you tips or donations',
        isActive: true
      },
      {
        id: 3,
        title: 'Opportunity search',
        // body: 'Opportunity search',
        isActive: true
      }
    ]
  }
];
