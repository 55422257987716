/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import PlansSubscriptionCard from './business/PlansSubscriptionCard';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { RoutePaths } from '../../../constants';
import { Checkmark } from 'react-checkmark';
import { useQuery } from 'utils/url';
import useUserStore from 'store/userStore';

const PricingDefault = () => {
  const [upgradingIndex, setupgradingIndex] = useState(-1);
  const [paymentForNewWorkspace] = useState(false);
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const [activePlan, setActivePlan] = useState(authenticatedUser.active_plan);
  const [openCheckoutCompletedPopup, setOpenCheckoutCompletedPopup] =
    useState(false);
  const [checkoutCompletedMessage, setCheckoutCompletedMessage] = useState();
  const [processingPayment, setProcessingPayment] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();

  const refreshauthenticatedUserData = () => {
    APIService.fetchLatestMe((latestMeResponse, error) => {
      if (error) {
        toast.error(error, { theme: 'colored' });
        if (
          error
            .toLowerCase()
            .includes('Invalid credentials provided'.toLowerCase())
        ) {
          window.localStorage.clear();
          setTimeout(() => {
            navigate(RoutePaths.AUTHENTICATION);
          }, 100);
        }
        return;
      }
      let latestUserData = latestMeResponse.data;
      setActivePlan(latestUserData.active_plan);
    });
  };

  const handleSubscription = (item, index) => {
    setupgradingIndex(index);
    APIService.billUser(
      paymentForNewWorkspace,
      null,
      false,
      item.plan,
      (response, error) => {
        if (error) {
          setupgradingIndex(-1);
          toast.error(error);
          return;
        }
        let { data } = response;
        window.location = data;
        setupgradingIndex(-1);
      }
    );
  };

  useEffect(() => {
    let paymentFor = query.get('payment_for');
    if (paymentFor) {
      try {
        let data = JSON.parse(decodeURIComponent(paymentFor));
        let { pricingData, coupon, user } = data;
        if (coupon) {
          pricingData.coupon = coupon;
        }
        if (pricingData) {
          console.log(pricingData);
          handleSubscription(pricingData, user);
        }
      } catch (e) {
        /* empty */
      }
    }
  }, [query]);

  useEffect(() => {
    refreshauthenticatedUserData();
  }, []);

  return (
    <Card className=" shadow-none">
      <Row className="g-3">
        <Col lg={8}>
          <PlansSubscriptionCard />
        </Col>
        <Col lg={4}>
        </Col>
      </Row>

      <Modal
        show={openCheckoutCompletedPopup}
        onHide={() => {
          setOpenCheckoutCompletedPopup(false);
          navigate(RoutePaths.DEFAULT);
          window.location.reload();
        }}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Subscription Successful!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex
            alignItems={'center'}
            className={'gap-2'}
            justifyContent={'center'}
            direction={'column'}
          >
            <Checkmark size="96px" />
            <span>{checkoutCompletedMessage}</span>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setOpenCheckoutCompletedPopup(false);
              navigate(RoutePaths.DEFAULT);
              window.location.reload();
            }}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={processingPayment}
        onHide={() => {
          setProcessingPayment(false);
          toast.info('Your new plan will reflect after verification', {
            theme: 'colored'
          });
        }}
        onExit={() => {
          setProcessingPayment(false);
        }}
        centered
      >
        <Modal.Body>
          <Flex alignItems={'center'} justifyContent={'center'}>
            <Flex
              className={'gap-2'}
              alignItems={'center'}
              justifyContent={'center'}
              direction={'column'}
            >
              <Spinner animation="border" />
              <span>Verifying. Please wait...</span>
            </Flex>
          </Flex>
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default PricingDefault;
