/* eslint-disable no-unused-vars */
import React from 'react';
import { useAppContext } from 'providers/AppProvider';
// import dashboardDark from 'assets/img/generic/dashboard-alt-dark.png';
// import dashboard from 'assets/img/generic/dashboard-alt.jpg';
import Section from 'components/common/Section';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useUserStore from 'store/userStore';
import BusinessesForSale from 'components/pages/landing/BusinessesForSale';
import Testimonial from './Testimonial';
import { courseFeatures } from 'data/elearning/courseDetails';
import FeaturesPage from './FeaturesPage';

const Hero = () => {
  const {
    config: { isDark, isRTL }
  } = useAppContext();
  const { t } = useTranslation();
  const authenticatedUser = useUserStore(state => state.authenticatedUser);

  return (
    <Section className="py-0 overflow-hidden" position="left bottom" overlay>
      <Row className="mb-6 justify-content-center text-center align-items-center pt-8 pt-lg-8 pb-xl-0">
        <Col lg={10}>
          {/* <h1 className="fw-bold fs-4 fs-sm-4 fs-md-8">
              The only AI{" "}
              <span style={{ color: "#00d27a" }}>customer service solution</span>{" "}
              you need
            </h1>  */}
          <h1 className="fw-bold fs-3 fs-sm-4 fs-md-2">
            <span style={{ color: '#00d27a' }}>Connect</span> with your fans &{' '}
            <span style={{ color: '#00d27a' }}>earn</span> sustainable income ✨
          </h1>
          <p className="lead mt-4">
            Monetize your audience by creating clean, exclusive,
            behind-the-scenes content on Reachable.
          </p>

          {authenticatedUser ? (
            <Link
              className="btn btn-primary border-2x btn-lg mb-3 fs-1"
              to="/dashboard"
            >
              GO TO DASHBOARD
            </Link>
          ) : (
            <>
              <div className="d-flex justify-content-center mt-4">
                <Button
                  tag={Link}
                  to="/pricing"
                  color="primary"
                  size="lg"
                  className="border-2x rounded-pill fs-6 py-2 mr-2 pt-2 pb-2"
                  style={{ marginRight: '8px', fontSize: '32px' }}
                >
                  Sign up for free
                </Button>
                {/* <Button
                    color="outline-primary"
                    size="lg"
                    className="border-2x rounded-pill fs-0 py-2 mr-2"
                  >
                    Talk to sales
                  </Button> */}
              </div>
            </>
          )}
        </Col>
      </Row>
      <Testimonial data={courseFeatures} />
      <br />
      <br />
      <br />
      <BusinessesForSale />
      <br />
      <br />
      <br />
      <FeaturesPage />
      <br />
      <br />
      <br />
      <br />
      {/* <Row className=" justify-content-center text-center align-items-center">
          <Col md={11} lg={9} xl={9} className="align-self-end">
            <Section bg="white">
              <h1 className="mt--2 text-center">Why Swap A Shoutout? </h1>
              <p className="text-center mt-2 mb-4 ml-5 mr-5">
                Shoutout is super simple: You find a business that is relevant to your audience, but not a
                competitor, you give that business owner one of your products and they give you one of theirs. You both
                promote the other's products to your email lists, Instagram stories, or post-checkout pages of your
                e-commerce website. With that, you will get a big batch of new customers. Win-Win for everyone
              </p>
              
              {loggedIn ? (
                <Button
                  tag={Link}
                  to="/dashboard/swap"
                  color="outline-dark"
                  size="lg"
                  className="border-2x rounded-pill mt-4 fs-0 py-2"
                >
                  GO TO DASHBOARD
                </Button>
              ) : (
                <Button
                  tag={Link}
                  to="/register"
                  color="outline-dark"
                  size="lg"
                  className="border-2x rounded-pill mt-4 fs-0 py-2"
                >
                  START FOR FREE
                </Button>
              )}
             
            </Section>
          </Col>
        </Row> */}
      {/* <Modal
        isOpen={showRegistrationModal}
        centered
        toggle={() => setShowRegistrationModal(!showRegistrationModal)}
      >
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Registration />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal> */}
    </Section>
  );
};

export default Hero;
