/* eslint-disable react/prop-types */
import { faList, faListOl } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import React, { useRef, useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const List = ({ editor }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const ref = useRef();
  const listTypes = [
    {
      label: 'Bullet List',
      name: 'bulletList',
      icon: faList,
      onClick: () => editor.chain().focus().toggleBulletList().run()
    },
    {
      label: 'Ordered List',
      name: 'orderedList',
      icon: faListOl,
      onClick: () => editor.chain().focus().toggleOrderedList().run()
    }
  ];

  const handleOnMouseEnter = () => {
    setShow(true);
  };

  const handleOnMouseLeave = () => {
    setShow(false);
  };

  return (
    <OverlayTrigger
      show={show}
      placement="bottom"
      container={ref}
      overlay={
        <Popover
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          <Popover.Body>
            <Flex direction={'column'} className={'gap-2'}>
              {listTypes.map(list => {
                return (
                  <Button
                    key={`list-${list.name}`}
                    size={'sm'}
                    variant={'link'}
                    onClick={() => list.onClick()}
                    className={`text-decoration-none m-0 py-0 px-2 text-600 rounded-2 ${
                      !editor.isActive(list.name) ? 'bg-50' : 'bg-300'
                    }  hover-bg-200`}
                  >
                    <Flex alignItems={'center'} className={'gap-2'}>
                      <FontAwesomeIcon icon={list.icon} className="p-0 m-0" />
                      <span>{list.label}</span>
                    </Flex>
                  </Button>
                );
              })}
            </Flex>
          </Popover.Body>
        </Popover>
      }
    >
      <span
        ref={ref}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        className={`cursor-pointer ${
          listTypes.some(list => editor.isActive(list.name))
            ? 'bg-200 px-2 rounded-4'
            : ''
        }`}
      >
        <Flex alignItems={'center'} className={'gap-2'}>
          <FontAwesomeIcon icon={faList} style={{ width: 14, height: 14 }} />
          <span>{t('list')}</span>
        </Flex>
      </span>
    </OverlayTrigger>
  );
};
export default List;
