/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import SubtleBadge from 'components/common/SubtleBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { faBookmark as faSolidBookmark } from '@fortawesome/free-solid-svg-icons';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import {
    calculateDaysTo,
    formatRelativeTime
} from 'utils/time';
import ContinousExpansionTextView from '../social/feed/ContinuousExpansionTextView';
import Flex from 'components/common/Flex';
import Priority from './Priority';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import IconButton from 'components/common/IconButton';

const MediaRequestsCardLayout = ({ searchTerm, handleShowMediaRequestDetails }) => {

    const { getRowModel } = useAdvanceTableContext();

    const calculateProgressPercentage = (start, end, current) => {
        const startTime = start.getTime();
        const endTime = end.getTime();
        const currentTime = current.getTime();

        if (currentTime < startTime) return 0;
        if (currentTime > endTime) return 100;

        const totalTime = endTime - startTime;
        const elapsedTime = currentTime - startTime;

        const percentage = (elapsedTime / totalTime) * 100;
        return percentage;
    }

    const expired = (created_at, createdAt, expected_delivery_date) => {
        return new Date(created_at ?? createdAt) > new Date(expected_delivery_date) || (new Date() > new Date(expected_delivery_date));
    }

    return (
        <div className="d-flex flex-column gap-2">
            {
                getRowModel()
                    .rows
                    .map((row, index) => {
                        const {
                            created_by: { first_name, last_name, avatar },
                            title,
                            question,
                            status = [],
                            topics,
                            created_at,
                            createdAt = new Date(),
                            expected_delivery_date = new Date(),
                        } = row.original;
                        return (
                            <div
                                key={index}
                                className="bg-white dark__bg-1100 d-md-flex d-xl-inline-block d-xxl-flex align-items-center p-x1 rounded-3 shadow-sm card-view-height w-100 border border-100"
                            >
                                <div className="cursor-pointer" onClick={() => handleShowMediaRequestDetails(row.original)}>
                                    <div className="d-flex align-items-start">
                                        <Link to={"#"} className="d-none d-sm-block">
                                            {avatar ? (
                                                <Avatar src={avatar} size="2xl" />
                                            ) : (
                                                <Avatar size="2xl" name={first_name} />
                                            )}
                                        </Link>
                                        <div className="ms-1 ms-sm-3">
                                            <p className="fw-semibold mb-3 mb-sm-2">
                                                <Link to={"#"}>{title}</Link>
                                            </p>
                                            <Row className="align-items-center gx-0 gy-2">
                                                <Col xs="auto" className="me-2">
                                                    <h6 className="mb-0">
                                                        <Link
                                                            to={"#"}
                                                            className="text-800 d-flex align-items-center gap-1"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon="user"
                                                                transform="shrink-3 up-1"
                                                            />
                                                            <span>{`${first_name} ${last_name}`}</span>
                                                        </Link>
                                                    </h6>
                                                </Col>
                                                <Col xs="auto" className="lh-1 me-3">
                                                    <SubtleBadge className='text-capitalize' bg={'info'}>{status.join(", ").replace(/_/g, " ")}</SubtleBadge>
                                                </Col>
                                                <Col xs="auto">
                                                    <h6 className="mb-0 text-500">{formatRelativeTime(new Date(created_at ?? createdAt))}</h6>
                                                </Col>
                                                <ContinousExpansionTextView
                                                    maxLengthInView={700}
                                                    search={searchTerm}
                                                    text={question}
                                                    inputClassName={expired(created_at, createdAt, expected_delivery_date) ? 'text-500' : 'text-800'}
                                                />
                                                {
                                                    topics &&
                                                    <Flex
                                                        direction={'row'}
                                                        alignItems="center"
                                                        className={'gap-1'}
                                                    >
                                                        {
                                                            topics.map((topic) => {
                                                                return (<SubtleBadge bg='secondary' key={topic}>#{topic}</SubtleBadge>)
                                                            })
                                                        }
                                                    </Flex>
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                    <Flex alignItems={'center'} className={'mt-4 gap-2'}>
                                        <b>Deadline:</b>
                                        <span>{status.includes('no_deadline') ? 'No deadline' : expired(created_at, createdAt, expected_delivery_date) ? 'Expired' : `in ${calculateDaysTo(new Date(created_at ?? createdAt), new Date(expected_delivery_date))} days`}</span>
                                        {
                                            !status.includes('no_deadline') &&
                                            <OverlayTrigger overlay={<Tooltip>{DateTime.fromJSDate(new Date(expected_delivery_date)).toFormat("MMMM, d'th', yyyy 'at' hh:mma")}</Tooltip>}>
                                                <span onClick={(e) => e.stopPropagation()}>
                                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                                </span>
                                            </OverlayTrigger>
                                        }
                                    </Flex>
                                </div>
                                {
                                    !expired(created_at, createdAt, expected_delivery_date) &&
                                    <div className="border-bottom my-2"></div>
                                }
                                <div className="d-flex justify-content-between ms-auto">
                                    {
                                        status.includes('no_deadline') ? <></> :
                                            !expired(created_at, createdAt, expected_delivery_date) &&
                                            <Priority
                                                title={"Deadline Approaching"}
                                                data={calculateProgressPercentage(new Date(created_at ?? createdAt), new Date(expected_delivery_date), new Date())}
                                                color={'orange'}
                                            />
                                    }
                                    <div className='flex-grow-1'></div>
                                    <IconButton
                                        iconClassName="fs--2 me-1"
                                        className="shadow-none"
                                        variant="falcon-default"
                                        size="sm"
                                        icon={faBookmark}
                                    >
                                        Bookmark
                                    </IconButton>
                                </div>
                            </div>
                        );
                    })
            }
        </div>
    );
};

export default MediaRequestsCardLayout;
