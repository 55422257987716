/* eslint-disable no-unused-vars */
import { Card, Col, Nav, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import PricingDefault from './PricingDefault';
import React, { useEffect, useState } from 'react';
import StringUtils from 'utils/string';
import { jwtDecode } from 'jwt-decode';
import { useQuery } from 'utils/url';

function PlansAndBillingPage() {
  const [activeTab, setActiveTab] = useState('billing');
  const [setCanContinueWithWorkspaceCreation] = useState(false);
  const [headsUp, setHeadsUp] = useState();
  const [openHeadsUp, setOpenHeadsUp] = useState(false);
  const query = useQuery();

  useEffect(() => {
    let params = new URL(document.location).searchParams;
    let accountTab = params.get('t');
    if (accountTab) {
      setActiveTab(accountTab);
    }
  }, []);

  useEffect(() => {
    let meta = query.get('meta');
    if (meta) {
      try {
        let metaData = jwtDecode(meta);
        if (metaData) {
          setActiveTab('billing');
          stealthUrl('billing');
          if (metaData.success_message) {
            setHeadsUp(metaData.success_message);
            if (metaData.continue_with_workspace_creation) {
              setCanContinueWithWorkspaceCreation(true);
            }
            setOpenHeadsUp(true);
          }
        }
      } catch (e) {
        //Malformed jwt
      }
    }
  }, [query]);

  const stealthUrl = value => {
    let currentLocation = window.location.href;
    let queryParam = StringUtils.substringAfterLast(
      window.location.href,
      '?t='
    );
    if (queryParam === window.location.href) {
      queryParam = '';
    }
    let newLocation;
    if (currentLocation.includes('?t=')) {
      newLocation = currentLocation.replace(queryParam, value);
    } else {
      newLocation = `${currentLocation}?t=${value}`;
    }
    window.history.replaceState('', '', newLocation);
  };

  return (
    <Card className="shadow-none">
      <Tab.Container
        id="account-tabs"
        activeKey={activeTab}
        onSelect={e => {
          setActiveTab(e);
          stealthUrl(e);
        }}
      >
        <Card className="p-3 pb-0">
          <Col>
            <h5>Plans and Billing</h5>
            {/* <p className="fs--1 mt-1">Manage your subscription, payment information, vouchers, and invoices.</p> */}
          </Col>
          <Row sm={1} className={'px-0'}>
            <Nav variant="tabs" style={{ border: 'none' }} className="flex-row">
              <Nav.Item>
                <Nav.Link eventKey="billing">Subscription</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="redeem_code">Redeem code</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="invoice_history">Invoice history</Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
        </Card>
        <Tab.Content className="mt-3">
          <Tab.Pane eventKey="billing">
            <PricingDefault />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
}

export default PlansAndBillingPage;
