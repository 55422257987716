export const courseFilters = [
  {
    label: 'Filter by medium',
    options: [
      {
        label: 'Online',
        icon: 'book',
        type: 'checkbox',
        value: 'online',
        name: 'online'
      },
      {
        label: 'Video',
        icon: 'video',
        type: 'checkbox',
        value: 'paid course',
        name: 'paid course'
      },
      {
        label: 'Podcast',
        icon: 'podcast',
        iconShrink: true,
        type: 'checkbox',
        value: 'podcast',
        name: 'podcast'
      }
    ]
  },
  {
    label: 'Filter by status',
    options: [
      {
        label: 'Free to pitch',
        icon: 'thumbs-up',
        type: 'checkbox',
        value: 'free_to_pitch',
        name: 'free to pitch'
      },
      {
        label: 'Most recent',
        icon: 'clock',
        type: 'checkbox',
        value: 'most_recent',
        name: 'most recent'
      },
      {
        label: 'Easy win',
        icon: 'trophy',
        type: 'checkbox',
        value: 'easy_win',
        name: 'easy win'
      },
      {
        label: 'No deadline',
        icon: 'infinity',
        type: 'checkbox',
        value: 'no_deadline',
        name: 'no deadline'
      }
    ]
  }
];

const tags = {
  writing: {
    id: 1,
    type: 'primary',
    content: 'Writing',
    icon: 'pen-nib'
  },
  topTrainer: {
    id: 2,
    type: 'success',
    content: 'Top Trainer',
    icon: 'crown'
  },
  editorsChoice: {
    id: 3,
    type: 'warning',
    content: 'Editor’s Choice',
    icon: 'award'
  },
  misc: {
    id: 4,
    type: 'primary',
    content: 'Misc.',
    icon: 'thumbtack'
  },
  design: {
    id: 5,
    type: 'primary',
    content: 'Design',
    icon: 'brush'
  },
  bestSeller: {
    id: 6,
    type: 'success',
    content: 'Best Seller',
    icon: 'hashtag'
  },
  painting: {
    id: 7,
    type: 'primary',
    content: 'Painting',
    icon: 'palette'
  }
};

export const courseData = [
  {
    id: 'CN000007',
    name: 'Character Design Masterclass: Your First Supervillain',
    price: 99.9,
    oldPrice: 139.9,
    excerpt:
      'Explore all the advanced design tools like Photoshop, Illustrator, Krita, Procreate, & Inkscape; trace the evolution of graphic design with us, from the first breakthrough of image development to today’s AI assisted designs. Begin your visual language journey with these advanced design tools.',
    trainer: 'Bill Finger',
    totalEnrolled: 292603,
    rating: 4.9,
    review: 201452,
    tags: [tags.writing, tags.bestSeller, tags.editorsChoice]
  }
];
