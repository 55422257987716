/* eslint-disable prettier/prettier */
import React from 'react';
import paths from './paths';
import { Broadcast, CurrencyCircleDollar, ChatCircleText, FilePlus, House } from '@phosphor-icons/react';
import { User } from '@phosphor-icons/react/dist/ssr';

const getUserFromLocalStorage = () => {
  const user = localStorage.getItem('reachable-user-storage');
  return user ? JSON.parse(user) : null;
}

const authenticatedUser = getUserFromLocalStorage();

export const homeRoutes = {
  label: 'Home',
  labelDisable: true,
  children: [
    {
      name: 'Community',
      icon: <House width={25} height={25} />,
      iconType: 'phosphor',
      to: paths.feed,
      active: true
    },
    {
      name: 'Dashboard',
      icon: 'grip',
      to: paths.expert,
      active: true
    },
    {
      name: 'Messages',
      icon: <ChatCircleText width={25} height={25} />,
      iconType: 'phosphor',
      to: paths.chat,
      active: true
    },
    
  ]
};

export const appRoutes = {
  label: 'Content',
  labelDisable: false,
  children: [
    {
      name: 'Media requests',
      icon: <Broadcast width={25} height={25} />,
      iconType: 'phosphor',
      to:  paths.mediaRequests,
      active: true,
      children: [
        {
          name: 'All media requests',
          to: paths.mediaRequests,
          active: true
        },
        {
          name: 'My media requests',
          to: paths.myMediaRequests,
          active: true
        },
        {
          name: 'Submit a request',
          iconType: 'phosphor',
          to: paths.addMediaRequest,
          active: true
        },
      ]
    },
    // {
    //   name: 'Post a media request',
    //   icon: <FilePlus FileText width={25} height={25} />,
    //   iconType: 'phosphor',
    //   to: paths.addMediaRequest,
    //   active: true
    // },
  ]
};

export const creatorRoutes = {
  label: 'Creator & Publisher',
  children: [
    // {
    //   name: 'Analytics',
    //   icon: 'square-poll-vertical',
    //   to: paths.saas,
    //   active: true,
    //   children: [
    //     {
    //       name: 'Overview',
    //       active: true
    //     }
    //   ]
    // },
    {
      name: 'Requested content',
      icon: <FilePlus FileText width={25} height={25} />,
      iconType: 'phosphor',
      to: paths.addMediaRequest,
      // active: true
    },
    // {
    //   name: 'Posts',
    //   icon: <FileText width={25} height={25} />,
    //   iconType: 'phosphor',
    //   to: paths.creatorPostPage,
    //   active: true,
    //   children: [
    //     {
    //       name: 'Post',
    //       active: true
    //     }
    //   ]
    // }
  ]
};

export const accountRoutes = {
  label: 'Manage account',
  children: [
    {
      name: 'My profile',
      to: paths.userProfile(authenticatedUser?.state?.authenticatedUser?.username),
      icon: <User width={25} height={25} />,
      iconType: 'phosphor',
      active: true
    },
    {
      name: 'Plans & billing',
      to: paths.plansAndBillingPage,
      icon: <CurrencyCircleDollar width={25} height={25} />,
      iconType: 'phosphor',
      active: true
    }
  ]
};

export const resourceRoutes = {
  label: 'Resources',
  children: [
    {
      name: 'Help Center',
      to: paths.faqBasic,
      icon: 'circle-question',
      active: true
    }
  ]
};

export default [
  homeRoutes,
  appRoutes,
  // creatorRoutes,
  accountRoutes,
  // resourceRoutes
  // pagesRoutes
  // modulesRoutes
  // documentationRoutes
];
