/* eslint-disable no-unused-vars */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BirthdayNotice from './PremiumAdCard';
import AddToFeed from './AddToFeed';
import InvitePeople from 'components/app/invite-people/InvitePeople';
import FeedContent from './FeedContent';
import FeedProvider from 'providers/FeedProvider';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

const Feed = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`Community - ${t('app_name')}`}</title>
        <meta charSet="utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@publishableapp " />
        <meta name="twitter:creator" content="@publishableapp" />
        <meta property="og:description" content={t('app_name')} />
        <meta
          content="https://twitter.com/publishableapp"
          property="og:see_also"
        />
        <meta name="twitter:title" content={t('app_name')} />
        <meta name="twitter:description" content="" />
        {/* <meta name="twitter:image" content="https://promoteapp.s3.amazonaws.com/promote-twitter-card.jpeg" /> */}
        <meta name="twitter:image:width" content="1001 " />
        <meta name="twitter:image:height" content="459 " />
        <link href="https://publishable.ai/" rel="canonical " />
        <link href="https://publishable.ai/" rel="home " />
      </Helmet>
      <FeedProvider>
        <Row className="">
          <Col lg={8}>
            <FeedContent />
          </Col>
          <Col lg={4}>
            <BirthdayNotice
              name="Emma Watson"
              profileLink="/user/profile"
              className="mb-3"
            />
            <InvitePeople />
            <br />
            <br />
            {/* <AddToFeed /> */}
            {/* <Events cardTitle="You ma y interested" events={events.slice(2, 5)} /> */}
          </Col>
        </Row>
        <Outlet />
      </FeedProvider>
    </>
  );
};

export default Feed;
