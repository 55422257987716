/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RegistrationForm from 'components/authentication/RegistrationForm';
import paths from 'routes/paths';
import { useTranslation } from 'react-i18next';
import VerifyEmailAddressContent from './EmailVerification';

const Registration = ({ loosedSocials = true }) => {
  const { t } = useTranslation();
  const [authData, setAuthData] = useState({ step: 0, formData: {} });

  const handleNextStep = (step, formData) => {
    setAuthData({ step, formData });
  };

  const StepView = ({ nextStep = 0, formData = {}, handleNextStep }) => {
    const [steps] = useState([
      <>
        <Row className="align-items-center mb-2">
          <Col>
            <h5 id="modalLabel">{t('register')}</h5>
          </Col>
          <Col xs="auto">
            <p className="fs-10 text-600 mb-0">
              {t('have_an_account')}{' '}
              <Link to={paths.simpleLogin}>{t('login')}</Link>
            </p>
          </Col>
        </Row>
        <RegistrationForm
          nextStep={nextStep}
          handleNextStep={handleNextStep}
          loosedSocials={loosedSocials}
        />
      </>,
      <>
        <VerifyEmailAddressContent
          email={formData?.email}
          nextStep={nextStep}
          handleNextStep={handleNextStep}
        />
      </>
    ]);
    return steps[nextStep];
  };

  return (
    <StepView
      nextStep={authData.step}
      formData={authData.formData}
      handleNextStep={handleNextStep}
    />
  );
};

export default Registration;
