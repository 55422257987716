/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconLightBoxGallery from "components/common/FalconLightBoxGallery";
import Flex from "components/common/Flex";
import FullScreenProgressDialog from "components/common/FullScreenSpinner";
import IconButton from "components/common/IconButton";
import RemovableMedia from "components/common/RemovableMedia";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React from 'react';

const UploadableMediaPreview = ({ media = [], addMore, setMedia, hideModal }) => {

    const { t } = useTranslation();
    const [mediaItems, setMediaItems] = useState([]);
    const [hideRemoveIcons, setHideRemoveIcons] = useState(false);
    const [postingFeedBack, setPostingFeedBack] = useState();

  useEffect(() => {
    const loadMediaContent = async () => {
      const promises = (media ?? []).map(async mediaEntry => {
        if (mediaEntry.file) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () =>
              resolve({ ...mediaEntry, preview: reader.result });
            reader.onerror = error => reject(error);
            reader.readAsDataURL(mediaEntry.file);
          });
        } else {
          return { ...mediaEntry, preview: mediaEntry.url };
        }
      });
      try {
        const updatedMediaItems = await Promise.all(promises);
        setMediaItems(updatedMediaItems);
      } catch (error) {
        console.error('Error loading media:', error);
      }
    };
    loadMediaContent();
  }, [media]);

  const handleOnRemove = media => {
    const updatedMedia = mediaItems.filter(item => item._id !== media._id);
    setMediaItems(updatedMedia);
    setMedia(updatedMedia);
    if (updatedMedia.length < 1) {
      hideModal();
    }
  };

    const handleOnDone = () => {
        console.log(mediaItems);
    }

  return (
    <div>
      <Flex direction={'column'}>
        <div className="my-3">
          <FalconLightBoxGallery images={mediaItems.map(item => item.preview)}>
            {setImgIndex => (
              <Flex className="gap-2 flex-wrap">
                {mediaItems.map(mediaEntry => (
                  <RemovableMedia
                    key={mediaEntry._id}
                    media={mediaEntry}
                    onClick={() => {
                      if (mediaEntry.type === 'video') {
                        return;
                      }
                      return setImgIndex(mediaItems.indexOf(mediaEntry));
                    }}
                    handleOnRemove={() => handleOnRemove(mediaEntry)}
                    hideRemoveIcons={hideRemoveIcons}
                  />
                ))}
                {mediaItems.length > 0 && (
                  <Flex
                    onClick={addMore}
                    className={'cursor-pointer'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <IconButton
                      variant="link"
                      className={
                        'text-decoration-none rounded-5 py-2 text-600 hover-bg-200'
                      }
                    >
                      <FontAwesomeIcon
                        icon={faAdd}
                        style={{ width: 30, height: 30 }}
                      />
                    </IconButton>
                    <span>{t('add_more')}</span>
                  </Flex>
                )}
              </Flex>
            )}
          </FalconLightBoxGallery>
        </div>
        {mediaItems.length > 0 && (
          <Flex className={'justify-content-center align-items-center'}>
            <Button
              disabled={postingFeedBack}
              variant="falcon-primary"
              size="lg"
              className="my-3"
              style={{ minWidth: 200 }}
              onClick={handleOnDone}
            >
              {t('done')}
            </Button>
          </Flex>
        )}
      </Flex>
      {postingFeedBack && (
        <FullScreenProgressDialog postingFeedBack={postingFeedBack} />
      )}
    </div>
  );
};
export default UploadableMediaPreview;
