/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';

export const AdvanceTablePagination = ({ loadMoreLazily }) => {

  const {
    previousPage,
    nextPage,
    getCanNextPage,
    getCanPreviousPage,
    getState,
    getPageCount,
    setPageIndex
  } = useAdvanceTableContext();

  const {
    pagination: { pageIndex },
  } = getState();

  let pages = Array.from(Array(getPageCount()).keys());

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => previousPage()}
        className={classNames({ disabled: !getCanPreviousPage() })}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button>
      <ul className="pagination mb-0 mx-2">
        {pages.map((page, index) => (
          <li key={page} className={classNames({ active: pageIndex === page })}>
            <Button
              size="sm"
              variant="falcon-default"
              className={classNames('page', {
                'me-2': index + 1 !== getPageCount()
              })}
              onClick={() => {
                if (loadMoreLazily) {
                  loadMoreLazily(pages.length, page);
                }
                return setPageIndex(page);
              }}
            >
              {page + 1}
            </Button>
          </li>
        ))}
      </ul>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => {
          if (loadMoreLazily) {
            loadMoreLazily(pages.length, pageIndex);
          }
          return nextPage();
        }}
        className={classNames({ disabled: !getCanNextPage() })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
    </Flex>
  );
};

export default AdvanceTablePagination;
