import React from 'react';
import VerifyEmailAddressContent from './EmailVerification';
import useTempStore from 'store/tempStore';

const ConfirmMail = () => {
  const authenticatingEmail = useTempStore(state => state.authenticatingEmail);

  return (
    <div className="text-center">
      <VerifyEmailAddressContent email={authenticatingEmail} />
    </div>
  );
};

export default ConfirmMail;
