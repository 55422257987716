/* eslint-disable no-unused-vars */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import StatisticsCards from './stats-cards/StatisticsCards';
import { files } from 'data/dashboard/default';
import SharedFiles from '../default/SharedFiles';
import PostsPublished from 'components/dashboards/expert/PostsPublished';
// import PostsDraft from 'ccomponents/dashboards/expert/PostsPublished
import BandwidthSaved from '../default/BandwidthSaved';
import DoMoreCard from './DoMoreCard';

const Saas = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={9}>
          <StatisticsCards />
        </Col>
        <Col xxl={3}>
          <Row className="g-3">
            <Col xxl={12}>
              <SharedFiles
                files={files}
                className="h-100 h-xxl-auto mt-xxl-3"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Saas;
