/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useRef, useState } from 'react';
import {
  CloseButton,
  Col,
  Image,
  Tab,
  Modal,
  Nav,
  Row,
  Table
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from 'providers/AppProvider';
import paths from 'routes/paths';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconLink from 'components/common/FalconLink';
import { Card } from 'react-bootstrap';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import FalconDropzone from 'components/common/FalconDropzone';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import MediaPreview from 'components/common/MediaPreview';
import UserCreatedPosts from './UserCreatedPosts';

const ProfileDetailsFooter = ({
  authenticatedUser,
  user,
  openProfileEditModal
}) => {
  const { t } = useTranslation();
  const {
    config: { isDark }
  } = useAppContext();
  const mediaInputRef = useRef(null);
  const [openPhotoUploadModal, setOpenPhotoUploadModal] = useState(false);
  const [photo, setPhoto] = useState();
  const [postingFeedBack, setPostingFeedBack] = useState();

  const message = [
    'https://imagedelivery.net/YyuoIT-j8f8G40IQoCYj6w/213f7329-5e19-45bd-44a5-385047331700/public',
    'https://imagedelivery.net/YyuoIT-j8f8G40IQoCYj6w/213f7329-5e19-45bd-44a5-385047331700/public',
    'https://imagedelivery.net/YyuoIT-j8f8G40IQoCYj6w/213f7329-5e19-45bd-44a5-385047331700/public',
    'https://imagedelivery.net/YyuoIT-j8f8G40IQoCYj6w/48b94baa-f943-4f68-37b8-40a8ae5f5b00/public',
    'https://imagedelivery.net/YyuoIT-j8f8G40IQoCYj6w/213f7329-5e19-45bd-44a5-385047331700/public',
    'https://imagedelivery.net/YyuoIT-j8f8G40IQoCYj6w/213f7329-5e19-45bd-44a5-385047331700/public'
  ];

  const handleOnRemove = media => {
    const updatedMedia = message.filter(item => item._id !== media._id);
  };

  return (
    <div className="mt-2">
      <Tab.Container defaultActiveKey="posts">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link
              eventKey="posts"
              className="px-2 px-md-3 cursor-pointer outline-none"
            >
              {t('posts')}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="profile">
            <div className="mt-3" />
            <Card className="h-100 shadow-none p-0">
              <FalconCardHeader
                title={t('About me')}
                className="p-0"
                endEl={<p onClick={openProfileEditModal}>Update</p>}
              />
              <Flex
                alignItems={'left'}
                justifyContent={'left'}
                className={'gap-1'}
                style={{
                  flexWrap: 'wrap'
                }}
              >
                <p className="fs-10 text-600">{user?.bio}</p>
              </Flex>
            </Card>
            <Card className="h-100 shadow-none p-0">
              <FalconCardHeader
                title={`${user?.first_name}'s Photos`}
                className="p-0 mb-2"
                endEl={
                  <p onClick={() => setOpenPhotoUploadModal(true)}>
                    Add photos
                  </p>
                }
              />
              <FalconLightBoxGallery images={message}>
                {setImgIndex => (
                  <Row className="g-2">
                    {message.map(media => (
                      <Col xs={12} sm={6} md={6} lg={4} key={`${media}-col`}>
                        <div
                          className="border"
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '2%',
                            overflow: 'hidden',
                            position: 'relative',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setImgIndex(message.indexOf(media));
                          }}
                        >
                          <Image
                            fluid
                            className="mb-2"
                            src={media}
                            alt=""
                            style={{
                              display: 'block',
                              width: '100%',
                              height: 'auto'
                            }}
                          />
                          <Button
                            size="sm"
                            variant="dark"
                            className="rounded-pill"
                            onClick={e => {
                              e.stopPropagation();
                              handleOnRemove(media);
                            }}
                            style={{
                              position: 'absolute',
                              top: '4px',
                              right: '4px',
                              width: '30px',
                              height: '30px',
                              padding: '0',
                              backgroundColor: 'grey',
                              border: 'none',
                              cursor: 'pointer',
                              zIndex: 1,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{
                                color: 'white',
                                width: '14px',
                                height: '14px'
                              }}
                            />
                          </Button>
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
              </FalconLightBoxGallery>
            </Card>
            <br />
            <br />
            <Card className="h-100 p-0 shadow-none">
              <FalconCardHeader
                title={t('Looking for')}
                className="p-0"
                endEl={
                  <FalconLink
                    to={paths.simpleUpdateContentPreferences}
                    title="Update"
                    className="px-0"
                  />
                }
              />
              <Flex
                alignItems={'left'}
                justifyContent={'left'}
                className={'gap-1'}
                style={{
                  flexWrap: 'wrap'
                }}
              >
                {user?.preferences?.discovery?.map(preference => {
                  return (
                    <Button
                      key={preference.name}
                      onClick={() => { }}
                      variant={isDark ? 'light' : 'light'}
                      className="rounded-pill me-1 mb-1"
                    >
                      <Flex
                        alignItems={'center'}
                        justifyContent={'center'}
                        className={'gap-1'}
                      >
                        <span>{preference.name}</span>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{
                            color: 'grey',
                            width: '14px',
                            height: '14px'
                          }}
                        />
                      </Flex>
                    </Button>
                  );
                })}
              </Flex>
            </Card>
            <br />
            <br />
            <>
              <Card className="h-100 shadow-none p-0">
                <FalconCardHeader
                  title={t('interests')}
                  className="p-0"
                  endEl={
                    <FalconLink
                      to={paths.simpleUpdateInterests}
                      title="Update"
                      className="px-0"
                    />
                  }
                />
                <Flex
                  alignItems={'left'}
                  justifyContent={'left'}
                  className={'gap-1'}
                  style={{
                    flexWrap: 'wrap'
                  }}
                >
                  {authenticatedUser?.interests?.map(interest => {
                    return (
                      <Button
                        key={interest}
                        onClick={() => { }}
                        variant={isDark ? 'light' : 'dark'}
                        className="rounded-pill me-1 mb-1"
                      >
                        <Flex
                          alignItems={'center'}
                          justifyContent={'center'}
                          className={'gap-1'}
                        >
                          <span>{interest}</span>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </Flex>
                      </Button>
                    );
                  })}
                </Flex>
              </Card>
            </>
          </Tab.Pane>
          <Tab.Pane eventKey="posts" className='p-0 m-0'>
            <Table className="fs-10 p-0 m-0">
              <UserCreatedPosts user={user} />
            </Table>
          </Tab.Pane>
          <Tab.Pane eventKey="reviews">
            <Row className="mt-3">
              <Col lg={6} className="mb-4 mb-lg-0">
                <Table className="fs-10 mt-3">
                  This users comments are hidden
                </Table>
              </Col>
              <Col lg={6} className="ps-lg-5"></Col>
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <Modal
        show={openPhotoUploadModal}
        onHide={() => setOpenPhotoUploadModal(false)}
        className="mt-4"
      >
        <Modal.Header className=" modal-shape-header px-4 position-relative">
          <div className="position-relative z-1" data-bs-theme="light">
            <h4 className="mb-0 text-dark" id="authentication-modal-label">
              Upload photos
            </h4>
            <p className="fs-10 mt-2 mb-0 text-700">
              Adding photos is a great way to show off more about yourself! You
              can drag your photos right from your desktop.
            </p>
          </div>
          <CloseButton
            variant="dark"
            className="position-absolute end-0 me-2 mt-2 top-0"
            onClick={() => setOpenPhotoUploadModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="p-4">
          <MediaPreview hideRemoveIcons={postingFeedBack} />
          <FalconLightBoxGallery images={message}>
            {setImgIndex => (
              <Row className="g-2">
                {message.map(media => (
                  <Col xs={12} sm={6} md={6} lg={4} key={`${media}-col`}>
                    <div
                      className="border"
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '2%',
                        overflow: 'hidden',
                        position: 'relative',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setImgIndex(message.indexOf(media));
                      }}
                    >
                      <Image
                        fluid
                        className="mb-2"
                        src={media}
                        alt=""
                        style={{
                          display: 'block',
                          width: '100%',
                          height: 'auto'
                        }}
                      />
                      <Button
                        size="sm"
                        variant="dark"
                        className="rounded-pill"
                        onClick={e => {
                          e.stopPropagation();
                          handleOnRemove(media);
                        }}
                        style={{
                          position: 'absolute',
                          top: '4px',
                          right: '4px',
                          width: '30px',
                          height: '30px',
                          padding: '0',
                          backgroundColor: 'grey',
                          border: 'none',
                          cursor: 'pointer',
                          zIndex: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          style={{
                            color: 'white',
                            width: '14px',
                            height: '14px'
                          }}
                        />
                      </Button>
                    </div>
                  </Col>
                ))}
                <FalconDropzone
                  files={photo}
                  onClick={() => mediaInputRef.current.click()}
                  onChange={files => {
                    setPhoto(files);
                  }}
                  multiple={false}
                  accept="image/*"
                  placeholder={
                    <>
                      <Flex justifyContent="center">
                        <img
                          src={cloudUpload}
                          alt=""
                          width={25}
                          className="me-2"
                        />
                        <p className="fs-9 mb-0 text-700">Upload your photos</p>
                      </Flex>
                      <p className="mb-0 mx-auto text-400">
                        We accept JPGs and PNGs of at least 500x500px.
                      </p>
                    </>
                  }
                />
              </Row>
            )}
          </FalconLightBoxGallery>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProfileDetailsFooter;
