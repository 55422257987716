/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import CreatePostForm from './CreatePostForm';
import useUserStore from 'store/userStore';
import { useTranslation } from 'react-i18next';
import FullScreenProgressDialog from 'components/common/FullScreenSpinner';

const CreatePost = ({ title, footerOpen = false, onDoneCallback, ...rest }) => {
  const { t } = useTranslation();
  const authenticatedUser = useUserStore(state => state.authenticatedUser);
  const [postingFeedBack, setPostingFeedBack] = useState();

  const handlePostingFeedBack = feedBack => {
    setPostingFeedBack(feedBack);
  };

  return (
    <Card
      {...rest}
      style={{
        position: 'relative'
      }}
      className="elevation-none
      shadow-none"
    >
      <Card.Header className="bg-body-tertiary">
        <Flex alignItems="center">
          <Avatar
            {...(authenticatedUser.avatar
              ? { src: authenticatedUser.avatar }
              : {
                  name: `${authenticatedUser?.first_name ?? ''} ${
                    authenticatedUser?.last_name ?? ''
                  }`
                })}
            size="xl"
            className="me-2"
          />
          <div className="flex-1">
            <h5 className="mb-0 fs-9">{authenticatedUser?.first_name ?? ''} { authenticatedUser?.last_name ?? '' }</h5>
          </div>
        </Flex>
      </Card.Header>
      <Card.Body className="p-0">
        <CreatePostForm
          onDoneCallback={onDoneCallback}
          footerOpen={footerOpen}
          postingFeedBack={postingFeedBack}
          handlePostingFeedBack={handlePostingFeedBack}
        />
      </Card.Body>
      {postingFeedBack && (
        <FullScreenProgressDialog postingFeedBack={postingFeedBack} />
      )}
    </Card>
  );
};

export default CreatePost;
