/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import { useTranslation } from 'react-i18next';
import Flex from 'components/common/Flex';
import VerificationInput from 'react-verification-input';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import './verification_input_styling.css';
import useUserStore from 'store/userStore';
import { useNavigate } from 'react-router-dom';
import { rootPaths } from 'routes/paths';
import useTempStore from 'store/tempStore';

const VerifyEmailAddressContent = ({
  email,
  titleTag: TitleTag = 'h4',
  nextStep,
  handleNextStep
}) => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const [pinCode, setPinCode] = useState('');
  const setAuthenticatedUser = useUserStore(
    state => state.setAuthenticatedUser
  );
  const authenticatingEmail = useTempStore(state => state.authenticatingEmail);
  const navigate = useNavigate();
  email = email ?? authenticatingEmail;

  const validateEnteredCode = enteredCode => {
    setPinCode(enteredCode);
    setProcessing(enteredCode.length === 6);
    if (enteredCode.length === 6) {
      APIService.validatePasswordLessConfirmationCode(
        enteredCode,
        email,
        (response, error) => {
          setProcessing(false);
          if (response) {
            let { data } = response;
            setAuthenticatedUser(data);
            navigate(rootPaths.root);
          } else {
            setProcessing(false);
            toast.error(error);
          }
        }
      );
    }
  };

  return (
    <Flex direction={'column'} alignItems={'center'} justifyContent={'center'}>
      <img
        className="d-block mx-auto mb-4"
        src={envelope}
        alt="sent"
        width={100}
      />
      <TitleTag>{t('please_check_your_email')}</TitleTag>
      <p>
        {t('verification_code_sent_to')} <strong>{email}</strong>.{' '}
        {t('check_email_and_enter_code_provided')}.
      </p>
      <div
        style={{
          flex: 1
        }}
      >
        <VerificationInput
          value={pinCode}
          disabled={processing}
          type="number"
          fields={6}
          onChange={validateEnteredCode}
          inputProps={{
            disabled: processing,
            autoComplete: 'one-time-code'
          }}
          classNames={{
            container: 'container',
            character: 'character',
            characterInactive: 'character--inactive',
            characterSelected: 'character--selected',
            characterFilled: 'character--filled'
          }}
        />
      </div>
      {processing ? (
        <Spinner className="mt-5" animation="border" size="sm" />
      ) : (
        <Button
          variant="outline-default"
          color="primary"
          size="sm"
          className="my-4"
          onClick={() => {
            if (handleNextStep) {
              handleNextStep(nextStep - 1);
            } else {
              navigate(rootPaths.root);
            }
          }}
        >
          <FontAwesomeIcon
            icon="chevron-left"
            transform="shrink-4 down-1"
            className="me-1"
          />
          {t(`go_back`)}
        </Button>
      )}
    </Flex>
  );
};

export default VerifyEmailAddressContent;
