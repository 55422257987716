/* eslint-disable react/prop-types */
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InAppVideoPlayer from 'components/app/social/feed/InAppVideoPlayer';
import React from 'react';
import { Button, Image } from 'react-bootstrap';

const RemovableMedia = ({
  media,
  onClick,
  handleOnRemove,
  hideRemoveIcons = false
}) => {
  const mediaContent = () => {
    if (media.file) {
      if (media.type === 'video') {
        return (
          <video
            controls
            src={media.preview}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '10%',
              objectFit: 'cover',
              transition: 'filter 0.3s ease'
            }}
          />
        );
      } else {
        return (
          <Image
            fluid
            src={media.preview}
            alt="Preview"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '10%',
              objectFit: 'cover',
              transition: 'filter 0.3s ease'
            }}
          />
        );
      }
    } else if (media.url) {
      if (media.type === 'video') {
        return (
          <InAppVideoPlayer
            media={media}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '10%',
              objectFit: 'cover',
              transition: 'filter 0.3s ease'
            }}
          />
        );
      } else {
        return (
          <Image
            fluid
            src={media.url}
            alt="Preview"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '10%',
              objectFit: 'cover',
              transition: 'filter 0.3s ease'
            }}
          />
        );
      }
    } else {
      return <div>Not available</div>;
    }
  };

  return (
    <div
      className="border"
      onClick={onClick}
      style={{
        width: 125,
        height: 125,
        borderRadius: '10%',
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer'
      }}
    >
      {mediaContent()}
      {media.type !== 'video' && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            transition: 'background-color 0.3s ease'
          }}
          onMouseEnter={e => {
            e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
          }}
          onMouseLeave={e => {
            e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)';
          }}
        ></div>
      )}
      {!hideRemoveIcons && (
        <Button
          size="sm"
          variant="dark"
          className="rounded-pill"
          onClick={e => {
            e.stopPropagation();
            handleOnRemove(media);
          }}
        >
          <FontAwesomeIcon
            icon={faRemove}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: 30,
              height: 30,
              padding: '4px',
              backgroundColor: 'black',
              border: 'none',
              cursor: 'pointer',
              zIndex: 1,
              color: 'white'
            }}
          />
        </Button>
      )}
    </div>
  );
};

export default RemovableMedia;
