import { Strings } from '../constants';
import StorageEngine from 'utils/storage_engine';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useUserStore = create(
  persist(
    set => ({
      users: [],
      authenticatedUser: null,
      setUser: users => set(state => ({ ...state, users })),
      setAuthenticatedUser: authenticatedUser => {
        if (authenticatedUser) {
          StorageEngine.setItem(
            Strings.LOGGED_IN_USER_API_KEY,
            authenticatedUser['api_key']
          );
        }
        return set(state => ({ ...state, authenticatedUser }));
      }
    }),
    {
      name: 'reachable-user-storage'
    }
  )
);

export default useUserStore;
