import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Section from 'components/common/Section';
import PartnershipHuntDashboard from 'assets/img/gallery/PHDashboard.png';
import engage from 'assets/img/gallery/engage.png';
// import recentbackgroundshape1 from 'assets/img/generic/recent-background-shape-1.png';
import recentbackgroundshape3 from 'assets/img/generic/recent-background-shape-3.png';
// import track from 'assets/img/gallery/PartnershipHuntDashboard.png';

const Features2 = () => {
  return (
    <>
      <section className="pt-5 pb-0 overflow-hidden ">
        <img
          src={recentbackgroundshape3}
          alt=""
          height="300"
          className="position-absolute l-0 b-0 d-none d-md-block"
        />
        {/* <img src={headerleftshape} className="banner-bg" alt="" width="441" /> */}
        <div className="container mt-7">
          {/* <img
            src={recentbackgroundshape3}
            alt=""
            width="200"
            className="position-absolute r-0 b-0 d-none d-md-block"
            style={{ bottom: '-79px', right: '-79px' }}
          /> */}
          <div className="">
            <div className="row">
              <div className="col-xxl-12 py-2 py-lg-4 justify-content-left d-flex d-lg-block text-left text-lg-left col-sm-12 col-lg-12 ">
                <div className="mb-lg-6 ml-lg-5">
                  <h1
                    className="text-dark font-weight-bold"
                    style={{ fontSize: '40px' }}
                  >
                    All the features you need to{' '}
                    <span style={{ color: '#00d27a' }}>
                      {' '}
                      boost your earnings
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Section className="py-0 overflow-hidden" position="left bottom" overlay>
        <Row className=" mb-6 justify-content-left align-items-left pt-7 pt-lg-8 pb-lg-9 pb-xl-0">
          <Col
            md={11}
            lg={8}
            xl={5}
            className="pt-5 pb-5 pb-xl-7 text-left text-xl-left"
          >
            <h1
              className="mb-3 text-dark font-weight-bold"
              style={{ fontSize: '38px' }}
            >
              {/* Create a new revenue channel today */}
              Earn money from{' '}
              <span style={{ color: '#00d27a' }}>subscriptions</span>
            </h1>
            <p className="lead text-dark opacity-75">
              Monetize your exclusive content by placing it behind a paywall,
              allowing your fans to subscribe and generate recurring revenue for
              you.
            </p>
            <Link
              className="btn btn-primary border-2x btn-lg mb-3 fs-0"
              to="/dashboard/swap"
            >
              SIGN UP. IT'S FREE
            </Link>
          </Col>
          <Col xl={{ size: 6, offset: 1 }} className="align-self-end">
            <div className="rounded-soft overflow-hidden position-relative mb-4">
              <img
                className="img-fluid rounded-bottom-0 w-100 d-md-block card-img"
                width="510px"
                src={PartnershipHuntDashboard}
                alt="affiliate partners"
              />
            </div>
          </Col>
        </Row>
        <Row className=" mb-6 justify-content-left align-items-left pt-7 pt-lg-8 pb-lg-9 pb-xl-0">
          <Col
            md={11}
            lg={8}
            xl={5}
            className="pt-5 pb-5 pb-xl-7 text-left text-xl-left"
          >
            <h1
              className="mb-3 text-dark font-weight-bold"
              style={{ fontSize: '38px' }}
            >
              {/* Create a new revenue channel today */}
              Connect with your fans with{' '}
              <span style={{ color: '#00d27a' }}>messaging</span>
            </h1>
            <p className="lead text-dark opacity-75">
              Connect with your fans via direct messages - paid or for free
            </p>
            <Link
              className="btn btn-primary border-2x btn-lg mb-3 fs-0"
              to="/dashboard/swap"
            >
              SIGN UP. REACHABLE IS FREE
            </Link>
          </Col>

          <Col xl={{ size: 6, offset: 1 }} className="align-self-end">
            <Card className="h-100 shadow-none bg-white">
              <div className="shadow-none rounded-soft overflow-hidden position-relative m-0">
                <img
                  className="shadow-none img-fluid rounded-bottom-0 w-100 d-md-block "
                  width="600px"
                  src={engage}
                  alt="affiliate partners"
                />
              </div>
            </Card>
          </Col>
          <Row className=" mb-6 justify-content-left align-items-left pt-7 pt-lg-8 pb-lg-9 pb-xl-0">
            <Col
              md={11}
              lg={8}
              xl={5}
              className="pt-5 pb-5 pb-xl-7 text-left text-xl-left"
            >
              <h1
                className="mb-3 text-dark font-weight-bold"
                style={{ fontSize: '38px' }}
              >
                Sell content with{' '}
                <span style={{ color: '#00d27a' }}> pay-to-view</span>
              </h1>
              <p className="lead text-dark opacity-75">
                Upsell your most exclusive content with pay-to-views.
              </p>
              <Link
                className="btn btn-primary border-2x btn-lg mb-3 fs-0"
                to="/dashboard/swap"
              >
                SIGN UP. IT'S FREE
              </Link>
            </Col>
            <Col xl={{ size: 6, offset: 1 }} className="align-self-end">
              <div className="rounded-soft overflow-hidden position-relative mb-4">
                <img
                  className="img-fluid rounded-bottom-0 w-100 d-md-block card-img"
                  width="510px"
                  src={PartnershipHuntDashboard}
                  alt="affiliate partners"
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-left align-items-left pt-7">
            <Col md={11} lg={8} xl={5} className=" text-left text-xl-left">
              <h1
                className="mb-3 text-dark font-weight-bold"
                style={{ fontSize: '38px' }}
              >
                Create and share your
                <span style={{ color: '#00d27a' }}> wishlist</span> to let fans
                support you
              </h1>
              <p className="lead text-dark opacity-75">
                Create your privacy-first Wishlist and let your fans support you
                by sending gift funds and gifts without sharing personal
                information.
              </p>
              <Link
                className="btn btn-primary border-2x btn-lg mb-3 fs-0"
                to="/dashboard/swap"
              >
                SIGN UP. IT'S FREE
              </Link>
            </Col>
            <Col xl={{ size: 6, offset: 1 }} className="align-self-end">
              <div className="rounded-soft overflow-hidden position-relative ">
                <img
                  className="img-fluid rounded-bottom-0 w-100 d-md-block card-img"
                  width="510px"
                  src={PartnershipHuntDashboard}
                  alt="affiliate partners"
                />
              </div>
            </Col>
          </Row>
        </Row>
        {/* <Row className=" mb-6 justify-content-left align-items-left pt-7 pt-lg-8 pb-lg-9 pb-xl-0">
          <Col md={11} lg={8} xl={5} className="pt-5 pb-5 pb-xl-7 text-left text-xl-left">
            <h1 className="mb-3 text-dark font-weight-bold" style={{ fontSize: '38px' }}>
              Publish
            </h1>
            <p className="lead text-dark opacity-75">
              Distribute your content across all channels - on autopilot.
            </p>
            <Link className="btn btn-primary border-2x btn-lg mb-3 fs-0" to="/dashboard/swap">
              Get started now
            </Link>
          </Col>
          <Col xl={{ size: 6, offset: 1 }} className="align-self-end">
            <Card className="h-100 shadow-none bg-white">
              <div className="shadow-none rounded-soft overflow-hidden position-relative m-0">
                <img
                  className="shadow-none img-fluid rounded-bottom-0 w-100 d-md-block "
                  width="600px"
                  src={track}
                  alt="affiliate partners"
                />
              </div>
            </Card>
          </Col>
        </Row> */}
      </Section>
    </>
  );
};

export default Features2;
