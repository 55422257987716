/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import SimpleBarReact from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse, Form, Image, Card } from 'react-bootstrap';
import { slugifyText } from 'helpers/utils';
import Flex from 'components/common/Flex';
import SubtleBadge from 'components/common/SubtleBadge';
import useSearchStore from 'store/search_store';

const filters = [
  {
    label: 'Filter by medium',
    options: [
      {
        label: 'Online',
        icon: 'book',
        type: 'checkbox',
        value: 'online',
        name: 'online',
        searchKey: 'medium'
      },
      {
        label: 'Video',
        icon: 'video',
        type: 'checkbox',
        value: 'paid_course',
        name: 'paid course',
        searchKey: 'medium'
      },
      {
        label: 'Podcast',
        icon: 'podcast',
        iconShrink: true,
        type: 'checkbox',
        value: 'podcast',
        name: 'podcast',
        searchKey: 'medium'
      }
    ]
  },
  {
    label: 'Filter by status',
    options: [
      {
        label: 'Free to pitch',
        icon: 'thumbs-up',
        type: 'checkbox',
        value: 'free_to_pitch',
        name: 'free to pitch',
        searchKey: 'status'
      },
      {
        label: 'Most recent',
        icon: 'clock',
        type: 'checkbox',
        value: 'most_recent',
        name: 'most recent',
        searchKey: 'status'
      },
      {
        label: 'Easy win',
        icon: 'trophy',
        type: 'checkbox',
        value: 'easy_win',
        name: 'easy win',
        searchKey: 'status'
      },
      {
        label: 'Deadline approaching',
        icon: 'hourglass',
        type: 'checkbox',
        value: 'deadline_approaching',
        name: 'deadline approaching',
        searchKey: 'status'
      },
      {
        label: 'No deadline',
        icon: 'infinity',
        type: 'checkbox',
        value: 'no_deadline',
        name: 'no deadline',
        searchKey: 'status'
      }
    ]
  }
];

const MediaRequestFilters = ({ setShow, isOffcanvas }) => {

  const [filterOptions, setFilterOptions] = useState([]);
  const setSearchFilters = useSearchStore(state => state.setSearchFilters);

  useEffect(() => {
    let filters = Object.groupBy(filterOptions, ({ searchKey }) => searchKey);
    let shallowFilters = Object.entries(filters).map(([key, value]) => {
      let val = value.map(item => item.value).join();
      return { [key]: val }
    });
    const mergedObject = Object.assign({}, ...shallowFilters);
    setSearchFilters(mergedObject);
  }, [filterOptions]);

  const handleFilterOptions = e => {
    const { type, name, value, searchKey, checked } = e;
    if (type === 'checkbox') {
      let options = [...filterOptions];
      options = options.filter(option => option.value !== value);
      checked && options.push({ name, value, searchKey });
      setFilterOptions(options);
    }
    if (type === 'radio') {
      const isExists = filterOptions.some(el => el.name === name);
      isExists
        ? setFilterOptions(
          filterOptions.map(el => (el.name === name ? { ...el, value } : el))
        )
        : setFilterOptions([...filterOptions, { name, value }]);
    }
  };

  return (
    <Card className="course-filter shadow-none border border-200">
      <SimpleBarReact style={{ height: '100%' }}>
        <Card.Header as={Flex} className="flex-between-center pt-x1">
          <Flex className="gap-2 flex-xl-grow-1 align-items-center justify-content-xl-between">
            <h5 className="mb-0 text-700 fs-9 d-flex align-items-center">
              <FontAwesomeIcon icon="filter" className="fs-10 me-1" />
              <span>Filter</span>
            </h5>
            <Button
              variant="outline-secondary"
              size="sm"
              className="ms-2 mt-0 mb-0"
              style={{ fontSize: '12px' }}
              onClick={() => setFilterOptions([])}
            >
              <FontAwesomeIcon icon="redo-alt" className="me-1 fs-11" />
              Reset
            </Button>
          </Flex>
          {isOffcanvas && (
            <Button
              onClick={() => setShow(false)}
              className="btn-close text-reset"
              size="sm"
              variant="link"
            ></Button>
          )}
        </Card.Header>
        <Card.Body className="py-0">
          {filterOptions.length > 0 && (
            <Flex wrap="wrap" className="gap-2 mb-3">
              {filterOptions.map(tag => (
                <SubtleBadge
                  key={tag.value}
                  className="text-capitalize bg-300 text-700 py-0"
                >
                  {tag.value.replace("_", " ")}
                  <Button
                    size="sm"
                    variant="link"
                    className="p-0 text-700"
                    onClick={() =>
                      setFilterOptions(
                        filterOptions.filter(({ value }) => value !== tag.value)
                      )
                    }
                  >
                    <FontAwesomeIcon icon="xmark" className="ms-1 fs-11" />
                  </Button>
                </SubtleBadge>
              ))}
            </Flex>
          )}
          <ul className="list-unstyled">
            {filters.map((filter, index) => (
              <FilterItem
                key={slugifyText(filter.label)}
                index={index}
                filter={filter}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
                handleFilterOptions={handleFilterOptions}
              />
            ))}
          </ul>
        </Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

const FilterItem = ({ filter, index, filterOptions, handleFilterOptions }) => {
  const [open, setOpen] = useState(false);

  return (
    <li className={`${filters.length - 1 !== index && 'border-bottom'}`}>
      <Button
        variant="link"
        onClick={() => setOpen(!open)}
        aria-controls={`${slugifyText(filter.label)}-collapse`}
        aria-expanded={index === 0 || index === 1 ? !open : open}
        className="collapse-indicator-plus w-100 fs-11 fw-medium text-start text-600 text-decoration-none py-3 px-0"
      >
        {filter.label}
      </Button>
      <Collapse
        in={index === 0 || index === 1 ? !open : open}
        id={`${slugifyText(filter.label)}-collapse`}
      >
        <ul className="list-unstyled">
          {filter.options &&
            filter.options.map(option => (
              <li key={slugifyText(option.label)}>
                <Form.Check
                  type={option.type}
                  className="form-check d-flex ps-0"
                >
                  <Form.Check.Label
                    className="fs-10 flex-1 text-truncate"
                    htmlFor={`filter-${slugifyText(filter.label)}-${slugifyText(
                      option.label
                    )}`}
                  >
                    {option.icon && (
                      <FontAwesomeIcon
                        icon={option.icon}
                        className={`me-3 ${option.iconShrink ? 'fs-11' : 'fs-10'
                          }`}
                      />
                    )}
                    {option.svg && (
                      <Image
                        src={option.svg}
                        width={13}
                        alt=""
                        className="me-3"
                      />
                    )}
                    {option.label}
                  </Form.Check.Label>
                  <Form.Check.Input
                    type={option.type}
                    onChange={e => handleFilterOptions({ ...option, checked: e.target.checked })}
                    checked={
                      option.type === 'checkbox'
                        ? filterOptions.some(
                          ({ value }) => option.value === value
                        )
                        : undefined
                    }
                    id={`filter-${slugifyText(filter.label)}-${slugifyText(
                      option.label
                    )}`}
                    name={option.name}
                    value={option.value}
                  />
                </Form.Check>
              </li>
            ))}
        </ul>
      </Collapse>
    </li>
  );
};

export default MediaRequestFilters;
