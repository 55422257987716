/* eslint-disable prettier/prettier */
import { create } from 'zustand';

const useConversationsStore = create(set => ({
    conversations: [],
    messages: {},
    activeConversation: null,
    updateConversations: (updatedConversations) =>
        set(state => {
            const mergedConversations = updatedConversations.reduce((acc, updatedConversation) => {
                const existingIndex = acc.findIndex(conversation => conversation._id.$oid === updatedConversation._id.$oid);
                if (existingIndex === -1) {
                    acc.push(updatedConversation);
                } else {
                    acc[existingIndex] = updatedConversation;
                }
                return acc;
            }, [...state.conversations]);

            return { ...state, conversations: mergedConversations };
        }),
    clearConversations: () => set({ conversations: [] }),
    setActiveConversation: conversation =>
        set(state => ({ ...state, activeConversation: conversation })),
    addMessages: (newMessages, conversationId) =>
        set(state => {
            const oldMessages = state.messages[conversationId] || [];
            const newUniqueMessages = newMessages.filter(newMessage =>
                !oldMessages.some(oldMessage => oldMessage._id.$oid === newMessage._id.$oid)
            );
            return {
                ...state,
                messages: {
                    ...state.messages,
                    [conversationId]: [...oldMessages, ...newUniqueMessages]
                }
            };
        }),
}));
export default useConversationsStore;
